import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';
import Page from '../../../layout/Page/Page';
import Button from '../../../components/bootstrap/Button';
import Icon from '../../../components/icon/Icon';

import Spinner from '../../../components/bootstrap/Spinner';

import { UserContext } from '../../../contexts/userContext';
import Role from '../../../common/data/role';

const SuccessDisplay = () => {
	const { t } = useTranslation('checkout');
	const { currentUser } = useContext(UserContext);

	return (
		<Page>
			{(!currentUser || currentUser.role === Role.None) && (
				<div className='row d-flex align-items-center h-100'>
					<div className='row d-flex flex-column'>
						<div
							className='col-12 d-flex justify-content-center'
							style={{ fontSize: 'calc(1rem + 3vw)' }}>
							<p>{t('Payment will be processed...')}</p>
						</div>
						<div className='col-12 d-flex align-items-baseline justify-content-center'>
							<Spinner color='primary' size={50} />
						</div>
					</div>
				</div>
			)}
			{currentUser && currentUser.role !== Role.None && (
				<div className='row d-flex align-items-center h-100'>
					<div
						className='col-12 d-flex justify-content-center'
						style={{ fontSize: 'calc(1rem + 3vw)' }}>
						<p>
							{t('Subscription to plan')}{' '}
							<span className='text-primary fw-bold mx-1'>{currentUser.role}</span>{' '}
							{t('successful!')}
						</p>
					</div>
					<div className='col-12 d-flex align-items-baseline justify-content-center'>
						<Icon icon='CartCheck' forceFamily='bootstrap' size='10x' color='success' />
					</div>
					<div className='col-12 d-flex justify-content-center'>
						<h4 className='fw-bold'>
							<span>
								{t(
									'You are now ready to start with the features including in your plan.',
								)}
							</span>
							<br />
							<span>
								{t(
									'If you do not see the new menus instantly, try and refresh the page.',
								)}
							</span>
						</h4>
					</div>
					<div className='col-12 mb-3'>
						<Button
							tag='a'
							to='/profile'
							color='primary'
							isLight
							className='w-100 py-3 text-uppercase'
							size='lg'>
							{t('Manage your billing information')}
						</Button>
					</div>
				</div>
			)}
		</Page>
	);
};

export default SuccessDisplay;
