import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Card, { CardBody } from '../../components/bootstrap/Card';
import Page from '../../layout/Page/Page';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Logo from '../../components/Logo';
import Button from '../../components/bootstrap/Button';
import Spinner from '../../components/bootstrap/Spinner';
import pages from '../../menu';
import userService from '../../services/user.service';
import authenticationService from '../../services/authentication.service';

const ChangeEmailConfirmPage = () => {
	const [token, setToken] = useState(null);
	const [newEmail, setNewMail] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [isSuccess, setIsSuccess] = useState(true);
	const [requestHasBeenSent, setRequestHasBeenSent] = useState(false);

	const { t } = useTranslation('login');

	useEffect(() => {
		const query = new URLSearchParams(window.location.search);
		const currentUser = authenticationService.currentUserValue;

		setToken(query.get('token'));
		setNewMail(query.get('newMail'));

		if (
			token != null &&
			token !== '' &&
			newEmail != null &&
			newEmail !== '' &&
			!requestHasBeenSent
		) {
			setIsLoading(true);
			setRequestHasBeenSent(true);
			userService.confirmChangeEmail(currentUser?.email, newEmail, token).then(
				() => {
					setIsLoading(false);
					setIsSuccess(true);
				},
				() => {
					// TODO Fehlermeldung?
					setIsSuccess(false);
					setIsLoading(false);
				},
			);
		} else {
			// TODO Fehlermeldung?
		}
	}, [token, newEmail, requestHasBeenSent]);

	return (
		<PageWrapper title={t(pages.changeEmailConfirm.text)} className='bg-warning'>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='shadow-3d-container col-xl-4 col-lg-6 col-md-8'>
						<Card className='shadow-3d-dark'>
							<CardBody>
								<div className='text-center my-5'>
									<Link
										to='/'
										className='text-decoration-none  fw-bold display-2 text-dark'>
										<Logo width={220} />
									</Link>
								</div>

								{!isLoading && (
									<>
										<div className='text-center h1 fw-bold mt-5'>
											{isSuccess
												? t('Email Changed')
												: t('Email not Changed')}
										</div>
										<div className='text-center h4 text-muted mb-5'>
											{isSuccess
												? t('The Email has been changed.')
												: t('The Email has not been changed.')}
											<br />
											{isSuccess
												? t(
														'Click the button below to return to the main dashboard.',
												  )
												: t(
														'Please request a new email change in the profile section.',
												  )}
										</div>
									</>
								)}

								<div className='row g-4'>
									<div className='col-12'>
										<Button
											tag='a'
											isDisable={isLoading}
											to={pages.dashboard.path}
											color='warning'
											className='w-100 py-3'>
											{isLoading && <Spinner color='dark' inButton isSmall />}
											{isLoading ? t('Loading...') : t('To Dashboard')}
										</Button>
									</div>
								</div>
							</CardBody>
						</Card>
						<div className='text-center'>
							<a
								href='https://wowgenerator.de/privacy-policy/'
								target='_blank'
								rel='noreferrer'
								className='text-decoration-none me-3 link-dark'>
								{t('Privacy policy')}
							</a>
							<a
								href='https://wowgenerator.de/privacy-policy/'
								target='_blank'
								rel='noreferrer'
								className='link-light text-decoration-none link-dark'>
								{t('Terms of use')}
							</a>
						</div>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default ChangeEmailConfirmPage;
