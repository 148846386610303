import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
	AimOutlined,
	DownloadOutlined,
	DownOutlined,
	FileOutlined,
	FolderOpenOutlined,
	PictureOutlined,
	PieChartOutlined,
	SaveOutlined,
	SelectOutlined,
	TagOutlined,
	ZoomInOutlined,
	ZoomOutOutlined,
} from '@ant-design/icons';
import { Button, Col, Dropdown, Input, InputNumber, Row, Space } from 'antd';
import * as pdfjsLib from 'pdfjs-dist';
import { useNavigate } from 'react-router-dom';
import DataContext from '../context/DataContext';
import { toBase64 } from '../../../../helpers/file-handle-helpers';
import showNotification from '../../../../components/extras/showNotification';
import Icon from '../../../../components/icon/Icon';
import { FILE_TYPE_NAME } from '../../../../common/services/ExportImportServiceBase';
import CustomTooltip from '../../../../components/CustomTooltip';
import pages from '../../../../menu';

const MIN_PICTURE_SIZE = 150;
const MAX_PICTURE_SIZE = 4000;

const ACCEPTED_FILE_TYPES = [
	'.jpg',
	'.png',
	'.jpeg',
	'.gif',
	'.bmp',
	'.tif',
	'.tiff',
	'.pdf',
	'.startwow', // Legacy support
	FILE_TYPE_NAME,
];

const GeneralMenu = () => {
	const { t: tg } = useTranslation('general');
	const { t } = useTranslation('startWOW');
	const {
		service,
		handleNewClick,
		fileName,
		setFileName,
		file,
		setFile,
		setRedoUndo,
		handleSelectorClick,
		gridSize,
		handleGridSizeChange,
		pieChartElementIsPresent,
		elementHeight,
		handleHeightChange,
		elementWidth,
		handleWidthChange,
		setShowHandleNewClickPrompt,
	} = useContext(DataContext);

	const navigate = useNavigate();

	const showUploadErrorNotification = () => {
		showNotification(
			<span className='d-flex align-items-center'>
				<Icon icon='Error' size='lg' className='me-1' />
				<span>{tg('Error')}</span>
			</span>,
			tg('The file could not be uploaded, because it is corrupt.'),
			'danger',
		);
	};

	const createUploadImageFileHandle = (img, fileNameArray, uploadedFile, isPdf) => {
		img.onload = async () => {
			if (
				img.height >= MIN_PICTURE_SIZE &&
				img.width >= MIN_PICTURE_SIZE &&
				img.height <= MAX_PICTURE_SIZE &&
				img.width <= MAX_PICTURE_SIZE
			) {
				setFileName(fileNameArray[0]);
				const newFile = !isPdf ? await toBase64(uploadedFile) : img.src;
				setFile(newFile);
				setRedoUndo(true);
				service.addPicture(newFile, img);
			} else {
				setRedoUndo(false, false);
				showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon='Error' size='lg' className='me-1' />
						<span>{tg('Picture not uploaded')}</span>
					</span>,
					tg('The picture you were trying to upload is not in the right format.', {
						min: MIN_PICTURE_SIZE,
						max: MAX_PICTURE_SIZE,
					}),
					'danger',
				);
			}
			setShowHandleNewClickPrompt(false);
		};
	};

	const uploadPDFFile = async (fr) => {
		try {
			const pdf = await pdfjsLib.getDocument(new Uint8Array(fr.result)).promise;

			if (pdf.numPages > 1) {
				showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon='Info' size='lg' className='me-1' />
						<span>{tg('Information')}</span>
					</span>,
					tg(
						'The PDF file contains more than one page, the first page was automatically used. If another page is to be used, the order in the PDF must be changed or a new file must be created.',
					),
				);
			}

			// Always get the first page, we will change this later on.
			const page = await pdf.getPage(1);
			const viewport = page.getViewport({ scale: 1 });

			// Prepare canvas using PDF page dimensions
			const pdfCanvas = document.createElement('canvas');
			const canvasContext = pdfCanvas.getContext('2d');
			pdfCanvas.height = viewport.height;
			pdfCanvas.width = viewport.width;

			// Render PDF page into canvas context
			const renderContext = {
				canvasContext,
				viewport,
			};
			await page.render(renderContext).promise;

			return pdfCanvas.toDataURL();
		} catch {
			// PDF loading error
			showNotification(
				<span className='d-flex align-items-center'>
					<Icon icon='Error' size='lg' className='me-1' />
					<span>{tg('Picture not uploaded')}</span>
				</span>,
				tg(
					'The PDF file could not be transformed into a picture, please try a different file.',
				),
				'danger',
			);
			return null;
		}
	};

	const handlePictureChange = async (e) => {
		if (e.target.files.length > 0) {
			const uploadedFile = e.target.files[0];
			const fileNameArray = uploadedFile.name.split('.');
			const fileExtension = `.${fileNameArray[fileNameArray.length - 1]}`;
			const isPdf = fileExtension === '.pdf';

			if (ACCEPTED_FILE_TYPES.indexOf(fileExtension) !== -1) {
				if (fileExtension !== FILE_TYPE_NAME) {
					const fr = new FileReader();

					fr.onload = async () => {
						// file is loaded
						const img = new Image();
						createUploadImageFileHandle(img, fileNameArray, uploadedFile, isPdf);

						// If the file is a pdf we need to convert it beforehand
						img.src = isPdf ? await uploadPDFFile(fr) : fr.result;
					};

					if (isPdf) {
						fr.readAsArrayBuffer(uploadedFile);
					} else {
						fr.readAsDataURL(uploadedFile);
					}
				} else if (await service.uploadGraph(uploadedFile, showUploadErrorNotification)) {
					setFileName(fileNameArray[0]);
					setFile(fileNameArray[0]);
					setRedoUndo(true);
					setShowHandleNewClickPrompt(false);
				} else {
					setFileName('');
					setFile(null);
					setRedoUndo(false, false);
					setShowHandleNewClickPrompt(false);
				}
			} else {
				setFileName('');
				setFile(null);
				setRedoUndo(false, false);
				setShowHandleNewClickPrompt(false);
				showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon='Error' size='lg' className='me-1' />
						<span>{tg('Picture not uploaded')}</span>
					</span>,
					tg(
						'The file type of the image you wanted to upload is not supported. Accepted types are: ',
						{
							types: ACCEPTED_FILE_TYPES.join(', '),
						},
					),
					'danger',
				);
			}
		}
	};

	const onFileMenuItemClick = ({ key }) => {
		switch (key) {
			case '0':
				if (handleNewClick(service.graphIsNotSaved(), false)) {
					service.clearCurrentPaper();
				}
				break;
			case '1':
				if (handleNewClick(service.graphIsNotSaved(), true)) {
					document.getElementById('uploadFileInput').click();
				}
				break;
			case '4':
				service.openAsPNG(fileName);
				break;
			case '5':
				service.openAsJPEG(fileName);
				break;
			case '7':
				navigate({
					pathname: pages.checkout.path,
				});
				break;
			// Saving as wowgenerator file is Upgrade your plan to access this feature
			case '2':
			// PDF and SVG are Upgrade your plan to access this feature
			// eslint-disable-next-line no-fallthrough
			case '6':
			case '3':
			default:
				break;
		}
	};

	const fileMenu = [
		{
			label: tg('New'),
			key: '0',
			disabled: file == null || file === '',
			title: tg('Delete the current objects and start from scratch'),
			icon: <FileOutlined />,
		},
		{
			label: tg('Open...'),
			key: '1',
			title: tg('Import a new picture or WOW Gènerator file'),
			icon: <FolderOpenOutlined />,
		},
		{
			label: tg('Save...'),
			key: '2',
			disabled: true,
			title:
				tg(
					'Save the current state as a WOW Génerator file to continue working with it later',
				) + tg(' (Upgrade your plan to access all features)'),
			icon: <SaveOutlined />,
		},
		{
			type: 'divider',
		},
		{
			label: tg('Download as SVG'),
			key: '3',
			disabled: true,
			title:
				tg('Save the current state as a SVG to your computer') +
				tg(' (Upgrade your plan to access all features)'),
			icon: <DownloadOutlined />,
		},
		{
			label: tg('Download as PNG'),
			key: '4',
			disabled: true,
			title:
				tg('Save the current state as a PNG to your computer') +
				tg(' (Upgrade your plan to access all features)'),
			icon: <DownloadOutlined />,
		},
		{
			label: tg('Download as JPEG'),
			key: '5',
			disabled: file == null || file === '',
			title: tg('Save the current state as a JPEG to your computer'),
			icon: <DownloadOutlined />,
		},
		{
			label: tg('Download as PDF'),
			key: '6',
			disabled: true,
			title:
				tg('Save the current state as a PDF to your computer') +
				tg(' (Upgrade your plan to access all features)'),
			icon: <DownloadOutlined />,
		},
		{
			type: 'divider',
		},
		{
			label: tg('Upgrade your plan'),
			key: '7',
			disabled: false,
			title: tg('Upgrade your plan to get access to all the features of WOW Génerator'),
			icon: <TagOutlined />,
		},
	];

	return (
		<Row gutter={[16, 16]} style={{ padding: '0.5rem 1.5rem' }}>
			<Col flex={2}>
				<CustomTooltip title={`${tg('Import or export the image here')}`}>
					<Dropdown
						menu={{
							items: fileMenu,
							onClick: onFileMenuItemClick,
						}}
						trigger={['click']}>
						<Button>
							<Space>
								{tg('File')}
								<DownOutlined />
							</Space>
						</Button>
					</Dropdown>
				</CustomTooltip>
				<input
					id='uploadFileInput'
					type='file'
					style={{ display: 'none' }}
					onChange={handlePictureChange}
					accept={ACCEPTED_FILE_TYPES.join(', ')}
				/>
			</Col>
			<Col flex={3}>
				<Button.Group>
					<CustomTooltip title={`${tg('Zoom in')}`}>
						<Button
							color='primary'
							icon={<ZoomInOutlined />}
							disabled={file == null || file === ''}
							onClick={() => service.zoomIn()}
						/>
					</CustomTooltip>
					<CustomTooltip title={`${tg('Zoom out')}`}>
						<Button
							color='primary'
							icon={<ZoomOutOutlined />}
							disabled={file == null || file === ''}
							onClick={() => service.zoomOut()}
						/>
					</CustomTooltip>
					<CustomTooltip
						title={`${tg('Centres the image on the middle of the view')}${tg(
							' (Upgrade your plan to access all features)',
						)}`}>
						<Button
							color='primary'
							icon={<AimOutlined />}
							disabled
							onClick={() => service.centerElements()}
						/>
					</CustomTooltip>
					{/*	Wurde in Ticket #82 erst einmal ausgebaut
					<CustomTooltip title={`${tg('Undo last change')}`}>
						<Button
							color='primary'
							icon={<UndoOutlined />}
							disabled={!hasUndo}
							onClick={handleUndoClick}>
							{tg('Undo')}
						</Button>
					</CustomTooltip>
					<CustomTooltip title={`${tg('Redo last change')}`}>
						<Button
							color='primary'
							icon={<RedoOutlined />}
							disabled={!hasRedo}
							onClick={handleRedoClick}>
							{tg('Redo')}
						</Button>
					</CustomTooltip> */}
				</Button.Group>
			</Col>
			<Col flex={4}>
				<Button.Group>
					<CustomTooltip
						title={`${t('Select the element containing the picture you uploaded')}`}>
						<Button
							color='primary'
							icon={<PictureOutlined />}
							disabled={file == null || file === ''}
							onClick={() => service.selectPictureCell()}>
							{t('Select Picture')}
						</Button>
					</CustomTooltip>
					<CustomTooltip title={`${t('Select the desired area of the floor plan')}`}>
						<Button
							color='primary'
							icon={<SelectOutlined />}
							disabled={file == null || file === ''}
							onClick={handleSelectorClick}>
							{t('Select Area')}
						</Button>
					</CustomTooltip>
					<CustomTooltip title={`${t('Select the Pie Chart')}`}>
						<Button
							color='primary'
							icon={<PieChartOutlined />}
							disabled={!pieChartElementIsPresent}
							onClick={() => service.selectPieChartCell()}>
							{t('Select Pie Chart')}
						</Button>
					</CustomTooltip>
				</Button.Group>
			</Col>
			<Col flex={3}>
				<Input.Group compact>
					<CustomTooltip
						title={`${tg('Grid Size Info')}${tg(
							' (Upgrade your plan to access all features)',
						)}`}>
						<InputNumber
							max={30}
							min={1}
							value={gridSize}
							disabled
							onChange={handleGridSizeChange}
						/>
					</CustomTooltip>
					<CustomTooltip
						title={`${tg('Height')}${tg(
							' (Upgrade your plan to access all features)',
						)}`}>
						<InputNumber
							min={0}
							max={3000}
							formatter={(value) => `${value} px`}
							parser={(value) => value.replace('px', '')}
							disabled
							value={elementHeight}
							onChange={handleHeightChange}
						/>
					</CustomTooltip>
					<CustomTooltip
						title={`${tg('Width')}${tg(
							' (Upgrade your plan to access all features)',
						)}`}>
						<InputNumber
							min={0}
							max={3000}
							formatter={(value) => `${value} px`}
							parser={(value) => value.replace('px', '')}
							disabled
							value={elementWidth}
							onChange={handleWidthChange}
						/>
					</CustomTooltip>
				</Input.Group>
			</Col>
		</Row>
	);
};

export default GeneralMenu;
