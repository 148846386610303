import { dia } from '@clientio/rappid';
import _ from 'lodash';
import { CELESTIAL_DIRECTIONS } from '../../../../common/services/RappidServiceBase';
import {
	getAngleForDirectionFromCustomAngle,
	getPieChartCoordinates,
} from '../../../../helpers/pie-chart-helpers';
import {
	FLYING_STARS_MAIN_GROUP_ID,
	FLYING_STARS_SECONDARY_GROUP_ID,
} from '../../../../common/data/selectorIDs';
import i18n from '../../../../i18n';
import { shiftArrayToRight } from '../../../../helpers/helpers';

export const FlyingStars8SectorsPie = dia.Element.define(
	'fengshui.FlyingStars8SectorsPie',
	{},
	{
		markup: [
			{
				tagName: 'g',
				selector: 'flyingStarsGroups',
				children: [
					{
						tagName: 'g',
						selector: 'nGroup',
						children: [
							{
								tagName: 'circle',
								selector: 'n',
							},
							{
								tagName: 'text',
								selector: 'nSitting',
							},
							{
								tagName: 'text',
								selector: 'nFacing',
							},
							{
								tagName: 'text',
								selector: 'nBase',
							},
							{
								tagName: 'text',
								selector: 'nYear',
							},
						],
					},
					{
						tagName: 'g',
						selector: 'neGroup',
						children: [
							{
								tagName: 'circle',
								selector: 'ne',
							},
							{
								tagName: 'text',
								selector: 'neSitting',
							},
							{
								tagName: 'text',
								selector: 'neFacing',
							},
							{
								tagName: 'text',
								selector: 'neBase',
							},
							{
								tagName: 'text',
								selector: 'neYear',
							},
						],
					},
					{
						tagName: 'g',
						selector: 'eGroup',
						children: [
							{
								tagName: 'circle',
								selector: 'e',
							},
							{
								tagName: 'text',
								selector: 'eSitting',
							},
							{
								tagName: 'text',
								selector: 'eFacing',
							},
							{
								tagName: 'text',
								selector: 'eBase',
							},
							{
								tagName: 'text',
								selector: 'eYear',
							},
						],
					},
					{
						tagName: 'g',
						selector: 'seGroup',
						children: [
							{
								tagName: 'circle',
								selector: 'se',
							},
							{
								tagName: 'text',
								selector: 'seSitting',
							},
							{
								tagName: 'text',
								selector: 'seFacing',
							},
							{
								tagName: 'text',
								selector: 'seBase',
							},
							{
								tagName: 'text',
								selector: 'seYear',
							},
						],
					},
					{
						tagName: 'g',
						selector: 'sGroup',
						children: [
							{
								tagName: 'circle',
								selector: 's',
							},
							{
								tagName: 'text',
								selector: 'sSitting',
							},
							{
								tagName: 'text',
								selector: 'sFacing',
							},
							{
								tagName: 'text',
								selector: 'sBase',
							},
							{
								tagName: 'text',
								selector: 'sYear',
							},
						],
					},
					{
						tagName: 'g',
						selector: 'swGroup',
						children: [
							{
								tagName: 'circle',
								selector: 'sw',
							},
							{
								tagName: 'text',
								selector: 'swSitting',
							},
							{
								tagName: 'text',
								selector: 'swFacing',
							},
							{
								tagName: 'text',
								selector: 'swBase',
							},
							{
								tagName: 'text',
								selector: 'swYear',
							},
						],
					},
					{
						tagName: 'g',
						selector: 'wGroup',
						children: [
							{
								tagName: 'circle',
								selector: 'w',
							},
							{
								tagName: 'text',
								selector: 'wSitting',
							},
							{
								tagName: 'text',
								selector: 'wFacing',
							},
							{
								tagName: 'text',
								selector: 'wBase',
							},
							{
								tagName: 'text',
								selector: 'wYear',
							},
						],
					},
					{
						tagName: 'g',
						selector: 'nwGroup',
						children: [
							{
								tagName: 'circle',
								selector: 'nw',
							},
							{
								tagName: 'text',
								selector: 'nwSitting',
							},
							{
								tagName: 'text',
								selector: 'nwFacing',
							},
							{
								tagName: 'text',
								selector: 'nwBase',
							},
							{
								tagName: 'text',
								selector: 'nwYear',
							},
						],
					},
				],
			},
		],
	},
);

export const FlyingStars9RectanglesChart = dia.Element.define(
	'fengshui.FlyingStars9RectanglesChart',
	{},
	{
		markup: [
			{
				tagName: 'g',
				selector: 'flyingStarsRectangleGroups',
				children: [
					{
						tagName: 'g',
						selector: 'nGroup',
						children: [
							{
								tagName: 'rect',
								selector: 'nRect',
							},
							{
								tagName: 'text',
								selector: 'nDirectionText',
							},
							{
								tagName: 'text',
								selector: 'nSitting',
							},
							{
								tagName: 'text',
								selector: 'nFacing',
							},
							{
								tagName: 'text',
								selector: 'nBase',
							},
							{
								tagName: 'text',
								selector: 'nYear',
							},
						],
					},
					{
						tagName: 'g',
						selector: 'neGroup',
						children: [
							{
								tagName: 'rect',
								selector: 'neRect',
							},
							{
								tagName: 'text',
								selector: 'neDirectionText',
							},
							{
								tagName: 'text',
								selector: 'neSitting',
							},
							{
								tagName: 'text',
								selector: 'neFacing',
							},
							{
								tagName: 'text',
								selector: 'neBase',
							},
							{
								tagName: 'text',
								selector: 'neYear',
							},
						],
					},
					{
						tagName: 'g',
						selector: 'eGroup',
						children: [
							{
								tagName: 'rect',
								selector: 'eRect',
							},
							{
								tagName: 'text',
								selector: 'eDirectionText',
							},
							{
								tagName: 'text',
								selector: 'eSitting',
							},
							{
								tagName: 'text',
								selector: 'eFacing',
							},
							{
								tagName: 'text',
								selector: 'eBase',
							},
							{
								tagName: 'text',
								selector: 'eYear',
							},
						],
					},
					{
						tagName: 'g',
						selector: 'seGroup',
						children: [
							{
								tagName: 'rect',
								selector: 'seRect',
							},
							{
								tagName: 'text',
								selector: 'seDirectionText',
							},
							{
								tagName: 'text',
								selector: 'seSitting',
							},
							{
								tagName: 'text',
								selector: 'seFacing',
							},
							{
								tagName: 'text',
								selector: 'seBase',
							},
							{
								tagName: 'text',
								selector: 'seYear',
							},
						],
					},
					{
						tagName: 'g',
						selector: 'sGroup',
						children: [
							{
								tagName: 'rect',
								selector: 'sRect',
							},
							{
								tagName: 'text',
								selector: 'sDirectionText',
							},
							{
								tagName: 'text',
								selector: 'sSitting',
							},
							{
								tagName: 'text',
								selector: 'sFacing',
							},
							{
								tagName: 'text',
								selector: 'sBase',
							},
							{
								tagName: 'text',
								selector: 'sYear',
							},
						],
					},
					{
						tagName: 'g',
						selector: 'swGroup',
						children: [
							{
								tagName: 'rect',
								selector: 'swRect',
							},
							{
								tagName: 'text',
								selector: 'swDirectionText',
							},
							{
								tagName: 'text',
								selector: 'swSitting',
							},
							{
								tagName: 'text',
								selector: 'swFacing',
							},
							{
								tagName: 'text',
								selector: 'swBase',
							},
							{
								tagName: 'text',
								selector: 'swYear',
							},
						],
					},
					{
						tagName: 'g',
						selector: 'wGroup',
						children: [
							{
								tagName: 'rect',
								selector: 'wRect',
							},
							{
								tagName: 'text',
								selector: 'wDirectionText',
							},
							{
								tagName: 'text',
								selector: 'wSitting',
							},
							{
								tagName: 'text',
								selector: 'wFacing',
							},
							{
								tagName: 'text',
								selector: 'wBase',
							},
							{
								tagName: 'text',
								selector: 'wYear',
							},
						],
					},
					{
						tagName: 'g',
						selector: 'nwGroup',
						children: [
							{
								tagName: 'rect',
								selector: 'nwRect',
							},
							{
								tagName: 'text',
								selector: 'nwDirectionText',
							},
							{
								tagName: 'text',
								selector: 'nwSitting',
							},
							{
								tagName: 'text',
								selector: 'nwFacing',
							},
							{
								tagName: 'text',
								selector: 'nwBase',
							},
							{
								tagName: 'text',
								selector: 'nwYear',
							},
						],
					},
					{
						tagName: 'g',
						selector: 'midGroup',
						children: [
							{
								tagName: 'rect',
								selector: 'midRect',
							},
							{
								tagName: 'text',
								selector: 'midSitting',
							},
							{
								tagName: 'text',
								selector: 'midFacing',
							},
							{
								tagName: 'text',
								selector: 'midBase',
							},
							{
								tagName: 'text',
								selector: 'midYear',
							},
						],
					},
				],
			},
		],
	},
);

export function createFlyingStars8SectorsPieElement(
	values,
	facingSittingBaseValues,
	yearEnergyValues,
	pieCellBBox,
	pieChart,
) {
	const initialFCOffset = 100;
	const labelCircleR = 14 + 12 + 12 * 2;
	const pieChartKind = pieChart.attr('custom/pieChartKind');
	const distanceToCenter =
		(pieChart.attr('custom/distanceToCenter') < 0.4
			? pieChart.attr('custom/distanceToCenter') * 0.5
			: pieChart.attr('custom/distanceToCenter') * 1.5) / 100;
	const newAngle = getAngleForDirectionFromCustomAngle(
		pieChart.attr('custom/angle'),
		pieChart.attr('custom/direction'),
	);
	let height;
	let width;

	switch (pieChartKind) {
		case 'R':
		case 'E':
			height = pieCellBBox.height;
			width = pieCellBBox.width;
			break;
		case 'S':
		case 'C':
		default:
			height = Math.max(pieCellBBox.width, pieCellBBox.height);
			width = Math.max(pieCellBBox.width, pieCellBBox.height);
			break;
	}
	const element = new FlyingStars8SectorsPie({
		id: FLYING_STARS_MAIN_GROUP_ID,
		position: {
			x: pieCellBBox.center().x - width / 2,
			y: pieCellBBox.center().y - height / 2,
		},
		preserveAspectRatio: pieChartKind === 'S' || pieChartKind === 'C',
		size: {
			height,
			width,
		},
	});

	let elemAttrs = {};

	CELESTIAL_DIRECTIONS.forEach((currentDirection, index) => {
		if (currentDirection.endsWith('2')) {
			const parsedDirection = currentDirection.slice(0, -1);
			const newIndex = index + 2 + (newAngle / 360) * 24;
			const currentAngle = (newIndex * 2 * Math.PI) / 24;

			const coordinates = getPieChartCoordinates(
				pieChartKind,
				currentAngle,
				width,
				height,
				distanceToCenter,
			);

			elemAttrs[parsedDirection] = {
				cx: coordinates.x,
				cy: coordinates.y,
				r: labelCircleR,
				fill: 'rgba(240,240,240,.3)',
				style: `fill:${'rgb(240,240,240)'};opacity:${0.3};`,
			};
			elemAttrs = setAllElementValuesForPieChart(
				initialFCOffset / 100,
				elemAttrs,
				parsedDirection,
				coordinates,
				labelCircleR,
				values.showCombinationFacingSitting,
				values.showBasicEnergy,
				facingSittingBaseValues,
				yearEnergyValues,
			);
		}
	});

	elemAttrs.custom = {
		facingSittingOffset: initialFCOffset,
		showCombinationFacingSitting: values.showCombinationFacingSitting,
		showBasicEnergy: values.showBasicEnergy,
		showYearEnergy: values.showYearEnergy,
		angle: values.angle,
		pieChartKind,
		angleChangedInternally: false,
		distanceToCenter: distanceToCenter * 100,
	};

	element.attr(elemAttrs);

	return element;
}

export function createFlyingStars9RectanglesChartElement(
	values,
	facingSittingBaseValues,
	yearEnergyValues,
	pieCellBBox,
	pictureCellBBox,
	pieChart,
	isMainElement,
) {
	const initialFCOffset = 80;
	const height = Math.max(pieCellBBox.width, pieCellBBox.height) * 0.7;
	const width = Math.max(pieCellBBox.width, pieCellBBox.height) * 0.7;
	const rectHeight = height / 3;
	const rectWidth = width / 3;
	const element = new FlyingStars9RectanglesChart({
		id: isMainElement ? FLYING_STARS_MAIN_GROUP_ID : FLYING_STARS_SECONDARY_GROUP_ID,
		position: {
			x: pictureCellBBox.topRight().x * 1.02,
			y: pictureCellBBox.topRight().y,
		},
		preserveAspectRatio: true,
		size: {
			height,
			width,
		},
	});

	let elemAttrs = {};
	let xIndex = 1;
	let yIndex = 1;

	getShiftedCelestialDirections(pieChart).forEach((currentDirection) => {
		if (xIndex === 2 && yIndex === 2) {
			const coordinates = { x: rectHeight * xIndex, y: rectWidth * yIndex };
			elemAttrs.midRect = {
				height: rectHeight,
				width: rectWidth,
				strokeWidth: 1,
				stroke: 'rgba(0,0,0,1)',
				fill: 'transparent',
				x: coordinates.x,
				y: coordinates.y,
				style: `fill: transparent;stroke-width:${1};stroke:${'rgba(0,0,0,1)'}`,
			};

			setAllElementValuesForRectChart(
				initialFCOffset / 200,
				elemAttrs,
				'mid',
				coordinates,
				rectHeight,
				rectWidth,
				values.showCombinationFacingSitting,
				values.showBasicEnergy,
				facingSittingBaseValues,
				yearEnergyValues,
			);
			xIndex += 1;
		}
		if (currentDirection.endsWith('2')) {
			const parsedDirection = currentDirection.slice(0, -1);
			const coordinates = { x: rectHeight * xIndex, y: rectWidth * yIndex };
			elemAttrs[`${parsedDirection}Rect`] = {
				height: rectHeight,
				width: rectWidth,
				stroke: 'rgba(0,0,0,1)',
				fill: 'transparent',
				x: coordinates.x,
				y: coordinates.y,
				style: `fill: transparent;stroke-width:${1};stroke:${'rgba(0,0,0,1)'}`,
			};

			elemAttrs = setAllElementValuesForRectChart(
				initialFCOffset / 200,
				elemAttrs,
				parsedDirection,
				coordinates,
				rectHeight,
				rectWidth,
				values.showCombinationFacingSitting,
				values.showBasicEnergy,
				facingSittingBaseValues,
				yearEnergyValues,
			);

			if (!(xIndex === 2 && yIndex === 2)) {
				let x = coordinates.x * 1.05 + rectWidth;
				let y = coordinates.y * 1.05 + rectHeight;

				if (xIndex === 1) {
					x = coordinates.x * 0.9;
				} else if (xIndex === 2) {
					x = coordinates.x + rectWidth * 0.5;
				}

				if (yIndex === 1) {
					y = coordinates.y * 0.9;
				} else if (yIndex === 2) {
					y = coordinates.y + rectHeight * 0.5;
				}

				elemAttrs[`${parsedDirection}DirectionText`] = {
					text: i18n.t(parsedDirection.toUpperCase(), { ns: 'general' }),
					x,
					y,
					textAnchor: 'middle',
					dominantBaseline: 'central',
					fontFamily: 'Arial',
					fill: 'rgba(0,0,0,1)',
					fontSize: 24,
					style: `fill:${'rgb(0,0,0)'};opacity:${1};font-family:Arial;font-size:${24}px;`,
				};
			}

			xIndex = yIndex === 3 ? xIndex - 1 : xIndex + 1;
			if ((xIndex === 4 && yIndex === 1) || (xIndex === 4 && yIndex === 2)) {
				xIndex = 3;
				yIndex += 1;
			} else if (xIndex === 0 && yIndex === 3) {
				xIndex = 1;
				yIndex -= 1;
			}
		}
	});
	elemAttrs.custom = {
		facingSittingOffset: initialFCOffset,
		showCombinationFacingSitting: values.showCombinationFacingSitting,
		showBasicEnergy: values.showBasicEnergy,
		showYearEnergy: values.showYearEnergy,
		angle: values.angle,
		rectHeight,
		rectWidth,
	};

	element.attr(elemAttrs);

	return element;
}

function setAllElementValuesForPieChart(
	fcOffset,
	elemAttrs,
	parsedDirection,
	coordinates,
	circleSize,
	showFacingSitting,
	showBase,
	facingSittingBaseValues,
	yearEnergyValues,
) {
	const calculatedFCOffset = (circleSize / 2) * fcOffset;
	elemAttrs[`${parsedDirection}Sitting`] = {
		text: showFacingSitting ? facingSittingBaseValues[`${parsedDirection}Sitting`] : '',
		x: coordinates.x - calculatedFCOffset,
		y: coordinates.y - calculatedFCOffset,
		textAnchor: 'middle',
		dominantBaseline: 'central',
		fontFamily: 'Arial',
		fill: 'rgba(0,0,0,1)',
		fontSize: 12,
		style: `fill:${'rgb(0,0,0)'};opacity:${1};font-family:Arial;font-size:${12}px;`,
	};
	elemAttrs[`${parsedDirection}Facing`] = {
		text: showFacingSitting ? facingSittingBaseValues[`${parsedDirection}Facing`] : '',
		x: coordinates.x + calculatedFCOffset,
		y: coordinates.y - calculatedFCOffset,
		textAnchor: 'middle',
		dominantBaseline: 'central',
		fontFamily: 'Arial',
		fill: 'rgba(0,0,0,1)',
		fontSize: 12,
		style: `fill:${'rgb(0,0,0)'};opacity:${1};font-family:Arial;font-size:${12}px;`,
	};
	elemAttrs[`${parsedDirection}Base`] = {
		text: showBase ? facingSittingBaseValues[`${parsedDirection}Base`] : '',
		x: coordinates.x,
		y: coordinates.y,
		textAnchor: 'middle',
		dominantBaseline: 'central',
		fontFamily: 'Arial',
		fill: 'rgba(0,0,0,1)',
		fontSize: 12 + 2,
		style: `fill:${'rgb(0,0,0)'};opacity:${1};font-family:Arial;font-size:${12 + 2}px;`,
	};
	elemAttrs[`${parsedDirection}Year`] = {
		x: coordinates.x,
		y: coordinates.y + circleSize / 1.4,
		textAnchor: 'middle',
		dominantBaseline: 'central',
		fontFamily: 'Arial',
		fill: 'rgba(0,0,0,1)',
		fontSize: 12,
		style: `fill:${'rgb(0,0,0)'};opacity:${1};font-family:Arial;font-size:${12}px;`,
		text: yearEnergyValues == null ? '' : yearEnergyValues[parsedDirection],
	};

	return elemAttrs;
}

function setAllElementValuesForRectChart(
	fcOffset,
	elemAttrs,
	parsedDirection,
	coordinates,
	rectHeight,
	rectWidth,
	showFacingSitting,
	showBase,
	facingSittingBaseValues,
	yearEnergyValues,
) {
	elemAttrs[`${parsedDirection}Sitting`] = {
		text: showFacingSitting ? facingSittingBaseValues[`${parsedDirection}Sitting`] : '',
		x: coordinates.x + rectWidth * (0.55 - fcOffset),
		y: coordinates.y + rectHeight * (0.55 - fcOffset),
		textAnchor: 'middle',
		dominantBaseline: 'central',
		fontFamily: 'Arial',
		fill: 'rgba(0,0,0,1)',
		fontSize: 14,
		style: `fill:${'rgb(0,0,0)'};opacity:${1};font-family:Arial;font-size:${14}px;`,
	};
	elemAttrs[`${parsedDirection}Facing`] = {
		text: showFacingSitting ? facingSittingBaseValues[`${parsedDirection}Facing`] : '',
		x: coordinates.x + rectWidth * (0.45 + fcOffset),
		y: coordinates.y + rectHeight * (0.55 - fcOffset),
		textAnchor: 'middle',
		dominantBaseline: 'central',
		fontFamily: 'Arial',
		fill: 'rgba(0,0,0,1)',
		fontSize: 14,
		style: `fill:${'rgb(0,0,0)'};opacity:${1};font-family:Arial;font-size:${14}px;`,
	};
	elemAttrs[`${parsedDirection}Base`] = {
		text: showBase ? facingSittingBaseValues[`${parsedDirection}Base`] : '',
		x: coordinates.x + rectWidth * 0.5,
		y: coordinates.y + rectHeight * 0.5,
		textAnchor: 'middle',
		dominantBaseline: 'central',
		fontFamily: 'Arial',
		fill: 'rgba(0,0,0,1)',
		fontSize: 14 + 2,
		style: `fill:${'rgb(0,0,0)'};opacity:${1};font-family:Arial;font-size:${14 + 2}px;`,
	};
	elemAttrs[`${parsedDirection}Year`] = {
		x: coordinates.x + rectWidth * 0.5,
		y: coordinates.y + rectHeight * 0.9,
		textAnchor: 'middle',
		dominantBaseline: 'central',
		fontFamily: 'Arial',
		fill: 'rgba(0,0,0,1)',
		fontSize: 14,
		style: `fill:${'rgb(0,0,0)'};opacity:${1};font-family:Arial;font-size:${14}px;`,
		text: yearEnergyValues == null ? '' : yearEnergyValues[parsedDirection],
	};

	return elemAttrs;
}

function getShiftedCelestialDirections(pieChart) {
	const newAngle = getAngleForDirectionFromCustomAngle(
		pieChart.attr('custom/angle'),
		pieChart.attr('custom/direction'),
	);
	let startDirection = '';
	let currentDirection = null;
	let index = 0;
	while (index < CELESTIAL_DIRECTIONS.length && startDirection === '') {
		currentDirection = CELESTIAL_DIRECTIONS[index];
		if (currentDirection.endsWith('2')) {
			const newIndex = index + 2 + (newAngle / 360) * 24;
			let currentAngle = Math.trunc(((newIndex * 2 * Math.PI) / 24) * (180 / Math.PI));

			if (currentAngle > 360) {
				currentAngle -= 360;
			}

			// Needs to be between > 292.5 and <= 337.5. Internally it is 202.5 and 247.5
			if (currentAngle > 202.5 && currentAngle <= 247.5) {
				startDirection = currentDirection;
			}
		}
		index += 1;
	}

	return shiftArrayToRight(
		_.cloneDeep(CELESTIAL_DIRECTIONS),
		CELESTIAL_DIRECTIONS.length - CELESTIAL_DIRECTIONS.indexOf(startDirection) + 1,
	);
}
