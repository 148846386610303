import handleResponse, { handleResponseWithoutRedirect } from '../helpers/handle-response';
import authenticationService, { BACKEND_URL } from './authentication.service';

const userService = {
	changePassword,
	initConfirmEmail,
	initChangeEmail,
	confirmChangeEmail,
	resetSubscription,
};

function changePassword(currentPassword, newPassword, newPasswordConfirmation) {
	const currentUser = authenticationService.currentUserValue;
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${currentUser.token}`,
		},
		body: JSON.stringify({ currentPassword, newPassword, newPasswordConfirmation }),
	};

	return fetch(`${BACKEND_URL}/user/changePassword`, requestOptions).then(handleResponse);
}

function initConfirmEmail(eMail) {
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({ eMail }),
	};

	return fetch(`${BACKEND_URL}/user/initConfirmEmail`, requestOptions).then(
		handleResponseWithoutRedirect,
	);
}

function initChangeEmail(eMail, newEmail) {
	const currentUser = authenticationService.currentUserValue;
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${currentUser.token}`,
		},
		body: JSON.stringify({ eMail, newEmail }),
	};

	return fetch(`${BACKEND_URL}/user/initChangeEmail`, requestOptions).then(handleResponse);
}

function confirmChangeEmail(oldEmail, newEmail, token) {
	const currentUser = authenticationService.currentUserValue;
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${currentUser.token}`,
		},
		body: JSON.stringify({ oldEmail, newEmail, token }),
	};

	return fetch(`${BACKEND_URL}/user/confirmChangeEmail`, requestOptions)
		.then(handleResponse)
		.then(() => {
			// set the new email in currentUser
			const newUser = authenticationService.currentUserValue;
			newUser.email = newEmail;
			localStorage.setItem('currentUser', JSON.stringify(newUser));
			authenticationService.setNext(newUser);

			return newUser;
		});
}

function resetSubscription() {
	const currentUser = authenticationService.currentUserValue;
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${currentUser.token}`,
		},
	};

	return fetch(`${BACKEND_URL}/user/resetsubscription`, requestOptions)
		.then(handleResponse)
		.then(() => {
			// set the new email in currentUser
			const newUser = authenticationService.currentUserValue;
			localStorage.setItem('currentUser', JSON.stringify(newUser));
			authenticationService.setNext(newUser);
			return newUser;
		});
}

export default userService;
