import { dia, shapes, ui } from '@clientio/rappid';
import _ from 'lodash';
import { PIE_CHART_ID } from '../data/selectorIDs';
import {
	FlyingStars8SectorsPie,
	FlyingStars9RectanglesChart,
} from '../../pages/fly/flyWow/models/JointShapes';
import { CustomPieChart } from '../../pages/start/startWow/models/JointShapes';

export const CELESTIAL_DIRECTIONS = [
	'n1',
	'n2',
	'n3',
	'ne1',
	'ne2',
	'ne3',
	'e1',
	'e2',
	'e3',
	'se1',
	'se2',
	'se3',
	's1',
	's2',
	's3',
	'sw1',
	'sw2',
	'sw3',
	'w1',
	'w2',
	'w3',
	'nw1',
	'nw2',
	'nw3',
];

export const PAPER_SIZE = 7000;

class RappidServiceBase {
	constructor(el, keyboardService, handleNewClick, setRedoUndo) {
		this.el = el;
		this.keyboardService = keyboardService;
		this.handleNewClick = handleNewClick;
		this.setRedoUndo = setRedoUndo;
		this.extName = 'wowgenerator';

		shapes.fengshui = {};
		shapes.fengshui.PieChart = CustomPieChart;
		shapes.fengshui.FlyingStars8SectorsPie = FlyingStars8SectorsPie;
		shapes.fengshui.FlyingStars9RectanglesChart = FlyingStars9RectanglesChart;
	}

	startRappid(setSubmitModalStatus, haloService, exportImportService) {
		this.initializePaper();
		this.initializeSelection();
		this.initializeKeyboardShortcuts();
		this.initializeTooltips();

		this.haloService = haloService;
		this.exportImportService = exportImportService;
	}

	stopRappid() {
		this.paper.remove();
		this.selection.remove();
		this.keyboardService.keyboard.stopListening();
		this.tooltip.remove();
	}

	initializePaper() {
		this.graph = new dia.Graph({}, { cellNamespace: shapes });

		this.commandManager = new dia.CommandManager({ graph: this.graph });

		this.paper = new dia.Paper({
			width: PAPER_SIZE,
			height: PAPER_SIZE,
			gridSize: 5,
			model: this.graph,
			interactive: { linkMove: false },
			async: true,
			sorting: dia.Paper.sorting.APPROX,
			cellViewNamespace: shapes,
			useModelGeometry: true,
		});

		this.paper.on('blank:mousewheel', _.partial(this.onMousewheel, null), this);
		this.paper.on('cell:mousewheel', this.onMousewheel.bind(this));

		this.snaplines = new ui.Snaplines({ paper: this.paper });

		this.paperScroller = new ui.PaperScroller({
			paper: this.paper,
			autoResizePaper: false,
			scrollWhileDragging: true,
			cursor: 'grab',
		});

		RappidServiceBase.renderPlugin(this.el, this.paperScroller);
		this.paperScroller.render().center();
	}

	initializeSelection() {
		this.clipboard = new ui.Clipboard();
		this.selection = new ui.Selection({ paper: this.paper, useModelGeometry: true });
		this.selection.collection.on('reset add remove', this.onSelectionChange.bind(this));

		const { keyboard } = this.keyboardService;

		// Initiate selecting when the user grabs the blank area of the paper while the Shift key is pressed.
		// Otherwise, initiate paper pan.
		this.paper.on('blank:pointerdown', (evt) => {
			this.setRedoUndo(this.commandManager.hasUndo(), this.commandManager.hasRedo());
			if (keyboard.isActive('shift', evt)) {
				this.selection.startSelecting(evt);
			} else {
				this.selection.collection.reset([]);
				this.paperScroller.startPanning(evt);
				this.paper.removeTools();
			}
		});

		this.paper.on('element:pointerdown', (elementView, evt) => {
			this.setRedoUndo(this.commandManager.hasUndo(), this.commandManager.hasRedo());
			// Select an element if CTRL/Meta key is pressed while the element is clicked.
			if (keyboard.isActive('ctrl meta', evt)) {
				this.selection.collection.add(elementView.model);
			}
		});

		this.graph.on('remove', (cell) => {
			this.setRedoUndo(this.commandManager.hasUndo(), this.commandManager.hasRedo());
			// If element is removed from the graph, remove from the selection too.
			if (this.selection.collection.has(cell)) {
				this.selection.collection.reset(
					this.selection.collection.models.filter((c) => c !== cell),
				);
			}
		});

		this.graph.on('change:size', () => {
			this.setRedoUndo(this.commandManager.hasUndo(), this.commandManager.hasRedo());
		});

		this.selection.on(
			'selection-box:pointerdown',
			(elementView, evt) => {
				this.setRedoUndo(this.commandManager.hasUndo(), this.commandManager.hasRedo());
				// Unselect an element if the CTRL/Meta key is pressed while a selected element is clicked.
				if (keyboard.isActive('ctrl meta', evt)) {
					evt.preventDefault();
					this.selection.collection.remove(elementView.model);
				}
			},
			this,
		);
	}

	initializeKeyboardShortcuts() {
		this.keyboardService.create(this.graph, this.clipboard, this.selection, this.paperScroller);
	}

	initializeTooltips() {
		this.tooltip = new ui.Tooltip({
			rootTarget: document.body,
			target: '[data-tooltip]',
			direction: ui.Tooltip.TooltipArrowPosition.Auto,
			padding: 10,
		});
	}

	centerElements() {
		this.paperScroller.scrollToContent();
		this.paperScroller.zoomToFit({ padding: 50, useModelGeometry: true });
	}

	zoomIn() {
		this.paperScroller.scrollToContent();
		this.paperScroller.zoom(0.2, { min: 0.2, grid: 0.2 });
	}

	zoomOut() {
		this.paperScroller.scrollToContent();
		this.paperScroller.zoom(-0.2, { min: 0.2, grid: 0.2 });
	}

	onMousewheel(cellView, evt, ox, oy, delta) {
		evt.preventDefault();
		this.paperScroller.zoom(delta * 0.2, { min: 0.2, max: 5, grid: 0.2, ox, oy });
	}

	performUndo() {
		this.commandManager.undo();
		this.setAllValuesAfterRedoUndo();
	}

	performRedo() {
		this.commandManager.redo();
		this.setAllValuesAfterRedoUndo();
	}

	setAllValuesAfterRedoUndo() {
		console.error('Not implemented.', this);
	}

	changeGridSize(newGridSize) {
		this.paper.setGridSize(newGridSize);
	}

	changeElementSize(newHeight, newWidth) {
		const { collection } = this.selection;
		if (collection.length === 1) {
			const primaryCell = collection.first();
			if (primaryCell.id !== PIE_CHART_ID) {
				primaryCell.resize(newWidth, newHeight);
			}
		}
	}

	onSelectionChange() {
		const { paper, selection } = this;
		const { collection } = selection;
		paper.removeTools();
		ui.Halo.clear(paper);
		ui.FreeTransform.clear(paper);

		if (collection.length === 1) {
			const primaryCell = collection.first();
			const primaryCellView = paper.requireView(primaryCell);
			selection.destroySelectionBox(primaryCell);
			this.selectPrimaryCell(primaryCellView);
		} else {
			selection.removeHandle('resize');
		}
	}

	clearCurrentPaper() {
		this.graph.clear();
		this.selection.collection.reset([]);
	}

	openAsSVG(fileName) {
		this.exportImportService.openAsSVG(fileName, this.extName);
	}

	openAsPNG(fileName) {
		this.exportImportService.openAsPNG(fileName, this.extName);
	}

	openAsJPEG(fileName) {
		this.exportImportService.openAsJPEG(fileName, this.extName);
	}

	openAsPDF(fileName) {
		this.exportImportService.openAsPDF(fileName, this.extName);
	}

	async saveGraph(fileName) {
		await this.exportImportService.saveGraph(fileName, this.extName);
	}

	graphIsNotSaved() {
		return this.exportImportService.graphIsNotSaved();
	}

	async uploadGraph(file, showNotification) {
		const result = await this.exportImportService.uploadGraph(file, showNotification);
		this.centerElements();
		return result;
	}

	selectPrimaryCell(cellView) {
		const cell = cellView.model;
		if (cell.isElement()) {
			this.selectPrimaryElement(cellView);
		}
	}

	selectPrimaryElement(elementView) {
		const elementModel = elementView.model;
		this.haloService.create(elementView, elementModel.id);
	}

	static renderPlugin(el, plugin) {
		el.appendChild(plugin.el);
		plugin.render();
	}
}

export default RappidServiceBase;
