import React from 'react';
import PropTypes from 'prop-types';
import { isWebpSupported } from 'react-image-webp/dist/utils';
import DashboardImageWebp from '../assets/img/logo/Logo_mit_DEV_Badge.webp';
import DashboardImage from '../assets/img/logo/Logo_mit_DEV_Badge.png';

const Logo = ({ width, height }) => {
	if (process.env.REACT_APP_SHOW_DEV_ICON === 'true')
		return (
			<img
				width={height !== 854 ? height * (1578.8 / 478.62) : width}
				alt='WOW Generator Dev Logo'
				src={isWebpSupported() ? DashboardImageWebp : DashboardImage}
			/>
		);
	return (
		<svg
			width={height !== 854 ? height * (1578.8 / 478.62) : width}
			height={width !== 2155 ? width * (478.62 / 1578.8) : height}
			fill='none'
			viewBox='0 0 1578.8 478.62'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				fill='currentColor'
				d='M175.12,225.57h16l26.39,79.95,25.62-79.64h19.8L225.45,334.06H209.18l-26.39-77.18L156.4,334.06H140.13L102.69,225.88H123.1l25.63,79.64Z'
			/>
			<path
				fill='currentColor'
				d='M379.82,300.92a54.22,54.22,0,0,1-11.67,17.72,56,56,0,0,1-40.51,16.49,57.4,57.4,0,0,1-22.71-4.37,53.31,53.31,0,0,1-29.15-29.54,57.79,57.79,0,0,1,0-43,54.07,54.07,0,0,1,11.66-17.73A56,56,0,0,1,328,224a57.51,57.51,0,0,1,22.71,4.37A53.28,53.28,0,0,1,379.82,258a57.82,57.82,0,0,1,0,43Zm-18.34-36a37.72,37.72,0,0,0-7.52-12.2,35.8,35.8,0,0,0-11.59-8.28,35.11,35.11,0,0,0-14.73-3.07,35.47,35.47,0,0,0-14.65,3,34.74,34.74,0,0,0-11.43,8.13,37.32,37.32,0,0,0-7.44,12.12,42.11,42.11,0,0,0,0,29.62,37.42,37.42,0,0,0,7.51,12.2,35.76,35.76,0,0,0,11.59,8.28,37.13,37.13,0,0,0,29.38.08A34.74,34.74,0,0,0,354,306.67a37.49,37.49,0,0,0,7.44-12.12,42.26,42.26,0,0,0,0-29.62Z'
			/>
			<path
				fill='currentColor'
				d='M465.13,225.57h16l26.39,79.95,25.63-79.64H552.9L515.46,334.06H499.2l-26.4-77.18-26.39,77.18H430.14L392.7,225.88h20.41l25.63,79.64Z'
			/>
			<path
				fill='#967135'
				d='M685.06,324.85a66.23,66.23,0,0,1-22.48,9,68,68,0,0,1-13.5,1.3,59.57,59.57,0,0,1-22.87-4.22,51,51,0,0,1-28.85-29.23,59.29,59.29,0,0,1-4-21.94,57.11,57.11,0,0,1,4.06-21.48,53.69,53.69,0,0,1,29-29.77A55.24,55.24,0,0,1,648.77,224a74.77,74.77,0,0,1,12.81,1,59.4,59.4,0,0,1,10.67,2.83,54.24,54.24,0,0,1,9.28,4.53,67.67,67.67,0,0,1,8.52,6.22l-12,14.27a68.84,68.84,0,0,0-6.29-4.76,39.57,39.57,0,0,0-6.76-3.61,42.18,42.18,0,0,0-7.75-2.3,47.55,47.55,0,0,0-9.28-.84,31.79,31.79,0,0,0-13.73,3,34.31,34.31,0,0,0-11,8.22,39.5,39.5,0,0,0-7.37,12.15,40.41,40.41,0,0,0-2.68,14.76,44,44,0,0,0,2.61,15.37,35.75,35.75,0,0,0,7.44,12.23,34.7,34.7,0,0,0,11.51,8.07,36.91,36.91,0,0,0,15,2.92A44.67,44.67,0,0,0,664,315.86a40.24,40.24,0,0,0,11.58-5.94V289.71H648.16V273.29h45.72v45.12A79.59,79.59,0,0,1,685.06,324.85Z'
			/>
			<path
				fill='#967135'
				d='M797.92,242.91H737.15v27.62H791v17H737.15v28.69h61.54v17H718.43V225.88h79.49Zm-61.84-43.58L753,191.81,769.22,217H754.8Z'
			/>
			<path
				fill='#967135'
				d='M896.12,225.88h18.72V333.29H899.07L840,256.88v76.41H821.24V225.88H838.7l57.42,74.27Z'
			/>
			<path
				fill='currentColor'
				d='M1022,242.91H961.18v27.62H1015v17H961.18v28.69h61.54v17H942.46V225.88H1022Z'
			/>
			<path
				fill='currentColor'
				d='M1114.17,333.29l-26.48-37.44H1064v37.44h-18.72V225.88h47.88a54.37,54.37,0,0,1,16.64,2.38,36.11,36.11,0,0,1,12.59,6.75,29.22,29.22,0,0,1,7.9,10.74,38.27,38.27,0,0,1,.84,26.24,31.1,31.1,0,0,1-5.29,9.36,32,32,0,0,1-8.06,6.91,39.94,39.94,0,0,1-10.35,4.37l29,40.66ZM1108,247.46q-5.85-4.54-16.47-4.55H1064v36.22h27.71q10.15,0,16.16-4.93a16.28,16.28,0,0,0,6-13.26Q1113.86,252,1108,247.46Z'
			/>
			<path
				fill='currentColor'
				d='M1259,333.29h-20l-10.89-25.93h-50.79l-11.05,25.93H1147l47.27-108.18h17.49Zm-56.32-85.62-18.41,43h37Z'
			/>
			<path fill='currentColor' d='M1309,333.29h-19V243.37H1256V225.88h87.16v17.49H1309Z' />
			<path
				fill='currentColor'
				d='M1460.65,300.92A53.9,53.9,0,0,1,1449,318.64a56,56,0,0,1-40.5,16.49,57.33,57.33,0,0,1-22.71-4.37,53.28,53.28,0,0,1-29.16-29.54,57.79,57.79,0,0,1,0-43,54.07,54.07,0,0,1,11.66-17.73A56,56,0,0,1,1408.78,224a57.51,57.51,0,0,1,22.71,4.37A53.28,53.28,0,0,1,1460.65,258a57.82,57.82,0,0,1,0,43Zm-18.34-36a37.72,37.72,0,0,0-7.52-12.2,35.76,35.76,0,0,0-11.58-8.28,37.15,37.15,0,0,0-29.39-.08,34.74,34.74,0,0,0-11.43,8.13,37.49,37.49,0,0,0-7.44,12.12,42.11,42.11,0,0,0,0,29.62,37.45,37.45,0,0,0,7.52,12.2,35.61,35.61,0,0,0,11.58,8.28,37.15,37.15,0,0,0,29.39.08,34.59,34.59,0,0,0,11.43-8.13,37.49,37.49,0,0,0,7.44-12.12,42.11,42.11,0,0,0,0-29.62Z'
			/>
			<path
				fill='currentColor'
				d='M1556.55,333.29l-26.47-37.44h-23.71v37.44h-18.72V225.88h47.88a54.44,54.44,0,0,1,16.65,2.38,36.15,36.15,0,0,1,12.58,6.75,29.11,29.11,0,0,1,7.9,10.74,38.36,38.36,0,0,1,.85,26.24,31.13,31.13,0,0,1-5.3,9.36,32,32,0,0,1-8,6.91,40,40,0,0,1-10.36,4.37l29,40.66Zm-6.16-85.83q-5.85-4.54-16.47-4.55h-27.55v36.22h27.71q10.15,0,16.16-4.93a16.28,16.28,0,0,0,6-13.26Q1556.24,252,1550.39,247.46Z'
			/>
			<path
				fill='#C91806'
				d='M117,373c-25.92-13.29-47.83-35.2-62.2-61.44A159.41,159.41,0,0,1,35.6,224.64a162,162,0,0,1,29.63-83.87c17.36-24.26,41.44-43.52,68.61-54C109.19,102.14,88.49,122.55,74,146.57a169.69,169.69,0,0,0-9.08,159.92C76.58,331.92,94.52,354.58,117,373Z'
			/>
			<path
				fill='#967135'
				d='M243,436.59c-30.42,8.36-63.43,8.15-94.68.31S87.71,413,63.69,391.15A202.12,202.12,0,0,1,32,354.29a193.49,193.49,0,0,1-21.55-43.4,182.53,182.53,0,0,1-7.35-94.52c.71,31.49,6.46,62.18,18.66,90.14A220.34,220.34,0,0,0,43.9,346.18a224.23,224.23,0,0,0,29.79,34.08,212.85,212.85,0,0,0,77.78,45.83C180.42,435.79,211.65,439.14,243,436.59Z'
			/>
			<path
				fill='#C91806'
				d='M379.1,369.22c-13,29.08-34.66,54.6-61.17,73.51-26.49,19.08-58.3,30.73-90.81,34.52a198.25,198.25,0,0,1-96.19-12.54,187.89,187.89,0,0,1-77-56.37C78,429,105.24,445.07,134.71,454.12a212,212,0,0,0,90.64,8.27,215.26,215.26,0,0,0,85.7-30.08C337.26,416.3,360.15,394.74,379.1,369.22Z'
			/>
			<path
				fill='#C91806'
				d='M133.83,152.44c13.82-20.87,34.1-37.78,57.33-48.84a152.31,152.31,0,0,1,75.65-13.84,148.84,148.84,0,0,1,72.83,25c21.4,14.37,38.86,34.38,49,57.3-16-19.24-34.67-35.1-55.62-46.53a168.57,168.57,0,0,0-67.42-20.3,166.45,166.45,0,0,0-69.93,9.33C173.16,122.56,152.32,135.67,133.83,152.44Z'
			/>
			<path
				fill='#967135'
				d='M66.64,263.47c-2.6-26.65,1.56-54.2,11.29-79.72A193.69,193.69,0,0,1,191.7,71.61c25.66-9.36,53.26-13.12,79.87-10.17-26.56,3-52.13,9.56-75.65,20.44A221,221,0,0,0,88.16,188.12C77,211.49,70,237,66.64,263.47Z'
			/>
			<polygon
				fill='#C91806'
				points='327.8 10.8 337.05 39.27 366.98 39.27 342.76 56.87 352.02 85.34 327.8 67.74 303.58 85.34 312.83 56.87 288.61 39.27 318.55 39.27 327.8 10.8'
			/>
			<polygon
				fill='#C91806'
				points='214.07 116.87 221.71 140.37 246.42 140.37 226.42 154.9 234.06 178.4 214.07 163.87 194.08 178.4 201.71 154.9 181.72 140.37 206.43 140.37 214.07 116.87'
			/>
			<polygon
				fill='#967135'
				points='214.07 0 219.66 17.2 237.74 17.2 223.11 27.83 228.7 45.02 214.07 34.4 199.44 45.02 205.03 27.83 190.4 17.2 208.48 17.2 214.07 0'
			/>
			<polygon
				fill='#967135'
				points='320.08 134.68 325.05 149.97 341.12 149.97 328.12 159.41 333.08 174.7 320.08 165.25 307.08 174.7 312.05 159.41 299.04 149.97 315.12 149.97 320.08 134.68'
			/>
			<polygon
				fill='#967135'
				points='411.71 85.34 416.19 99.14 430.71 99.14 418.96 107.68 423.45 121.48 411.71 112.95 399.96 121.48 404.45 107.68 392.7 99.14 407.22 99.14 411.71 85.34'
			/>
		</svg>
	);
};
Logo.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
};
Logo.defaultProps = {
	width: 2155,
	height: 854,
};

export default Logo;
