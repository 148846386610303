import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, InputNumber, Select, Row, Col, Segmented, Button } from 'antd';
import { BorderOuterOutlined } from '@ant-design/icons';
import DataContext from '../context/DataContext';
import ColorPicker from '../../../../components/ColorPicker';
import CustomTooltip from '../../../../components/CustomTooltip';

const { Option } = Select;

const PieChartMenu = () => {
	const { t } = useTranslation('startWOW');
	const {
		service,
		elementIsSelected,
		is24Pie,
		customAngle,
		direction,
		pieChartType,
		pieChartElementIsPresent,
		mainColor,
		mainStrokeWidth,
		mainDashArray,
		secondaryColor,
		secondaryStrokeWidth,
		secondaryDashArray,
		handleIs24PieChange,
		handleCustomAngleChange,
		handleDirectionChange,
		handlePieChartTypeChange,
		handleMainColorChange,
		handleMainStrokeWidthChange,
		handleMainDashArrayChange,
		handleSecondaryColorChange,
		handleSecondaryStrokeWidthChange,
		handleSecondaryDashArrayChange,
	} = useContext(DataContext);

	const eightOr24Options = [
		{
			label: t('24 Directions'),
			value: '1',
		},
		{
			label: t('8 Sectors'),
			value: '0',
		},
	];

	const selectDirection = (
		<CustomTooltip title={`${t('Origin of the gaze')}`}>
			<Select
				disabled={elementIsSelected !== 2}
				onChange={handleDirectionChange}
				value={direction}
				style={{
					width: 90,
				}}>
				<Option value='T'>{t('Top')}</Option>
				<Option value='R'>{t('Right')}</Option>
				<Option value='B'>{t('Bottom')}</Option>
				<Option value='L'>{t('Left')}</Option>
			</Select>
		</CustomTooltip>
	);

	return (
		<Row gutter={[16, 16]} style={{ padding: '0.5rem 1.5rem' }}>
			<Col span={12}>
				<CustomTooltip
					title={`${t('Centres the pie chart on the middle of the select area')}`}>
					<Button
						style={{
							marginRight: 10,
						}}
						color='primary'
						icon={<BorderOuterOutlined />}
						disabled={!pieChartElementIsPresent}
						onClick={() => service.centerPieChartOnSelectArea()}>
						{t('Center Pie Chart')}
					</Button>
				</CustomTooltip>
				<CustomTooltip title={`${t('8 Sectors or 24 Directions')}`}>
					<Segmented
						style={{
							marginRight: 10,
						}}
						options={eightOr24Options}
						disabled={elementIsSelected !== 2}
						onChange={handleIs24PieChange}
						value={is24Pie}
					/>
				</CustomTooltip>
				<CustomTooltip title={`${t('Angle')}`}>
					<InputNumber
						style={{
							width: 160,
							marginRight: 10,
						}}
						addonAfter={selectDirection}
						disabled={elementIsSelected !== 2}
						onChange={handleCustomAngleChange}
						value={customAngle}
						min={0}
						max={360}
						step={0.5}
					/>
				</CustomTooltip>
				<CustomTooltip title={`${t('Pie Chart Type')}`}>
					<Select
						dropdownMatchSelectWidth={false}
						disabled={elementIsSelected !== 2}
						onChange={handlePieChartTypeChange}
						value={pieChartType}>
						<Option value='C'>{t('Circle')}</Option>
						<Option value='S'>{t('Square')}</Option>
						<Option value='R'>{t('Rectangle')}</Option>
						<Option value='E'>{t('Ellipsis')}</Option>
					</Select>
				</CustomTooltip>
			</Col>
			<Col span={6}>
				<Input.Group compact>
					<CustomTooltip title={`${t('Color 8 Pie')}`}>
						<ColorPicker
							popup
							className='first-item'
							blockStyles={{
								width: '60px',
								height: '30px',
								marginTop: '1px',
								marginRight: '0',
							}}
							disabled={elementIsSelected !== 2}
							value={mainColor}
							onChange={handleMainColorChange}
						/>
					</CustomTooltip>
					<CustomTooltip title={`${t('Stroke Width 8 Pie')}`}>
						<InputNumber
							disabled={elementIsSelected !== 2}
							onChange={handleMainStrokeWidthChange}
							value={mainStrokeWidth}
							min={0}
							max={10}
							step={0.5}
						/>
					</CustomTooltip>
					<CustomTooltip title={`${t('Dash Array 8 Pie')}`}>
						<InputNumber
							disabled={elementIsSelected !== 2}
							onChange={handleMainDashArrayChange}
							value={mainDashArray}
							min={0}
							max={10}
							step={0.5}
						/>
					</CustomTooltip>
				</Input.Group>
			</Col>
			<Col span={6}>
				<Input.Group compact>
					<CustomTooltip title={`${t('Color 24 Pie')}`}>
						<ColorPicker
							popup
							className='first-item'
							blockStyles={{
								width: '60px',
								height: '30px',
								marginTop: '1px',
								marginRight: '0',
							}}
							disabled={elementIsSelected !== 2 || is24Pie === '0'}
							value={secondaryColor}
							onChange={handleSecondaryColorChange}
						/>
					</CustomTooltip>
					<CustomTooltip title={`${t('Stroke Width 24 Pie')}`}>
						<InputNumber
							disabled={elementIsSelected !== 2 || is24Pie === '0'}
							onChange={handleSecondaryStrokeWidthChange}
							value={secondaryStrokeWidth}
							min={0}
							max={10}
							step={0.5}
						/>
					</CustomTooltip>
					<CustomTooltip title={`${t('Dash Array 24 Pie')}`}>
						<InputNumber
							disabled={elementIsSelected !== 2 || is24Pie === '0'}
							onChange={handleSecondaryDashArrayChange}
							value={secondaryDashArray}
							min={0}
							max={10}
							step={0.5}
						/>
					</CustomTooltip>
				</Input.Group>
			</Col>
		</Row>
	);
};

export default PieChartMenu;
