import { dia } from '@clientio/rappid';
import PieChartService from '../services/PieChartService';
import { CELESTIAL_DIRECTIONS } from '../../../../common/services/RappidServiceBase';
import {
	getAngleForDirectionFromCustomAngle,
	getPieChartCoordinates,
} from '../../../../helpers/pie-chart-helpers';
import i18n from '../../../../i18n';
import { PIE_CHART_ID } from '../../../../common/data/selectorIDs';

export const CustomPieChart = dia.Element.define(
	'fengshui.PieChart',
	{},
	{
		markup: [
			{
				tagName: 'g',
				selector: 'pie',
				children: [
					{
						tagName: 'g',
						selector: 'eightPie',
						children: [
							{
								tagName: 'polyline',
								selector: 'l80',
							},
							{
								tagName: 'polyline',
								selector: 'l81',
							},
							{
								tagName: 'polyline',
								selector: 'l82',
							},
							{
								tagName: 'polyline',
								selector: 'l83',
							},
						],
					},
					{
						tagName: 'g',
						selector: 'twentyFourPie',
						children: [
							{
								tagName: 'polyline',
								selector: 'l240',
							},
							{
								tagName: 'polyline',
								selector: 'l241',
							},
							{
								tagName: 'polyline',
								selector: 'l242',
							},
							{
								tagName: 'polyline',
								selector: 'l243',
							},
							{
								tagName: 'polyline',
								selector: 'l244',
							},
							{
								tagName: 'polyline',
								selector: 'l245',
							},
							{
								tagName: 'polyline',
								selector: 'l246',
							},
							{
								tagName: 'polyline',
								selector: 'l247',
							},
						],
					},
				],
			},
			{
				tagName: 'g',
				selector: 'pieTexts',
				children: [
					{
						tagName: 'text',
						selector: 'n1',
					},
					{
						tagName: 'text',
						selector: 'n2',
					},
					{
						tagName: 'text',
						selector: 'n3',
					},
					{
						tagName: 'text',
						selector: 'ne1',
					},
					{
						tagName: 'text',
						selector: 'ne2',
					},
					{
						tagName: 'text',
						selector: 'ne3',
					},
					{
						tagName: 'text',
						selector: 'e1',
					},
					{
						tagName: 'text',
						selector: 'e2',
					},
					{
						tagName: 'text',
						selector: 'e3',
					},
					{
						tagName: 'text',
						selector: 'se1',
					},
					{
						tagName: 'text',
						selector: 'se2',
					},
					{
						tagName: 'text',
						selector: 'se3',
					},
					{
						tagName: 'text',
						selector: 's1',
					},
					{
						tagName: 'text',
						selector: 's2',
					},
					{
						tagName: 'text',
						selector: 's3',
					},
					{
						tagName: 'text',
						selector: 'sw1',
					},
					{
						tagName: 'text',
						selector: 'sw2',
					},
					{
						tagName: 'text',
						selector: 'sw3',
					},
					{
						tagName: 'text',
						selector: 'w1',
					},
					{
						tagName: 'text',
						selector: 'w2',
					},
					{
						tagName: 'text',
						selector: 'w3',
					},
					{
						tagName: 'text',
						selector: 'nw1',
					},
					{
						tagName: 'text',
						selector: 'nw2',
					},
					{
						tagName: 'text',
						selector: 'nw3',
					},
				],
			},
		],
	},
);

function createSectorsPieElement(
	selectorCellBBox,
	direction,
	angle,
	is24Pie,
	pieChartKind,
	useCelestialDirections,
	celestialDirectionsDistanceToCenter,
) {
	let height;
	let width;

	switch (pieChartKind) {
		case 'R':
		case 'E':
			height = selectorCellBBox.height;
			width = selectorCellBBox.width;
			break;
		case 'S':
		case 'C':
		default:
			height = Math.max(selectorCellBBox.width, selectorCellBBox.height);
			width = Math.max(selectorCellBBox.width, selectorCellBBox.height);
			break;
	}
	const newAngle = getAngleForDirectionFromCustomAngle(angle, direction);
	const element = new CustomPieChart({
		id: PIE_CHART_ID,
		position: {
			x: selectorCellBBox.center().x - width / 2,
			y: selectorCellBBox.center().y - height / 2,
		},
		preserveAspectRatio: pieChartKind === 'S' || pieChartKind === 'C',
		size: {
			height,
			width,
		},
	});

	const elemAttrs = {};

	PieChartService.get8Or24PiePath(false, pieChartKind, width, height, newAngle).forEach(
		(coordinates, index) => {
			elemAttrs[`l8${index}`] = {
				points: coordinates,
				stroke: 'rgba(0,0,0,1)',
				strokeWidth: 1,
				style: 'stroke:rgb(0,0,0);stroke-width:1;opacity:1;',
			};
		},
	);

	PieChartService.get8Or24PiePath(true, pieChartKind, width, height, newAngle).forEach(
		(coordinates, index) => {
			elemAttrs[`l24${index}`] = {
				points: is24Pie === '1' ? coordinates : '0,0',
				stroke: 'rgba(0,0,0,1)',
				strokeWidth: 0.5,
				style: 'stroke:rgb(0,0,0);stroke-width:0.5;opacity:1;',
			};
		},
	);

	CELESTIAL_DIRECTIONS.forEach((currentDirection, index) => {
		const newIndex = index + 2 + (newAngle / 360) * 24;
		const currentAngle = (newIndex * 2 * Math.PI) / 24;

		const coordinates = getPieChartCoordinates(
			pieChartKind,
			currentAngle,
			width,
			height,
			celestialDirectionsDistanceToCenter,
		);

		const colorIsNotNone =
			useCelestialDirections === '1' && (currentDirection.endsWith('2') || is24Pie === '1');
		elemAttrs[currentDirection] = {
			x: coordinates.x,
			y: coordinates.y,
			textAnchor: 'middle',
			dominantBaseline: 'central',
			fontFamily: 'Arial',
			fill: colorIsNotNone ? 'rgba(0,0,0,1)' : 'none',
			fontSize: is24Pie === '1' ? 12 : 16,
			text:
				currentDirection.endsWith('2') && is24Pie === '0'
					? i18n.t(currentDirection.slice(0, -1).toUpperCase(), { ns: 'general' })
					: i18n.t(currentDirection.slice(0, -1).toUpperCase(), { ns: 'general' }) +
					  currentDirection.slice(-1),
			style: `fill:${colorIsNotNone ? 'rgb(0,0,0)' : 'none'};opacity:${
				colorIsNotNone ? 1 : 0
			};font-family:Arial;font-size:${is24Pie === '1' ? 12 : 16}px;`,
		};
	});

	elemAttrs.custom = {
		distanceToCenter: celestialDirectionsDistanceToCenter * 100,
		is24Pie,
		useCelestialDirections,
		direction,
		angle,
		pieChartKind,
		angleChangedInternally: false,
	};

	element.attr(elemAttrs);

	return element;
}

export default createSectorsPieElement;
