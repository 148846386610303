import { TinyColor } from '@ctrl/tinycolor';
import {
	FLYING_STARS_MAIN_GROUP_ID,
	FLYING_STARS_SECONDARY_GROUP_ID,
} from '../../../../common/data/selectorIDs';
import {
	getAngleForDirectionFromCustomAngle,
	getPieChartCoordinates,
} from '../../../../helpers/pie-chart-helpers';

export function update8PieValue(
	element,
	directionSelector,
	newX,
	newY,
	newTextColor,
	newTextSize,
	newTextFamily,
) {
	// TODO
	const colorIsNotNone = true;
	element.attr(`${directionSelector}/x`, newX);
	element.attr(`${directionSelector}/y`, newY);
	element.attr(`${directionSelector}/fill`, newTextColor);
	element.attr(`${directionSelector}/fontSize`, newTextSize);
	element.attr(`${directionSelector}/fontFamily`, newTextFamily);
	element.attr(
		`${directionSelector}/style`,
		`fill:${colorIsNotNone ? new TinyColor(newTextColor).toRgbString() : 'none'};opacity:${
			colorIsNotNone ? new TinyColor(newTextColor).getAlpha() : 0
		};font-family:${newTextFamily};font-size:${newTextSize}px;`,
	);
}

export function update8PieCircleElement(
	flyingStarsElement,
	pieChartElement,
	index,
	directionSelector,
	newDistanceToCenter,
	newTextSizeSittingFacing,
	newTextSizeBase,
	newTextSizeYearEnergy,
) {
	let coordinates;
	const { width, height } = flyingStarsElement.getBBox();
	let circleSize = flyingStarsElement.attr(`${directionSelector}/r`);
	const angle = getAngleForDirectionFromCustomAngle(
		pieChartElement.attr('custom/angle'),
		pieChartElement.attr('custom/direction'),
	);
	const newIndex = index + 2 + (angle / 360) * 24;
	const currentAngle = (newIndex * 2 * Math.PI) / 24;

	if (
		flyingStarsElement.attr(`${directionSelector}Sitting/fontSize`) !==
			newTextSizeSittingFacing ||
		flyingStarsElement.attr(`${directionSelector}Base/fontSize`) !== newTextSizeBase ||
		flyingStarsElement.attr(`${directionSelector}Year/fontSize`) !== newTextSizeYearEnergy
	) {
		circleSize = newTextSizeSittingFacing * 2 + newTextSizeBase + newTextSizeYearEnergy;
		flyingStarsElement.attr(`${directionSelector}/r`, circleSize);
	}

	if (newDistanceToCenter !== flyingStarsElement.attr('custom/distanceToCenter')) {
		coordinates = getPieChartCoordinates(
			flyingStarsElement.attr('custom/pieChartKind'),
			currentAngle,
			width,
			height,
			newDistanceToCenter / 100,
		);
		flyingStarsElement.attr(`${directionSelector}/cx`, coordinates.x);
		flyingStarsElement.attr(`${directionSelector}/cy`, coordinates.y);
	} else {
		coordinates = {
			x: flyingStarsElement.attr(`${directionSelector}/cx`),
			y: flyingStarsElement.attr(`${directionSelector}/cy`),
		};
	}

	return { circleSize, coordinates };
}

export function update8PieCircleElementColorAndSize(
	element,
	directionSelector,
	newCircleColor,
	newCircleRadius,
) {
	const tinyColorObject = new TinyColor(newCircleColor);
	element.attr(`${directionSelector}/r`, newCircleRadius);
	element.attr(`${directionSelector}/fill`, newCircleColor);
	element.attr(
		`${directionSelector}/style`,
		`fill:${tinyColorObject.toRgbString()};opacity:${tinyColorObject.getAlpha()};`,
	);
}

export function selectionIsFlyingStarsMainChart(cellView) {
	const cell = cellView.model;
	return cell.isElement() && cell.id === FLYING_STARS_MAIN_GROUP_ID;
}
export function selectionIsFlyingStarsAdditionalChart(cellView) {
	const cell = cellView.model;
	return cell.isElement() && cell.id === FLYING_STARS_SECONDARY_GROUP_ID;
}
