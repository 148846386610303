// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-nested-ternary, react/require-default-props, react/jsx-no-useless-fragment, react/forbid-prop-types */
import React from 'react';
import * as ReactColor from 'react-color';
import { Popover } from 'antd';
import PropTypes from 'prop-types';
import tinycolor from 'tinycolor2';

const ColorPicker = ({
	value,
	onChange,
	onChangeComplete,
	onColorResult,
	popup = false,
	blockStyles = {},
	popoverProps = {},
	picker = 'SketchPicker',
	...props
}) => {
	const formatColor = (color) => {
		return color !== undefined && typeof color !== 'string'
			? tinycolor(
					'hsl' in color
						? color.hsl
						: 'hsv' in color
						? color.hsv
						: 'rgb' in color
						? color.rgb
						: 'hex' in color
						? color.hex
						: color,
			  )
			: tinycolor(color);
	};

	const prepareValue = (newValue) => {
		const decimalToHex = (alpha) => (alpha === 0 ? '00' : Math.round(255 * alpha).toString(16));
		const formatted = formatColor(newValue);
		return `${formatted.toHexString()}${decimalToHex(formatted.getAlpha())}`;
	};

	const triggerOnChange = (color) => {
		const colorValue = onColorResult ? onColorResult(color) : color;
		onChange?.(colorValue);
	};

	const triggerOnChangeComplete = (color) => {
		const colorValue = onColorResult ? onColorResult(color) : color;
		onChangeComplete?.(colorValue);
	};

	const getBackgroundBlockColor = (color) => {
		const formatted = formatColor(color);
		const rgba = formatted.toRgb();
		return color
			? `rgba(
        ${rgba?.r ?? 0}, ${rgba?.g ?? 0}, ${rgba?.b ?? 0}, ${rgba?.a ?? 100}
      )`
			: '';
	};

	const blStyles = {
		width: '50px',
		height: '20px',
		display: 'inline-flex',
		border: '2px solid #fff',
		boxShadow: '0 0 0 1px #ccc',
		...blockStyles,
		background: getBackgroundBlockColor(value),
	};

	const fixReactColorStyles = {
		MaterialPicker: {
			default: {
				material: {
					boxSizing: 'content-box',
				},
			},
		},
	};

	if (popup) {
		fixReactColorStyles.SliderPicker = {
			default: {
				hue: {
					minWidth: '300px',
				},
			},
		};
	}

	const Picker = getPicker(picker);

	return (
		<>
			{popup ? (
				<Popover
					trigger='click'
					{...popoverProps}
					content={
						<Picker
							color={prepareValue(value)}
							onChange={triggerOnChange}
							onChangeComplete={triggerOnChangeComplete}
							styles={fixReactColorStyles[picker] || undefined}
							{...props}
						/>
					}>
					<div style={blStyles} />
				</Popover>
			) : (
				<Picker
					color={prepareValue(value)}
					onChange={triggerOnChange}
					onChangeComplete={triggerOnChangeComplete}
					styles={fixReactColorStyles[picker] || undefined}
					{...props}
				/>
			)}
		</>
	);
};

const getPicker = (pickerType) => {
	// eslint-disable-next-line default-case
	switch (pickerType) {
		case 'BlockPicker':
			return ReactColor.BlockPicker;
		case 'ChromePicker':
			return ReactColor.ChromePicker;
		case 'CirclePicker':
			return ReactColor.CirclePicker;
		case 'CompactPicker':
			return ReactColor.CompactPicker;
		case 'GithubPicker':
			return ReactColor.GithubPicker;
		case 'GooglePicker':
			return ReactColor.GooglePicker;
		case 'HuePicker':
			return ReactColor.HuePicker;
		case 'MaterialPicker':
			return ReactColor.MaterialPicker;
		case 'PhotoshopPicker':
			return ReactColor.PhotoshopPicker;
		case 'SliderPicker':
			return ReactColor.SliderPicker;
		case 'SwatchesPicker':
			return ReactColor.SwatchesPicker;
		case 'TwitterPicker':
			return ReactColor.TwitterPicker;
	}
	return ReactColor.SketchPicker;
};

ColorPicker.propTypes = {
	popup: PropTypes.bool,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	onChange: PropTypes.func,
	onChangeComplete: PropTypes.func,
	onColorResult: PropTypes.func,
	blockStyles: PropTypes.object,
	popoverProps: PropTypes.object,
	picker: PropTypes.oneOf([
		'BlockPicker',
		'ChromePicker',
		'CirclePicker',
		'CompactPicker',
		'GithubPicker',
		'GooglePicker',
		'HuePicker',
		'MaterialPicker',
		'PhotoshopPicker',
		'SketchPicker',
		'SliderPicker',
		'SwatchesPicker',
		'TwitterPicker',
	]),
};

export default ColorPicker;
