import React, { useContext, useState } from 'react';
import { useEffectOnce } from 'react-use';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import pages from '../../menu';
import ProductDisplay from './components/ProductDisplay';
import SuccessDisplay from './components/SuccessDisplay';
import ErrorDisplay from './components/ErrorDisyplay';
import { UserContext } from '../../contexts/userContext';
import showNotification from '../../components/extras/showNotification';
import Icon from '../../components/icon/Icon';

const CheckoutPage = () => {
	const { currentUser } = useContext(UserContext);
	const { t } = useTranslation('checkout');
	const [message, setMessage] = useState('');
	const [success, setSuccess] = useState(false);
	const [searchParams] = useSearchParams();

	const navigate = useNavigate();

	const resetStatus = () => {
		navigate(pages.checkout.path);
		setSuccess(false);
		setMessage('');
	};

	useEffectOnce(() => {
		// Check to see if this is a redirect back from Checkout
		if (searchParams.has('success')) {
			setSuccess(true);
			setMessage('');
			localStorage.removeItem('stripeVoucherCode');
			return;
		}

		if (searchParams.has('canceled')) {
			setSuccess(false);
			setMessage('Order canceled');
			return;
		}

		const voucher = localStorage.getItem('stripeVoucherCode');
		if (voucher) {
			showNotification(
				<span className='d-flex align-items-center'>
					<Icon icon='Info' size='lg' className='me-1' />
					<span>{t('Active Voucher')}</span>
				</span>,
				t('You have an active voucher that will be applied at checkout.', {
					voucher,
				}),
				'info',
			);
		}
	});

	const getPage = () => {
		if (!success && message === '') {
			return <ProductDisplay isDisabled={!currentUser?.emailConfirmed} />;
		}
		if (success && message === '') {
			return <SuccessDisplay />;
		}
		return <ErrorDisplay resetStatus={resetStatus} />;
	};

	return <PageWrapper title={t(pages.checkout.text)}>{getPage()}</PageWrapper>;
};

export default CheckoutPage;
