import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import pages from '../../menu';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../components/bootstrap/Dropdown';
import Button from '../../components/bootstrap/Button';
import Icon from '../../components/icon/Icon';
import { UserContext } from '../../contexts/userContext';
import LanguageSelector from '../../components/LanguageSelector';

const User = () => {
	const { currentUser, handleLogoutWithUnsavedCheck } = useContext(UserContext);
	const navigate = useNavigate();
	const { t } = useTranslation('menu');

	return (
		<Dropdown>
			<DropdownToggle hasIcon={false}>
				<div className='user'>
					<Icon forceFamily='material' icon='AccountCircle' size='3x' />
					<div className='user-info'>
						<div className='user-name'>{`${currentUser?.firstName} ${currentUser?.lastName}`}</div>
						<div className='user-sub-title'>{currentUser?.role}</div>
					</div>
				</div>
			</DropdownToggle>
			<DropdownMenu>
				<DropdownItem>
					<Button icon='AccountBox' onClick={() => navigate(`${pages.profile.path}`)}>
						{t('Profile')}
					</Button>
				</DropdownItem>
				<DropdownItem>
					<LanguageSelector isUserMenu />
				</DropdownItem>
				<DropdownItem isDivider />
				<DropdownItem>
					<Button icon='Logout' onClick={handleLogoutWithUnsavedCheck}>
						{t('Logout')}
					</Button>
				</DropdownItem>
			</DropdownMenu>
		</Dropdown>
	);
};

export default User;
