import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../components/bootstrap/Card';
import Spinner from '../../../components/bootstrap/Spinner';
import Button from '../../../components/bootstrap/Button';
import Icon from '../../../components/icon/Icon';
import showNotification from '../../../components/extras/showNotification';
import { TABS } from '../ProfilePage';
import stripeService from '../../../services/stripe.service';

const PaymentDetails = () => {
	const { t: tg } = useTranslation('general');
	const { t } = useTranslation('profile');
	const [isLoading, setIsLoading] = useState(false);

	const handleCostumerPortalClick = () => {
		setIsLoading(true);
		stripeService.openPortal().then(
			() => {
				showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon='Info' size='lg' className='me-1' />
						<span>{t('Portal opening')}</span>
					</span>,
					t('You will be redirected to the user portal.'),
					'success',
				);
				setIsLoading(true);
			},
			() => {
				showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon='Error' size='lg' className='me-1' />
						<span>{tg('Error')}</span>
					</span>,
					t("Portal Session can't be created, please try again."),
					'danger',
				);
				setIsLoading(false);
			},
		);
	};

	return (
		<Card stretch>
			<CardHeader>
				<CardLabel icon='Style' iconColor='info'>
					<CardTitle>{t(TABS.PAYMENT_DETAILS)}</CardTitle>
				</CardLabel>
			</CardHeader>
			<CardBody className='pb-0' isScrollable>
				<div className='row g-4'>
					<div className='col-12 mb-3'>
						<div className='py-3'>
							<p>{t('Stripe1')}</p>
							<p>{t('Stripe2')}</p>
						</div>
					</div>
					<div className='col-12 mb-3'>
						<Button
							isDisable={isLoading}
							type='submit'
							color='info'
							isLight
							className='w-100 py-3 text-uppercase'
							size='lg'
							onClick={handleCostumerPortalClick}>
							{isLoading && <Spinner color='info' inButton isSmall />}
							{isLoading ? t('Loading...') : t('Open Stripe Costumer Portal')}
						</Button>
					</div>
				</div>
			</CardBody>
		</Card>
	);
};

export default PaymentDetails;
