import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
	AimOutlined,
	DownloadOutlined,
	DownOutlined,
	FileOutlined,
	FolderOpenOutlined,
	SaveOutlined,
	StarOutlined,
	ZoomInOutlined,
	ZoomOutOutlined,
} from '@ant-design/icons';
import { Button, Col, Dropdown, Row, Space } from 'antd';
import DataContext from '../context/DataContext';
import showNotification from '../../../../components/extras/showNotification';
import Icon from '../../../../components/icon/Icon';
import { FILE_TYPE_NAME } from '../../../../common/services/ExportImportServiceBase';
import CustomTooltip from '../../../../components/CustomTooltip';

const ACCEPTED_FILE_TYPES = [FILE_TYPE_NAME];

const GeneralMenu = () => {
	const { t: tg } = useTranslation('general');
	const { t } = useTranslation('flyWOW');
	const {
		service,
		handleNewClick,
		fileName,
		setFileName,
		file,
		setFile,
		setRedoUndo,
		setShowHandleNewClickPrompt,
		setSubmitModalStatus,
		flyingStarsElementIsPresent,
		flyingStarsAdditionalElementIsPresent,
	} = useContext(DataContext);

	const showUploadErrorNotification = () => {
		showNotification(
			<span className='d-flex align-items-center'>
				<Icon icon='Error' size='lg' className='me-1' />
				<span>{tg('Error')}</span>
			</span>,
			tg('The file could not be uploaded, because it is corrupt.'),
			'danger',
		);
	};

	const handlePictureChange = async (e) => {
		if (e.target.files.length > 0) {
			const uploadedFile = e.target.files[0];
			const fileNameArray = uploadedFile.name.split('.');
			const fileExtension = `.${fileNameArray[fileNameArray.length - 1]}`;

			if (ACCEPTED_FILE_TYPES.indexOf(fileExtension) !== -1) {
				if (await service.uploadGraph(uploadedFile, showUploadErrorNotification)) {
					setFileName(fileNameArray[0]);
					setFile(fileNameArray[0]);
					setRedoUndo(true);
					setShowHandleNewClickPrompt(false);
				} else {
					setFileName('');
					setFile(null);
					setRedoUndo(false, false);
					setShowHandleNewClickPrompt(false);
				}
			} else {
				setFileName('');
				setFile(null);
				setRedoUndo(false, false);
				setShowHandleNewClickPrompt(false);
				showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon='Error' size='lg' className='me-1' />
						<span>{tg('Picture not uploaded')}</span>
					</span>,
					tg(
						'The file type of the image you wanted to upload is not supported. Accepted types are: ',
						{
							types: ACCEPTED_FILE_TYPES.join(', '),
						},
					),
					'danger',
				);
			}
		}
	};

	const onFileMenuItemClick = ({ key }) => {
		switch (key) {
			case '0':
				if (handleNewClick(service.graphIsNotSaved(), false)) {
					service.clearCurrentPaper();
				}
				break;
			case '1':
				if (handleNewClick(service.graphIsNotSaved(), true)) {
					document.getElementById('uploadFileInput').click();
				}
				break;
			case '2':
				service.saveGraph(fileName);
				break;
			case '3':
				service.openAsSVG(fileName);
				break;
			case '4':
				service.openAsPNG(fileName);
				break;
			case '5':
				service.openAsJPEG(fileName);
				break;
			case '6':
				service.openAsPDF(fileName);
				break;
			default:
				break;
		}
	};

	const fileMenu = [
		{
			label: tg('New'),
			key: '0',
			disabled: file == null || file === '',
			title: tg('Delete the current objects and start from scratch'),
			icon: <FileOutlined />,
		},
		{
			label: tg('Open...'),
			key: '1',
			title: tg('Import a new picture or WOW Gènerator file'),
			icon: <FolderOpenOutlined />,
		},
		{
			label: tg('Save...'),
			key: '2',
			disabled: file == null || file === '',
			title: tg(
				'Save the current state as a WOW Génerator file to continue working with it later',
			),
			icon: <SaveOutlined />,
		},
		{
			type: 'divider',
		},
		{
			label: tg('Download as SVG'),
			key: '3',
			disabled: file == null || file === '',
			title: tg('Save the current state as a SVG to your computer'),
			icon: <DownloadOutlined />,
		},
		{
			label: tg('Download as PNG'),
			key: '4',
			disabled: file == null || file === '',
			title: tg('Save the current state as a PNG to your computer'),
			icon: <DownloadOutlined />,
		},
		{
			label: tg('Download as JPEG'),
			key: '5',
			disabled: file == null || file === '',
			title: tg('Save the current state as a JPEG to your computer'),
			icon: <DownloadOutlined />,
		},
		{
			label: tg('Download as PDF'),
			key: '6',
			disabled: file == null || file === '',
			title: tg('Save the current state as a PDF to your computer'),
			icon: <DownloadOutlined />,
		},
	];

	// FIXME Disabled for the first version
	// const additionalChartMenu = [
	// 	{
	// 		label: tg('New'),
	// 		key: '0',
	// 		disabled: file == null || file === '',
	// 		title: tg('Delete the current objects and start from scratch'),
	// 		icon: <FileOutlined />,
	// 	},
	// 	{
	// 		type: 'divider',
	// 	},
	// 	{
	// 		label: tg('Open...'),
	// 		key: '1',
	// 		title: tg('Import a new picture or WOW Gènerator file'),
	// 		icon: <FolderOpenOutlined />,
	// 	},
	// ];

	return (
		<Row gutter={[16, 16]} style={{ padding: '0.5rem 1.5rem' }}>
			<Col flex={2}>
				<CustomTooltip title={`${tg('Import or export the image here')}`}>
					<Dropdown
						menu={{
							items: fileMenu,
							onClick: onFileMenuItemClick,
						}}
						trigger={['click']}>
						<Button>
							<Space>
								{tg('File')}
								<DownOutlined />
							</Space>
						</Button>
					</Dropdown>
				</CustomTooltip>
				<input
					id='uploadFileInput'
					type='file'
					style={{ display: 'none' }}
					onChange={handlePictureChange}
					accept={ACCEPTED_FILE_TYPES.join(', ')}
				/>
			</Col>
			<Col flex={3}>
				<Button.Group>
					<CustomTooltip title={`${tg('Zoom in')}`}>
						<Button
							color='primary'
							icon={<ZoomInOutlined />}
							disabled={file == null || file === ''}
							onClick={() => service.zoomIn()}
						/>
					</CustomTooltip>
					<CustomTooltip title={`${tg('Zoom out')}`}>
						<Button
							color='primary'
							icon={<ZoomOutOutlined />}
							disabled={file == null || file === ''}
							onClick={() => service.zoomOut()}
						/>
					</CustomTooltip>
					<CustomTooltip title={`${tg('Centres the image on the middle of the view')}`}>
						<Button
							color='primary'
							icon={<AimOutlined />}
							disabled={file == null || file === ''}
							onClick={() => service.centerElements()}
						/>
					</CustomTooltip>
				</Button.Group>
			</Col>
			<Col flex={4}>
				<CustomTooltip title={`${t('Select the chart you want to draw')}`}>
					<Button
						color='primary'
						icon={<StarOutlined />}
						disabled={file == null || file === ''}
						onClick={() => setSubmitModalStatus(true)}>
						{t('Create Chart')}
					</Button>
				</CustomTooltip>
			</Col>
			<Col flex={3}>
				<Button.Group>
					<CustomTooltip title={`${t('Select Main Chart')}`}>
						<Button
							color='primary'
							disabled={!flyingStarsElementIsPresent}
							onClick={() => service.selectMainChartCell()}>
							{t('Select Main Chart')}
						</Button>
					</CustomTooltip>
					<CustomTooltip title={`${t('Select Additional Chart')}`}>
						<Button
							color='primary'
							disabled={!flyingStarsAdditionalElementIsPresent}
							onClick={() => service.selectAdditionalChartCell()}>
							{t('Select Additional Chart')}
						</Button>
						{/*					<Dropdown.Button
							disabled={!flyingStarsAdditionalElementIsPresent}
							menu={{
								items: additionalChartMenu,
								onClick: onFileMenuItemClick,
							}}
							className='wow--group-dropdown-btn'
							trigger={['click']}
							onClick={() => service.selectAdditionalChartCell()}>
							{t('Select Additional Chart')}
						</Dropdown.Button> */}
					</CustomTooltip>
				</Button.Group>
			</Col>
		</Row>
	);
};

export default GeneralMenu;
