import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEffectOnce } from 'react-use';
import ThemeContext from '../../../contexts/themeContext';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import pages from '../../../menu';
import RappidService from './services/RappidService';
import SettingsModal from './components/SettingsModal';
import GeneralMenu from './components/GeneralMenu';
import useCallbackPrompt from '../../../hooks/useCallbackPrompt';
import MainChartMenu from './components/MainChartMenu';
import DialogBox from '../../../components/DialogBox';
import DataContext from './context/DataContext';
import { UserContext } from '../../../contexts/userContext';
import { innerDimensions } from '../../../helpers/helpers';
import KeyboardServiceBase from '../../../common/services/KeyboardServiceBase';
import TabMenu from '../../../components/TabMenu';
import AdditionalChartMenu from './components/AdditionalChartMenu';

const FlyWOWPageImpl = () => {
	const {
		service,
		setElementIsSelected,
		setFlyingStarsElementIsPresent,
		setFlyingStarsAdditionalElementIsPresent,
		setFileName,
		setFile,
		setRedoUndo,
		showHandleNewClickPrompt,
		setShowHandleNewClickPrompt,
		setContainerHeight,
		setContainerWidth,
		containerHeight,
		containerWidth,
		handleNewClick,
		setAllGeneralMenuValues,
		setService,
		setSubmitModalStatus,
		bodyTextCreateOrUploadNewFile,
		setPieChartValues,
		flyingStarsElementIsPresent,
		flyingStarsAdditionalElementIsPresent,
		setAllMainChartMenuValues,
		setAllAdditionalChartMenuValues,
		setCircleRadius,
	} = useContext(DataContext);

	const listTab = {
		GENERAL: { name: 'General', disabled: false },
		MAIN_CHART: { name: 'Main Chart', disabled: !flyingStarsElementIsPresent },
		ADDITIONAL_CHART: {
			name: 'Additional Chart',
			disabled: !flyingStarsAdditionalElementIsPresent,
		},
		// ACTIVATION: {
		// 	name: 'Activation',
		// 	disabled: !flyingStarsElementIsPresent && !flyingStarsAdditionalElementIsPresent,
		// },
	};

	const handleActiveListTab = (tabName) => {
		setActiveListTab(tabName);

		switch (tabName) {
			case listTab.MAIN_CHART:
				// TODO Selektion funktioniert nicht.
				service.selectMainChartCell();
				break;
			case listTab.selectAdditionalChartCell:
				service.selectMainChartCell();
				break;
			case listTab.GENERAL:
			default:
				break;
		}
	};

	const [activeListTab, setActiveListTab] = useState(listTab.GENERAL.name);
	const { asideStatus } = useContext(ThemeContext);

	const paperContainerRef = useRef();
	const paperRef = useRef();
	const { t: tg } = useTranslation('general');
	const { t } = useTranslation('flyWOW');

	const { setGlobalService } = useContext(UserContext);

	const [showUnsavedChangesPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
		service != null && service.graphIsNotSaved(),
	);

	const handleSubmitNewClick = () => {
		service.clearCurrentPaper();
		document.getElementById('uploadFileInput').value = null;
		setElementIsSelected(0);
		setFileName('');
		setFile(null);
		setRedoUndo(false, false);
		setShowHandleNewClickPrompt(false);
	};

	const handleSubmitOpenFileClick = () => {
		document.getElementById('uploadFileInput').click();
	};

	const handleCancelNewClick = () => {
		setShowHandleNewClickPrompt(false);
	};

	const resizeContainer = useCallback(() => {
		const { width, height } = innerDimensions(paperContainerRef.current);
		setContainerHeight(height);
		setContainerWidth(width);
	}, [setContainerHeight, setContainerWidth]);

	useEffectOnce(() => {
		localStorage.removeItem('currentJointGraph');
		const { width, height } = innerDimensions(paperContainerRef.current);
		setContainerHeight(height);
		setContainerWidth(width);

		const tempService = new RappidService(
			paperRef.current,
			new KeyboardServiceBase(),
			handleNewClick,
			setRedoUndo,
			setAllGeneralMenuValues,
			setFlyingStarsElementIsPresent,
			setFlyingStarsAdditionalElementIsPresent,
			setPieChartValues,
			setAllMainChartMenuValues,
			setAllAdditionalChartMenuValues,
			setCircleRadius,
		);
		setService(tempService);
		setGlobalService(tempService);
		tempService.startRappid(setSubmitModalStatus);

		const alertUser = (e) => {
			if (tempService.graphIsNotSaved()) {
				e.preventDefault();
				e.returnValue = '';
			}
		};

		window.addEventListener('resize', () => resizeContainer());
		window.addEventListener('beforeunload', alertUser);

		return () => {
			tempService.stopRappid();
			localStorage.removeItem('currentJointGraph');
			setGlobalService(null);
			window.removeEventListener('resize', () => resizeContainer());
			window.removeEventListener('beforeunload', alertUser);
		};
	});

	useEffect(() => {
		setTimeout(() => resizeContainer(), 500);
	}, [asideStatus, resizeContainer]);

	return (
		<>
			<DialogBox
				showDialog={showUnsavedChangesPrompt}
				setIsOpen={() => {}}
				id='unsavedChangesDialogBox'
				handleSubmit={confirmNavigation}
				handleCancel={cancelNavigation}
				bodyTextPrimary={tg('There are unsaved changes')}
				bodyTextSecondary={tg('Are you sure you want to navigate?')}
			/>
			<PageWrapper title={t(pages.flyWOW.text)}>
				<Page container='fluid custom-rappid'>
					<div className='col'>
						<Card stretch style={{ minHeight: 550 }}>
							<TabMenu
								listTab={listTab}
								t={t}
								activeListTab={activeListTab}
								handleActiveListTab={handleActiveListTab}
							/>

							{activeListTab === listTab.GENERAL.name && <GeneralMenu />}
							{activeListTab === listTab.MAIN_CHART.name && <MainChartMenu />}
							{activeListTab === listTab.ADDITIONAL_CHART.name && (
								<AdditionalChartMenu />
							)}
							{/* {activeListTab === listTab.ACTIVATION.name && <MainChartMenu />} */}

							<CardBody ref={paperContainerRef}>
								<div
									ref={paperRef}
									className='paper-work-area'
									style={{ height: containerHeight, width: containerWidth }}
								/>
							</CardBody>
						</Card>

						<SettingsModal
							setActiveListTab={setActiveListTab}
							listTabName={listTab.GENERAL.name}
						/>

						<DialogBox
							showDialog={showHandleNewClickPrompt}
							setIsOpen={setShowHandleNewClickPrompt}
							id='handleNewClickDialogBox'
							handleSubmit={
								bodyTextCreateOrUploadNewFile
									? handleSubmitOpenFileClick
									: handleSubmitNewClick
							}
							handleCancel={handleCancelNewClick}
							bodyTextPrimary={tg('There are unsaved changes')}
							bodyTextSecondary={
								bodyTextCreateOrUploadNewFile
									? tg('Are you sure you want to open a new file?')
									: tg('Are you sure you want to create a new file?')
							}
						/>
					</div>
				</Page>
			</PageWrapper>
		</>
	);
};

export default FlyWOWPageImpl;
