import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../../../../components/bootstrap/Modal';
import { OffCanvasTitle } from '../../../../components/bootstrap/OffCanvas';
import Checks, { ChecksGroup } from '../../../../components/bootstrap/forms/Checks';
import Button from '../../../../components/bootstrap/Button';
import Input from '../../../../components/bootstrap/forms/Input';
import DataContext from '../context/DataContext';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import Select from '../../../../components/bootstrap/forms/Select';
import Option from '../../../../components/bootstrap/Option';
import {
	get24CelestialDirectionFromAngle,
	getAngleFrom24CelestialDirection,
} from '../../../../helpers/pie-chart-helpers';
import CustomTooltip from '../../../../components/CustomTooltip';

const SettingsModal = ({ setActiveListTab, listTabName }) => {
	const { t: tg } = useTranslation('general');
	const { t } = useTranslation('flyWOW');

	const {
		submitModalStatus,
		setSubmitModalStatus,
		initialAngle,
		initialCelestialDirection,
		service,
	} = useContext(DataContext);

	const options = [
		{
			label: `${t('Period')} 1 (1864 - 1883)`,
			value: '1',
			yearFrom: 1864,
			yearTo: 1883,
		},
		{
			label: `${t('Period')} 2 (1884 - 1903)`,
			value: '2',
			yearFrom: 1884,
			yearTo: 1903,
		},
		{
			label: `${t('Period')} 3 (1904 - 1923)`,
			value: '3',
			yearFrom: 1904,
			yearTo: 1923,
		},
		{
			label: `${t('Period')} 4 (1924 - 1943)`,
			value: '4',
			yearFrom: 1924,
			yearTo: 1943,
		},
		{
			label: `${t('Period')} 5 (1944 - 1963)`,
			value: '5',
			yearFrom: 1944,
			yearTo: 1963,
		},
		{
			label: `${t('Period')} 6 (1964 - 1983)`,
			value: '6',
			yearFrom: 1964,
			yearTo: 1983,
		},
		{
			label: `${t('Period')} 7 (1984 - 2003)`,
			value: '7',
			yearFrom: 1984,
			yearTo: 2003,
		},
		{
			label: `${t('Period')} 8 (2004 - 2023)`,
			value: '8',
			yearFrom: 2004,
			yearTo: 2023,
		},
		{
			label: `${t('Period')} 9 (2024 - 2043)`,
			value: '9',
			yearFrom: 2024,
			yearTo: 2043,
		},
	];

	const celestialDirectionsOptions = [
		{
			label: `${tg('North')} 1`,
			value: 'n1',
		},
		{
			label: `${tg('North')} 2`,
			value: 'n2',
		},
		{
			label: `${tg('North')} 3`,
			value: 'n3',
		},
		{
			label: `${tg('North East')} 1`,
			value: 'ne1',
		},
		{
			label: `${tg('North East')} 2`,
			value: 'ne2',
		},
		{
			label: `${tg('North East')} 3`,
			value: 'ne3',
		},
		{
			label: `${tg('East')} 1`,
			value: 'e1',
		},
		{
			label: `${tg('East')} 2`,
			value: 'e2',
		},
		{
			label: `${tg('East')} 3`,
			value: 'e3',
		},
		{
			label: `${tg('South East')} 1`,
			value: 'se1',
		},
		{
			label: `${tg('South East')} 2`,
			value: 'se2',
		},
		{
			label: `${tg('South East')} 3`,
			value: 'se3',
		},
		{
			label: `${tg('South')} 1`,
			value: 's1',
		},
		{
			label: `${tg('South')} 2`,
			value: 's2',
		},
		{
			label: `${tg('South')} 3`,
			value: 's3',
		},
		{
			label: `${tg('South West')} 1`,
			value: 'sw1',
		},
		{
			label: `${tg('South West')} 2`,
			value: 'sw2',
		},
		{
			label: `${tg('South West')} 3`,
			value: 'sw3',
		},
		{
			label: `${tg('West')} 1`,
			value: 'w1',
		},
		{
			label: `${tg('West')} 2`,
			value: 'w2',
		},
		{
			label: `${tg('West')} 3`,
			value: 'w3',
		},
		{
			label: `${tg('North West')} 1`,
			value: 'nw1',
		},
		{
			label: `${tg('North West')} 2`,
			value: 'nw2',
		},
		{
			label: `${tg('North West')} 3`,
			value: 'nw3',
		},
	];

	const validate = (values) => {
		const errors = {};
		if (values.angle == null || values.angle === '') {
			errors.angle = tg('Required');
		} else if (values.angle < 0 || values.angle > 360) {
			errors.angle = t('The value needs to be between 0 and 360 degrees.');
		}

		if (
			!values.showCombinationFacingSitting &&
			!values.showBasicEnergy &&
			!values.showYearEnergy
		) {
			errors.showCombinationFacingSitting = t('At least one field must be selected.');
			errors.showBasicEnergy = t('At least one field must be selected.');
			errors.showYearEnergy = t('At least one field must be selected.');
		}

		if (values.yearEnergySelect) {
			if (values.yearEnergy == null || values.yearEnergy === '') {
				errors.yearEnergy = tg('Required');
			} else if (values.yearEnergy < 1850 || values.yearEnergy > 2999) {
				errors.yearEnergy = t('The value needs to be between 1850 and 2999.');
			}
		}

		return errors;
	};

	const formik = useFormik({
		initialValues: {
			angle: initialAngle,
			celestialDirection: initialCelestialDirection,
			period: options.filter(
				(option) =>
					option.yearFrom <= new Date().getFullYear() &&
					option.yearTo >= new Date().getFullYear(),
			)[0].value,
			is8SectorsOr9Rectangles: '0',
			pieChartKind: 'C',
			showCombinationFacingSitting: true,
			showBasicEnergy: true,
			showYearEnergy: false,
			yearEnergy: new Date().getFullYear(),
		},
		enableReinitialize: true,
		validate,
		onSubmit: (values) => {
			setSubmitModalStatus(false);
			service.addMainCharts(values);

			setActiveListTab(listTabName);
			formik.resetForm();
		},
	});

	const onAngelChange = (newAngle) => {
		formik.setFieldValue('angle', newAngle.target.value);
		formik.setFieldValue(
			'celestialDirection',
			get24CelestialDirectionFromAngle(newAngle.target.value),
		);
	};

	const onCelestialDirectionChange = (newCelestialDirection) => {
		if (initialCelestialDirection === newCelestialDirection.target.value) {
			formik.setFieldValue('angle', initialAngle);
			formik.setFieldValue('celestialDirection', newCelestialDirection.target.value);
		} else if (newCelestialDirection !== newCelestialDirection.target.value) {
			formik.setFieldValue(
				'angle',
				getAngleFrom24CelestialDirection(
					initialAngle,
					initialCelestialDirection,
					newCelestialDirection.target.value,
				),
			);
			formik.setFieldValue('celestialDirection', newCelestialDirection.target.value);
		}
	};

	return (
		<Modal
			setIsOpen={setSubmitModalStatus}
			isOpen={submitModalStatus}
			titleId='submitSelectionModal'
			isCentered
			isScrollable
			size='lg'>
			<ModalHeader setIsOpen={setSubmitModalStatus}>
				<OffCanvasTitle id='submitSelectionModal'>{t('Create Chart')}</OffCanvasTitle>
			</ModalHeader>
			<ModalBody>
				<div className='row g-4'>
					{/* Angle and celestial direction of facing */}
					<div style={{ display: 'flex', flexWrap: 'wrap' }}>
						<CustomTooltip title={t('Angle of the facing tooltip')}>
							<FormGroup
								className='col-4'
								style={{ marginRight: '5px', width: '33%' }}
								id='angle'
								label={t('Angle of the facing')}>
								<Input
									id='angle-input'
									type='number'
									placeholder={t('Angle of the facing')}
									name='angle'
									min={0}
									max={360}
									step={0.5}
									onChange={(e) => onAngelChange(e)}
									value={formik.values.angle}
									isValid={formik.isValid}
									isTouched={formik.touched.angle}
									invalidFeedback={formik.errors.angle}
								/>
							</FormGroup>
						</CustomTooltip>
						<CustomTooltip title={t('Direction of the facing tooltip')}>
							<FormGroup
								className='col-8'
								style={{ width: '66%' }}
								id='celestial-direction-select'
								label={t('Direction of the facing')}>
								<Select
									id='celestial-direction-select'
									showSearch
									placeholder={t('Choose a direction...')}
									ariaLabel={t('Direction of the facing')}
									optionFilterProp='children'
									filterOption={(input, option) =>
										option.label.toLowerCase().includes(input.toLowerCase())
									}
									name='celestialDirection'
									notFoundContent={t('No matches found.')}
									onChange={(e) => onCelestialDirectionChange(e)}
									value={formik.values.celestialDirection}>
									{celestialDirectionsOptions.map((option) => (
										<Option key={option.value} value={option.value}>
											{option.label}
										</Option>
									))}
								</Select>
							</FormGroup>
						</CustomTooltip>
					</div>

					{/* Period */}
					<CustomTooltip title={t('Period tooltip')}>
						<div className='col-12'>
							<FormGroup id='period-select' label={t('Period')}>
								<Select
									id='period-select'
									showSearch
									placeholder={t('Choose a period...')}
									ariaLabel={t('Period')}
									optionFilterProp='children'
									filterOption={(input, option) =>
										option.label.toLowerCase().includes(input.toLowerCase())
									}
									name='period'
									notFoundContent={t('No matches found.')}
									onChange={formik.handleChange}
									value={formik.values.period}>
									{options.map((option) => (
										<Option key={option.value} value={option.value}>
											{option.label}
										</Option>
									))}
								</Select>
							</FormGroup>
						</div>
					</CustomTooltip>
					{/* Type of presentation */}
					<CustomTooltip title={t('Type of presentation tooltip')}>
						<div className='col-12'>
							<FormGroup
								id='pie-selector-radio-group'
								label={t('Type of presentation')}>
								<ChecksGroup id='pie-selector-radio-group' isInline>
									<Checks
										type='radio'
										label={t('8 Sectors (Pie Chart)')}
										value='0'
										name='is8SectorsOr9Rectangles'
										onChange={formik.handleChange}
										checked={formik.values.is8SectorsOr9Rectangles}
									/>
									<Checks
										type='radio'
										label={t('9 Rectangles (Lo Shu)')}
										value='1'
										name='is8SectorsOr9Rectangles'
										onChange={formik.handleChange}
										checked={formik.values.is8SectorsOr9Rectangles}
									/>
									<Checks
										type='radio'
										label={t('Both')}
										value='2'
										name='is8SectorsOr9Rectangles'
										onChange={formik.handleChange}
										checked={formik.values.is8SectorsOr9Rectangles}
									/>
								</ChecksGroup>
							</FormGroup>
						</div>
					</CustomTooltip>
					{/* Show Section */}
					<CustomTooltip title={t('Show tooltip')}>
						<div className='col-12'>
							<label
								className='border-0 bg-transparent cursor-pointer form-label'
								htmlFor='celestial-directions-radio-group'>
								{t('Show')}
							</label>
							<Checks
								label={t('Combination Facing Sitting')}
								name='showCombinationFacingSitting'
								onChange={formik.handleChange}
								checked={formik.values.showCombinationFacingSitting}
								isValid={formik.isValid}
								isTouched={formik.touched.showCombinationFacingSitting}
								invalidFeedback={formik.errors.showCombinationFacingSitting}
							/>
							<Checks
								label={t('Basic Energy')}
								name='showBasicEnergy'
								onChange={formik.handleChange}
								checked={formik.values.showBasicEnergy}
								isValid={formik.isValid}
								isTouched={formik.touched.showBasicEnergy}
								invalidFeedback={formik.errors.showBasicEnergy}
							/>
							<Checks
								label={t('Year Energy')}
								name='showYearEnergy'
								onChange={formik.handleChange}
								checked={formik.values.showYearEnergy}
								isValid={formik.isValid}
								isTouched={formik.touched.showYearEnergy}
								invalidFeedback={formik.errors.showYearEnergy}
							/>
							<Input
								id='angle-input'
								type='number'
								placeholder={t('Year Energy')}
								name='yearEnergy'
								min={1850}
								max={2999}
								disabled={!formik.values.showYearEnergy}
								onChange={formik.handleChange}
								value={formik.values.yearEnergy}
								isValid={formik.isValid}
								isTouched={formik.touched.yearEnergy}
								invalidFeedback={formik.errors.yearEnergy}
							/>
						</div>
					</CustomTooltip>
				</div>
			</ModalBody>
			<ModalFooter className='bg-transparent'>
				<div className='row g-4'>
					<div className='col-6'>
						<Button color='primary' onClick={formik.handleSubmit}>
							{tg('Confirm')}
						</Button>
					</div>
					<div className='col-6'>
						<Button color='info' isOutline onClick={() => setSubmitModalStatus(false)}>
							{tg('Cancel')}
						</Button>
					</div>
				</div>
			</ModalFooter>
		</Modal>
	);
};

SettingsModal.propTypes = {
	setActiveListTab: PropTypes.func.isRequired,
	listTabName: PropTypes.string.isRequired,
};

export default SettingsModal;
