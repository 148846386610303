import React from 'react';
import pages from '../menu';
import DefaultHeader from '../pages/common/Headers/DefaultHeader';

const headers = [
	{ path: 'auth/login', element: null, exact: true },
	{ path: 'auth/sign-up', element: null, exact: true },
	{ path: 'auth/reset-password', element: null, exact: true },
	{ path: 'auth/reset-password-confirm', element: null, exact: true },
	{ path: 'auth/confirm-email', element: null, exact: true },
	{ path: 'profile/change-email-confirm', element: null, exact: true },
	{ path: 'register', element: null, exact: true },
	{ path: '404', element: null, exact: true },
	{ path: pages.dashboard.path, element: <DefaultHeader />, exact: true },
	{
		path: `*`,
		element: <DefaultHeader />,
	},
];
export default headers;
