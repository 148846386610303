import React from 'react';
import { Tooltip } from 'antd';
import PropTypes from 'prop-types';

const CustomTooltip = ({ title, mouseEnterDelay, mouseLeaveDelay, children, ...props }) => {
	return (
		<Tooltip
			title={title}
			{...props}
			mouseEnterDelay={mouseEnterDelay}
			mouseLeaveDelay={mouseLeaveDelay}>
			{children}
		</Tooltip>
	);
};

CustomTooltip.defaultProps = {
	mouseEnterDelay: 1.5,
	mouseLeaveDelay: 0.1,
};

CustomTooltip.propTypes = {
	title: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
	mouseEnterDelay: PropTypes.number,
	mouseLeaveDelay: PropTypes.number,
};

export default CustomTooltip;
