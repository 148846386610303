import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import Card, { CardBody } from '../../components/bootstrap/Card';
import Page from '../../layout/Page/Page';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Logo from '../../components/Logo';
import Button from '../../components/bootstrap/Button';
import Spinner from '../../components/bootstrap/Spinner';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import showNotification from '../../components/extras/showNotification';
import Icon from '../../components/icon/Icon';
import authenticationService from '../../services/authentication.service';
import pages from '../../menu';
import PasswordInput from '../../components/PasswordInput';

const ResetPasswordConfirmPage = () => {
	const [token, setToken] = useState(null);
	const [eMail, setEMail] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [isAllDisabled, setIsAllDisabled] = useState(false);
	const { t: tg } = useTranslation('general');
	const { t } = useTranslation('login');
	const navigate = useNavigate();

	useEffect(() => {
		const query = new URLSearchParams(window.location.search);

		setToken(query.get('token'));
		setEMail(query.get('email'));

		setIsAllDisabled(token === null || token === '' || eMail === null || eMail === '');
	}, [token, eMail]);

	const validatePasswordReset = (values) => {
		const errors = {};
		if (!values.newPassword) {
			errors.newPassword = tg('Required');
		} else {
			errors.newPassword = '';

			if (values.newPassword.length < 8 || values.newPassword.length > 32) {
				errors.newPassword += t(
					'The password must be at least 8 characters long, but no more than 32. ',
				);
			}
			if (!/\d/g.test(values.newPassword)) {
				errors.newPassword += t('Requires at least one digit. ');
			}
			if (!/[a-z]/g.test(values.newPassword)) {
				errors.newPassword += t('Requires at least one lowercase letter. ');
			}
			if (!/[A-Z]/g.test(values.newPassword)) {
				errors.newPassword += t('Requires at least one uppercase letter. ');
			}
			if (!/[!@#$%^&*)(+=._-]/g.test(values.newPassword)) {
				errors.newPassword += t('Requires at least one special character. ');
			}

			if (errors.newPassword === '') {
				delete errors.newPassword;
			}
		}

		if (!values.newPasswordConfirm) {
			errors.newPasswordConfirm = tg('Required');
		} else if (values.newPassword !== values.newPasswordConfirm) {
			errors.newPasswordConfirm = t('Password need to match');
		}

		return errors;
	};

	const formikPasswordReset = useFormik({
		initialValues: {
			newPassword: '',
			newPasswordConfirm: '',
		},
		validate: validatePasswordReset,
		onSubmit: (values) => {
			setIsLoading(true);
			authenticationService
				.resetPasswordConfirm(token, eMail, values.newPassword, values.newPasswordConfirm)
				.then(
					() => {
						showNotification(
							<span className='d-flex align-items-center'>
								<Icon icon='Info' size='lg' className='me-1' />
								<span>{t('Password changed')}</span>
							</span>,
							t(
								'The password has been successfully changed. You will be redirected to the login page.',
							),
						);
						setIsLoading(false);
						formikPasswordReset.resetForm();
						setTimeout(() => {
							navigate(pages.login.path);
						}, 2000);
					},
					() => {
						setIsLoading(false);
						showNotification(
							<span className='d-flex align-items-center'>
								<Icon icon='Error' size='lg' className='me-1' />
								<span>{tg('Error')}</span>
							</span>,
							t(
								'The password could not been changed. The link has been used already or is not valid anymore, please request a new one.',
							),
							'danger',
						);
					},
				);
		},
	});

	return (
		<PageWrapper title={t(pages.resetPasswordConfirm.text)} className='bg-warning'>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='shadow-3d-container col-xl-4 col-lg-6 col-md-8'>
						<Card className='shadow-3d-dark'>
							<CardBody>
								<div className='text-center my-5'>
									<Link
										to='/'
										className='text-decoration-none  fw-bold display-2 text-dark'>
										<Logo width={220} />
									</Link>
								</div>

								<div className='text-center h1 fw-bold mt-5'>
									{t('Reset Password')}
								</div>
								<div className='text-center h4 text-muted mb-5'>
									{t('Please enter a new password.')}
									<br />
									{t(
										'After setting the new password you will be redirected to the login page.',
									)}
								</div>

								<div className='row g-4'>
									<form
										className='row g-4'
										onSubmit={formikPasswordReset.handleSubmit}>
										<div className='col-12'>
											<FormGroup
												id='newPassword'
												isFloating
												label={t('New Password')}>
												<PasswordInput
													disabled={isAllDisabled}
													autoComplete='password'
													onChange={formikPasswordReset.handleChange}
													onBlur={formikPasswordReset.handleBlur}
													value={formikPasswordReset.values.newPassword}
													isValid={formikPasswordReset.isValid}
													isTouched={
														formikPasswordReset.touched.newPassword
													}
													invalidFeedback={
														formikPasswordReset.errors.newPassword
													}
												/>
											</FormGroup>
										</div>
										<div className='col-12'>
											<FormGroup
												id='newPasswordConfirm'
												isFloating
												label={t('Confirm new password')}>
												<PasswordInput
													disabled={isAllDisabled}
													autoComplete='password'
													onChange={formikPasswordReset.handleChange}
													onBlur={formikPasswordReset.handleBlur}
													value={
														formikPasswordReset.values
															.newPasswordConfirm
													}
													isValid={formikPasswordReset.isValid}
													isTouched={
														formikPasswordReset.touched
															.newPasswordConfirm
													}
													invalidFeedback={
														formikPasswordReset.errors
															.newPasswordConfirm
													}
												/>
											</FormGroup>
										</div>
										<div className='col-12'>
											<Button
												isDisable={isLoading || isAllDisabled}
												type='submit'
												color='warning'
												className='w-100 py-3'>
												{isLoading && (
													<Spinner color='dark' inButton isSmall />
												)}
												{isLoading ? t('Loading...') : t('Change Password')}
											</Button>
										</div>
										<div className='col-12 border-bottom' />
										<div className='col-12' style={{ marginTop: 0 }}>
											<Button
												tag='a'
												to={pages.login.path}
												color='link'
												className='w-100 py-3'>
												{t('Back to Login')}
											</Button>
										</div>
									</form>
								</div>
							</CardBody>
						</Card>
						<div className='text-center'>
							<a
								href='https://wowgenerator.de/privacy-policy/'
								target='_blank'
								rel='noreferrer'
								className='text-decoration-none me-3 link-dark'>
								{t('Privacy policy')}
							</a>
							<a
								href='https://wowgenerator.de/privacy-policy/'
								target='_blank'
								rel='noreferrer'
								className='link-light text-decoration-none link-dark'>
								{t('Terms of use')}
							</a>
						</div>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default ResetPasswordConfirmPage;
