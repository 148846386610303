import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import useBlocker from './useBlocker';

function useCallbackPrompt(when) {
	const navigate = useNavigate();
	const location = useLocation();
	const [showUnsavedChangesPrompt, setShowUnsavedChangesPrompt] = useState(false);
	const [lastLocation, setLastLocation] = useState(null);
	const [confirmedNavigation, setConfirmedNavigation] = useState(false);

	const cancelNavigation = useCallback(() => {
		setShowUnsavedChangesPrompt(false);
	}, []);

	// handle blocking when user click on another route prompt will be shown
	const handleBlockedNavigation = useCallback(
		(nextLocation) => {
			// in if condition we are checking next location and current location are equals or not
			if (!confirmedNavigation && nextLocation.location.pathname !== location.pathname) {
				setShowUnsavedChangesPrompt(true);
				setLastLocation(nextLocation);
				return false;
			}
			return true;
		},
		[confirmedNavigation, location],
	);

	const confirmNavigation = useCallback(() => {
		setShowUnsavedChangesPrompt(false);
		setConfirmedNavigation(true);
	}, []);

	useEffect(() => {
		if (confirmedNavigation && lastLocation) {
			navigate(lastLocation.location.pathname);
		}
	}, [confirmedNavigation, lastLocation, navigate]);

	useBlocker(handleBlockedNavigation, when);

	return [showUnsavedChangesPrompt, confirmNavigation, cancelNavigation];
}

export default useCallbackPrompt;
