import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Input from './bootstrap/forms/Input';
import Button from './bootstrap/Button';

const PasswordInput = ({ isValid, isTouched, invalidFeedback, ...props }) => {
	const { t } = useTranslation('general');
	const [showPassword, setShowPassword] = useState(false);

	const toggleShowPassword = () => {
		setShowPassword(!showPassword);
	};

	return (
		<>
			<Input
				type={showPassword ? 'text' : 'password'}
				isValid={isValid}
				isTouched={isTouched}
				invalidFeedback={invalidFeedback}
				/* eslint-disable-next-line react/jsx-props-no-spreading */
				{...props}
			/>
			<Button
				title={showPassword ? t('Hide Password') : t('Show Password')}
				style={{
					position: 'absolute',
					top: '0.5em',
					right:
						(!isValid && isTouched && invalidFeedback) ||
						(!isValid && isTouched && !invalidFeedback)
							? 'calc(0.375em + 1.5rem)'
							: 'calc(0.375em + 0.25rem)',
				}}
				icon={showPassword ? 'EyeSlash' : 'Eye'}
				onClick={toggleShowPassword}
			/>
		</>
	);
};

PasswordInput.propTypes = {
	isTouched: PropTypes.bool,
	isValid: PropTypes.bool,
	invalidFeedback: PropTypes.string,
};
PasswordInput.defaultProps = {
	isTouched: false,
	isValid: false,
	invalidFeedback: null,
};

export default PasswordInput;
