import { TinyColor } from '@ctrl/tinycolor';
import createSectorsPieElement from '../models/JointShapes';
import {
	updateCelestialDirections,
	updateCelestialDirectionsTextValues,
	updatePiePaths,
} from '../helpers/RappidServiceHelpers';
import {
	getAngleForDirectionFromCustomAngle,
	getPieChartCoordinates,
} from '../../../../helpers/pie-chart-helpers';
import {
	PICTURE_CELL_ID,
	PIE_CHART_ID,
	PIE_CHART_SELECTOR_ID,
} from '../../../../common/data/selectorIDs';
import { CELESTIAL_DIRECTIONS } from '../../../../common/services/RappidServiceBase';

class PieChartService {
	constructor(rappidService) {
		this.paper = rappidService.paper;
		this.graph = rappidService.graph;
		this.setAllPieChartMenuValues = rappidService.setAllPieChartMenuValues;
		this.selection = rappidService.selection;
		this.setAllGeneralMenuValues = rappidService.setAllGeneralMenuValues;
		this.setPieChartElementIsPresent = rappidService.setPieChartElementIsPresent;
	}

	add8or24Pie(angle, direction, is24Pie, useCelestialDirections, pieChartKind, distanceToCenter) {
		const currentPictureCell = this.graph
			.getCells()
			.filter((cell) => cell.id === PICTURE_CELL_ID)[0];
		const currentSelectorCell = this.graph
			.getCells()
			.filter((cell) => cell.id === PIE_CHART_SELECTOR_ID)[0];
		const selectorCellBBox = currentSelectorCell.getBBox();
		const pieChartCell = createSectorsPieElement(
			selectorCellBBox,
			direction,
			angle,
			is24Pie,
			pieChartKind,
			useCelestialDirections,
			distanceToCenter / 100,
		);

		currentPictureCell.embed(pieChartCell);

		this.graph.resetCells([currentSelectorCell, pieChartCell, currentPictureCell]);

		// Make sure new element gets selected immediately. This makes the UX better as
		// the user can immediately manipulate the new element.
		currentPictureCell.toFront({ deep: true });
		pieChartCell.toFront({ deep: true });
		this.selection.collection.reset(pieChartCell);
		this.setPieChartElementIsPresent(true);
	}

	changePieValues(
		newIs24Pie,
		newCustomAngle,
		newDirection,
		newPieChartType,
		newMainColor,
		newMainStrokeWidth,
		newMainDashArray,
		newSecondaryColor,
		newSecondaryStrokeWidth,
		newSecondaryDashArray,
	) {
		const { collection } = this.selection;
		let pieChartKindChanged = false;
		if (collection.length === 1) {
			const element = collection.first();
			const currentIs24Pie = element.attr('custom/is24Pie');
			const currentAngle = element.attr('custom/angle');
			const currentDirection = element.attr('custom/direction');
			const currentDistanceToCenter = element.attr('custom/distanceToCenter');
			const currentTextColor = element.attr('n2/fill');
			const currentPieChartKind = element.attr('custom/pieChartKind');
			let currentSize = element.size();
			const newAngle = getAngleForDirectionFromCustomAngle(newCustomAngle, newDirection);

			if (
				currentIs24Pie !== newIs24Pie ||
				currentAngle !== newCustomAngle ||
				currentDirection !== newDirection ||
				currentPieChartKind !== newPieChartType
			) {
				pieChartKindChanged = currentPieChartKind !== newPieChartType;
				if (pieChartKindChanged) {
					const currentSelectorCell = this.graph
						.getCells()
						.filter((cell) => cell.id === PIE_CHART_SELECTOR_ID)[0];
					const selectorCellBBox = currentSelectorCell.getBBox();
					currentSize = selectorCellBBox;
					if (newPieChartType === 'C' || newPieChartType === 'S') {
						currentSize = {
							height: Math.max(selectorCellBBox.width, selectorCellBBox.height),
							width: Math.max(selectorCellBBox.width, selectorCellBBox.height),
						};
					}

					element.resize(currentSize.width, currentSize.height);
					element.position(
						selectorCellBBox.center().x - currentSize.width / 2,
						selectorCellBBox.center().y - currentSize.height / 2,
					);
					element.set(
						'preserveAspectRatio',
						newPieChartType === 'S' || newPieChartType === 'C',
					);
				}

				element.attr('custom/is24Pie', newIs24Pie);
				element.attr('custom/angle', newCustomAngle);
				element.attr('custom/direction', newDirection);
				element.attr('custom/pieChartKind', newPieChartType);
				element.attr('custom/angleChangedInternally', currentIs24Pie === newIs24Pie);

				updatePiePaths(element, newIs24Pie, newPieChartType, currentSize, newAngle);

				CELESTIAL_DIRECTIONS.forEach((directionSelector, index) => {
					updateCelestialDirections(
						element,
						directionSelector,
						newAngle,
						index,
						currentSize,
						currentDistanceToCenter / 100,
						newIs24Pie,
						currentTextColor,
					);
				});
			} else {
				const eightColor = new TinyColor(newMainColor);
				const twentyFourColor = new TinyColor(newSecondaryColor);
				// eslint-disable-next-line no-plusplus
				for (let i = 0; i < 8; i++) {
					if (i < 4) {
						element.attr(`l8${i}/stroke`, newMainColor);
						element.attr(`l8${i}/strokeWidth`, newMainStrokeWidth);
						element.attr(
							`l8${i}/strokeDasharray`,
							newMainDashArray === 0 ? null : newMainDashArray,
						);

						const dashArrayString =
							newMainDashArray === 0 ? '' : `stroke-dasharray:${newMainDashArray};`;
						element.attr(
							`l8${i}/style`,
							`stroke:${eightColor.toRgbString()};opacity:${eightColor.getAlpha()};stroke-width:${newMainStrokeWidth};${dashArrayString}`,
						);
					}
					element.attr(`l24${i}/stroke`, newSecondaryColor);
					element.attr(`l24${i}/strokeWidth`, newSecondaryStrokeWidth);
					element.attr(
						`l24${i}/strokeDasharray`,
						newSecondaryDashArray === 0 ? null : newSecondaryDashArray,
					);

					const dashArrayStringSecondary =
						newSecondaryDashArray === 0
							? ''
							: `stroke-dasharray:${newSecondaryDashArray};`;
					element.attr(
						`l24${i}/style`,
						`stroke:${twentyFourColor.toRgbString()};opacity:${twentyFourColor.getAlpha()};stroke-width:${newSecondaryStrokeWidth};${dashArrayStringSecondary}`,
					);
				}
			}
		}

		return pieChartKindChanged;
	}

	changeShowCelestialDirectionValues(
		newShowCelestialDirections,
		newTextColor,
		newTextSize,
		newDistanceToCenter,
		newTextFamily,
	) {
		const { collection } = this.selection;
		if (collection.length === 1) {
			const element = collection.first();
			const currentIs24Pie = element.attr('custom/is24Pie');
			const currentSize = element.size();
			const currentDistanceToCenter = element.attr('custom/distanceToCenter');
			const currentAngle = getAngleForDirectionFromCustomAngle(
				element.attr('custom/angle'),
				element.attr('custom/direction'),
			);
			const textColor =
				newTextColor == null ||
				(newTextColor === 'none' && newShowCelestialDirections === '1')
					? 'rgba(0,0,0,1)'
					: newTextColor;

			element.attr('custom/distanceToCenter', newDistanceToCenter);
			element.attr('custom/useCelestialDirections', newShowCelestialDirections);

			CELESTIAL_DIRECTIONS.forEach((directionSelector, index) => {
				if (currentDistanceToCenter !== newDistanceToCenter) {
					updateCelestialDirections(
						element,
						directionSelector,
						currentAngle,
						index,
						currentSize,
						newDistanceToCenter / 100,
						currentIs24Pie,
						textColor,
					);
				} else {
					updateCelestialDirectionsTextValues(
						element,
						directionSelector,
						textColor,
						newTextSize,
						newTextFamily[0].value,
						newShowCelestialDirections,
						currentIs24Pie,
					);
				}
			});
		}
	}

	handlePieChartSizeChange(element, newSize) {
		const currentIs24Pie = element.attr('custom/is24Pie');
		const currentDistanceToCenter = element.attr('custom/distanceToCenter');
		const currentPieChartKind = element.attr('custom/pieChartKind');
		const currentTextColor = element.attr('n2/fill');
		const currentAngle = getAngleForDirectionFromCustomAngle(
			element.attr('custom/angle'),
			element.attr('custom/direction'),
		);
		this.setAllGeneralMenuValues(newSize.height, newSize.width);

		updatePiePaths(element, currentIs24Pie, currentPieChartKind, newSize, currentAngle);

		CELESTIAL_DIRECTIONS.forEach((directionSelector, index) => {
			updateCelestialDirections(
				element,
				directionSelector,
				currentAngle,
				index,
				newSize,
				currentDistanceToCenter / 100,
				currentIs24Pie,
				currentTextColor,
			);
		});
	}

	static handlePieChartAngleChange(element, newAngle, setAllPieChartMenuValues) {
		const currentIs24Pie = element.attr('custom/is24Pie');
		const currentPieChartKind = element.attr('custom/pieChartKind');
		const currentDirection = element.attr('custom/direction');
		const currentSize = element.size();
		const currentDistanceToCenter = element.attr('custom/distanceToCenter');
		const currentTextColor = element.attr('n2/fill');
		let customAngle = newAngle;

		if (!element.attr('custom/angleChangedInternally')) {
			if (customAngle < 0) {
				customAngle = 360 + customAngle;
			} else if (customAngle > 360) {
				customAngle -= 360;
			}

			element.attr('custom/angle', customAngle);

			setAllPieChartMenuValues(
				currentIs24Pie,
				customAngle,
				currentDirection,
				element.attr('custom/pieChartKind'),
				element.attr('l80/stroke'),
				element.attr('l80/strokeWidth'),
				element.attr('l80/strokeDasharray'),
				element.attr('l240/stroke'),
				element.attr('l240/strokeWidth'),
				element.attr('l240/strokeDasharray'),
			);
		}

		customAngle = getAngleForDirectionFromCustomAngle(customAngle, currentDirection);

		updatePiePaths(element, currentIs24Pie, currentPieChartKind, currentSize, customAngle);

		CELESTIAL_DIRECTIONS.forEach((directionSelector, index) => {
			updateCelestialDirections(
				element,
				directionSelector,
				customAngle,
				index,
				currentSize,
				currentDistanceToCenter / 100,
				currentIs24Pie,
				currentTextColor,
			);
		});

		element.attr('custom/angleChangedInternally', false);
	}

	centerPieChartOnSelectArea() {
		const currentSelectorCell = this.graph
			.getCells()
			.filter((cell) => cell.id === PIE_CHART_SELECTOR_ID)[0];
		const currentPieChartCell = this.graph
			.getCells()
			.filter((cell) => cell.id === PIE_CHART_ID)[0];

		if (currentSelectorCell != null && currentPieChartCell != null) {
			const selectorCellBBox = currentSelectorCell.getBBox();
			const pieChartSize = currentPieChartCell.size();

			currentPieChartCell.position(
				selectorCellBBox.center().x - pieChartSize.width / 2,
				selectorCellBBox.center().y - pieChartSize.height / 2,
			);
		}
	}

	static get8Or24PiePath(is24Pie, pieChartKind, width, height, customAngle) {
		const resultPaths = [];
		const maxIndex = is24Pie ? 12 : 4;
		const circleAngleStep = (2 * Math.PI) / (maxIndex * 2);
		const additionalIndexValue = (customAngle / 360) * (maxIndex * 2);

		/* eslint-disable-next-line no-plusplus */
		for (let index = 0; index < maxIndex; index++) {
			if (!is24Pie || (index + 2) % 3 !== 0) {
				const fromIndex = index + 0.5 + additionalIndexValue;
				const toIndex = fromIndex + maxIndex;
				const fromAngle = circleAngleStep * fromIndex;
				const toAngle = circleAngleStep * toIndex;

				const coordinatesFrom = getPieChartCoordinates(
					pieChartKind,
					fromAngle,
					width,
					height,
				);
				const coordinatesTo = getPieChartCoordinates(pieChartKind, toAngle, width, height);

				resultPaths.push(
					`${coordinatesFrom.x},${coordinatesFrom.y} ${coordinatesTo.x},${coordinatesTo.y}`,
				);
			}
		}

		return resultPaths;
	}
}

export default PieChartService;
