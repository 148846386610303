import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useEffectOnce } from 'react-use';
import { useNavigate } from 'react-router-dom';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { useTranslation } from 'react-i18next';
import authenticationService from '../services/authentication.service';
import showNotification from '../components/extras/showNotification';
import Icon from '../components/icon/Icon';
import pages from '../menu';
import DialogBox from '../components/DialogBox';

export const UserContext = createContext(null);

export const UserContextProvider = ({ children }) => {
	const { t } = useTranslation('general');
	const navigate = useNavigate();
	const [currentUser, setCurrentUser] = useState(null);
	const [globalService, setGlobalService] = useState(null);
	const [showUnsavedChangesPrompt, setShowUnsavedChangesPrompt] = useState(false);
	let connection = null;

	useEffectOnce(() => {
		authenticationService.currentUser.subscribe((contextUser) => {
			if (authenticationService.currentUserValue != null) {
				authenticationService.testJwt().then((result) => {
					if (!result) {
						logoutAndRedirect();
					} else {
						setCurrentUser(contextUser);

						if (connection == null) {
							connection = new HubConnectionBuilder()
								.withUrl(
									`${process.env.REACT_APP_BACKEND_BASE_URL}/ws/user?access_token=${authenticationService.currentUserValue.token}`,
								)
								.build();

							connection.on('connected', (data) => {
								authenticationService.setNext(data);
							});

							connection.on('user_changed', (data) => {
								const newUser = contextUser;
								newUser.role = data.role;
								newUser.token = data.token;
								authenticationService.setNext(newUser);
							});

							connection.start().then(
								() => {},
								() => {
									logoutAndRedirect();
								},
							);
						}
					}
				});
			} else {
				setCurrentUser({
					id: '',
					email: '',
					emailConfirmed: false,
					firstName: '',
					lastName: '',
					token: '',
					role: 'None',
				});
			}
		});
	});

	const logoutAndRedirect = () => {
		connection = null;
		authenticationService.logout();

		navigate(pages.login.path);
		showNotification(
			<span className='d-flex align-items-center'>
				<Icon icon='Error' size='lg' className='me-1' />
				<span>{t('Not Authorized')}</span>
			</span>,
			t('You are not authorized to access this resource!'),
			'danger',
		);
	};

	const confirmLogout = () => {
		connection = null;
		localStorage.removeItem('currentJointGraph');
		globalService?.clearCurrentPaper();
		setShowUnsavedChangesPrompt(false);
		setTimeout(() => {
			authenticationService.logout();
			navigate(`${pages.login.path}`);
		}, 200);
	};

	const cancelLogout = () => {
		setShowUnsavedChangesPrompt(false);
	};

	const handleLogoutWithUnsavedCheck = () => {
		if (globalService != null && globalService.graphIsNotSaved()) {
			setShowUnsavedChangesPrompt(true);
		} else {
			confirmLogout();
		}
	};

	// eslint-disable-next-line react/jsx-no-constructed-context-values
	const values = {
		currentUser,
		handleLogoutWithUnsavedCheck,
		setGlobalService,
	};

	return (
		<UserContext.Provider value={values}>
			<>
				<DialogBox
					showDialog={showUnsavedChangesPrompt}
					setIsOpen={() => {}}
					id='unsavedChangesDialogBox'
					handleSubmit={confirmLogout}
					handleCancel={cancelLogout}
					bodyTextPrimary={t('There are unsaved changes')}
					bodyTextSecondary={t('Are you sure you want to logout?')}
				/>
				{children}
			</>
		</UserContext.Provider>
	);
};
UserContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default UserContextProvider;
