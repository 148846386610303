import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Input, InputNumber, Row, Segmented } from 'antd';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import FontSelectorAutocomplete from '../../../../components/extras/fontDropdown';
import DataContext from '../context/DataContext';
import ColorPicker from '../../../../components/ColorPicker';
import CustomTooltip from '../../../../components/CustomTooltip';

const CelestialDirectionsMenu = () => {
	const { t } = useTranslation('startWOW');
	const {
		elementIsSelected,
		handleShowCelestialDirectionsChange,
		showCelestialDirections,
		textColor,
		handleTextColorChange,
		textSize,
		handleTextSizeChange,
		distanceToCenter,
		handleDistanceToCenterChange,
		textFamily,
		handleTextFamilyChange,
	} = useContext(DataContext);

	const celestialDirectionsOptions = [
		{
			label: t('Show'),
			value: '1',
			icon: <EyeOutlined />,
		},
		{
			label: t('Hide'),
			value: '0',
			icon: <EyeInvisibleOutlined />,
		},
	];

	return (
		<Row gutter={[16, 16]} style={{ padding: '0.5rem 1.5rem' }}>
			<Col flex={3}>
				<Row gutter={[16, 16]}>
					<Col flex={1}>
						<CustomTooltip title={`${t('Show Celestial Directions')}`}>
							<Segmented
								options={celestialDirectionsOptions}
								disabled={elementIsSelected !== 2}
								onChange={handleShowCelestialDirectionsChange}
								value={showCelestialDirections}
							/>
						</CustomTooltip>
					</Col>
					<Col flex={4}>
						<Input.Group compact>
							<CustomTooltip title={`${t('Text Color')}`}>
								<ColorPicker
									popup
									blockStyles={{
										width: '60px',
										height: '30px',
										marginTop: '1px',
										marginRight: '0',
									}}
									disabled={
										elementIsSelected !== 2 || showCelestialDirections === '0'
									}
									value={textColor}
									onChange={handleTextColorChange}
								/>
							</CustomTooltip>
							<CustomTooltip title={`${t('Text Size')}`}>
								<InputNumber
									disabled={
										elementIsSelected !== 2 || showCelestialDirections === '0'
									}
									onChange={handleTextSizeChange}
									value={textSize}
									formatter={(value) => `${value} px`}
									parser={(value) => value.replace('px', '')}
									min={1}
									max={100}
									step={1}
								/>
							</CustomTooltip>
							<CustomTooltip title={`${t('Distance to Center')}`}>
								<InputNumber
									disabled={
										elementIsSelected !== 2 || showCelestialDirections === '0'
									}
									onChange={handleDistanceToCenterChange}
									value={distanceToCenter}
									formatter={(value) => `${value}%`}
									parser={(value) => value.replace('%', '')}
									min={10}
									max={200}
									step={1}
								/>
							</CustomTooltip>
						</Input.Group>
					</Col>
				</Row>
			</Col>
			<Col flex={2}>
				<FontSelectorAutocomplete
					bodyStyle={{ width: '65%' }}
					tooltipText={`${t('Text Family')}`}
					fontName={textFamily}
					setFontName={handleTextFamilyChange}
					isDisabled={elementIsSelected !== 2 || showCelestialDirections === '0'}
				/>
			</Col>
		</Row>
	);
};

export default CelestialDirectionsMenu;
