import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEffectOnce } from 'react-use';
import { useNavigate } from 'react-router-dom';
import ThemeContext from '../../../contexts/themeContext';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import pages from '../../../menu';
import KeyboardServiceBase from '../../../common/services/KeyboardServiceBase';
import RappidService from './services/RappidService';
import SettingsModal from './components/SettingsModal';
import GeneralMenu from './components/GeneralMenu';
import useCallbackPrompt from '../../../hooks/useCallbackPrompt';
import DialogBox from '../../../components/DialogBox';
import DataContext from './context/DataContext';
import { UserContext } from '../../../contexts/userContext';
import { innerDimensions } from '../../../helpers/helpers';
import TabMenu from '../../../components/TabMenu';
import PieChartMenu from './components/PieChartMenu';
import CelestialDirectionsMenu from './components/CelestialDirectionsMenu';

const StartWOWFreePageImpl = () => {
	const { asideStatus } = useContext(ThemeContext);

	const paperContainerRef = useRef();
	const paperRef = useRef();
	const { t: tg } = useTranslation('general');
	const { t } = useTranslation('startWOW');
	const navigate = useNavigate();

	const {
		service,
		setElementIsSelected,
		setPieChartElementIsPresent,
		setFileName,
		setFile,
		setRedoUndo,
		showHandleNewClickPrompt,
		setShowHandleNewClickPrompt,
		setContainerHeight,
		setContainerWidth,
		containerHeight,
		containerWidth,
		pieChartElementIsPresent,
		handleNewClick,
		setAllGeneralMenuValues,
		setAllPieChartMenuValues,
		setAllCelestialDirectionsMenuValues,
		setService,
		setSubmitModalStatus,
		bodyTextCreateOrUploadNewFile,
	} = useContext(DataContext);

	const listTab = {
		GENERAL: { name: 'General', disabled: false },
		PIE_CHART: { name: 'Pie Chart', disabled: !pieChartElementIsPresent },
		CELESTIAL_DIRECTIONS: { name: 'Celestial Directions', disabled: !pieChartElementIsPresent },
		UPGRADE_PLAN: { name: 'Upgrade Plan', disabled: false },
	};

	const [activeListTab, setActiveListTab] = useState(listTab.GENERAL.name);

	const handleActiveListTab = (tabName) => {
		if (tabName === listTab.UPGRADE_PLAN.name) {
			navigate('/checkout');
			return;
		}
		setActiveListTab(tabName);
		if (tabName !== listTab.GENERAL) {
			service.selectPieChartCell();
		}
	};

	const { setGlobalService } = useContext(UserContext);

	const [showUnsavedChangesPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
		service != null && service.graphIsNotSaved(),
	);

	const handleSubmitNewClick = () => {
		service.clearCurrentPaper();
		document.getElementById('uploadFileInput').value = null;
		setElementIsSelected(0);
		setFileName('');
		setFile(null);
		setRedoUndo(false, false);
		setShowHandleNewClickPrompt(false);
	};

	const handleSubmitOpenFileClick = () => {
		document.getElementById('uploadFileInput').click();
	};

	const handleCancelNewClick = () => {
		setShowHandleNewClickPrompt(false);
	};

	const resizeContainer = useCallback(() => {
		const { width, height } = innerDimensions(paperContainerRef.current);
		setContainerHeight(height);
		setContainerWidth(width);
	}, [setContainerHeight, setContainerWidth]);

	useEffectOnce(() => {
		localStorage.removeItem('currentJointGraph');
		const { width, height } = innerDimensions(paperContainerRef.current);
		setContainerHeight(height);
		setContainerWidth(width);

		const tempService = new RappidService(
			paperRef.current,
			new KeyboardServiceBase(),
			setElementIsSelected,
			setPieChartElementIsPresent,
			handleNewClick,
			setAllGeneralMenuValues,
			setAllPieChartMenuValues,
			setAllCelestialDirectionsMenuValues,
			setRedoUndo,
		);
		setService(tempService);
		setGlobalService(tempService);
		tempService.startRappid(setSubmitModalStatus);

		const alertUser = (e) => {
			if (tempService.graphIsNotSaved()) {
				e.preventDefault();
				e.returnValue = '';
			}
		};

		window.addEventListener('resize', () => resizeContainer());
		window.addEventListener('beforeunload', alertUser);

		return () => {
			tempService.stopRappid();
			localStorage.removeItem('currentJointGraph');
			setGlobalService(null);
			window.removeEventListener('resize', () => resizeContainer());
			window.removeEventListener('beforeunload', alertUser);
		};
	});

	useEffect(() => {
		setTimeout(() => resizeContainer(), 500);
	}, [asideStatus, resizeContainer]);

	return (
		<>
			<DialogBox
				showDialog={showUnsavedChangesPrompt}
				setIsOpen={() => {}}
				id='unsavedChangesDialogBox'
				handleSubmit={confirmNavigation}
				handleCancel={cancelNavigation}
				bodyTextPrimary={tg('There are unsaved changes')}
				bodyTextSecondary={tg('Are you sure you want to navigate?')}
			/>
			<PageWrapper title={t(pages.startWOWFree.text)}>
				<Page container='fluid custom-rappid'>
					<div className='col'>
						<Card stretch style={{ minHeight: 550 }}>
							<TabMenu
								listTab={listTab}
								t={t}
								activeListTab={activeListTab}
								handleActiveListTab={handleActiveListTab}
							/>

							{activeListTab === listTab.GENERAL.name && <GeneralMenu />}
							{activeListTab === listTab.PIE_CHART.name && <PieChartMenu />}
							{activeListTab === listTab.CELESTIAL_DIRECTIONS.name && (
								<CelestialDirectionsMenu />
							)}

							<CardBody ref={paperContainerRef}>
								<div
									ref={paperRef}
									className='paper-work-area'
									style={{ height: containerHeight, width: containerWidth }}
								/>
							</CardBody>
						</Card>

						<SettingsModal
							setActiveListTab={setActiveListTab}
							listTabName={listTab.PIE_CHART.name}
						/>

						<DialogBox
							showDialog={showHandleNewClickPrompt}
							setIsOpen={setShowHandleNewClickPrompt}
							id='handleNewClickDialogBox'
							handleSubmit={
								bodyTextCreateOrUploadNewFile
									? handleSubmitOpenFileClick
									: handleSubmitNewClick
							}
							handleCancel={handleCancelNewClick}
							bodyTextPrimary={tg('There are unsaved changes')}
							bodyTextSecondary={
								bodyTextCreateOrUploadNewFile
									? tg('Are you sure you want to open a new file?')
									: tg('Are you sure you want to create a new file?')
							}
						/>
					</div>
				</Page>
			</PageWrapper>
		</>
	);
};

export default StartWOWFreePageImpl;
