import React from 'react';
import StartWOWPageImpl from './StartWOWPageImpl';
import { DataContextProvider } from './context/DataContext';

const StartWOWPage = () => {
	return (
		<DataContextProvider>
			<StartWOWPageImpl />
		</DataContextProvider>
	);
};

export default StartWOWPage;
