import React from 'react';
import pages from '../menu';
import LoginPage from '../pages/auth/LoginPage';
import PrivateRoute from '../components/PrivateRoute';
import Role from '../common/data/role';
import DashboardPage from '../pages/dashboard/DashboardPage';
import CheckoutPage from '../pages/checkout/CheckoutPage';
import ProfilePage from '../pages/profile/ProfilePage';
import ResetPasswordPage from '../pages/resetPassword/ResetPasswordPage';
import ConfirmEmailPage from '../pages/auth/ConfirmEmailPage';
import ResetPasswordConfirmPage from '../pages/resetPassword/ResetPasswordConfirmPage';
import ChangeEmailConfirmPage from '../pages/profile/ChangeEmailConfirmPage';
import StartWOWFreePage from '../pages/start/startWowFree/StartWOWFreePage';
import StartWOWPage from '../pages/start/startWow/StartWOWPage';
import FlyWOWPage from '../pages/fly/flyWow/FlyWOWPage';

const routes = [
	/**
	 * Auth Page
	 */
	{
		path: pages.login.path,
		element: <LoginPage />,
		exact: true,
	},
	{
		path: pages.signUp.path,
		element: <LoginPage isSignUp />,
		exact: true,
	},
	{
		path: pages.resetPassword.path,
		element: <ResetPasswordPage />,
		exact: true,
	},
	{
		path: pages.resetPasswordConfirm.path,
		element: <ResetPasswordConfirmPage />,
		exact: true,
	},
	{
		path: pages.confirmEmail.path,
		element: <ConfirmEmailPage />,
		exact: true,
	},

	/**
	 * Landing
	 */
	{
		path: pages.dashboard.path,
		element: <PrivateRoute component={DashboardPage} />,
		exact: true,
	},

	/**
	 * Profile
	 */
	{
		path: pages.profile.path,
		element: <PrivateRoute component={ProfilePage} />,
		exact: true,
	},
	{
		path: pages.changeEmailConfirm.path,
		element: <PrivateRoute component={ChangeEmailConfirmPage} />,
		exact: true,
	},

	/**
	 * Mini pages
	 */
	{
		path: pages.startWOW.path,
		element: (
			<PrivateRoute
				roles={[Role.Mini, Role.Basic, Role.Advanced, Role.Premium]}
				component={StartWOWPage}
			/>
		),
		exact: true,
	},

	/**
	 * Basic pages
	 */
	{
		path: pages.flyWOW.path,
		element: (
			<PrivateRoute
				roles={[Role.Basic, Role.Advanced, Role.Premium]}
				component={FlyWOWPage}
			/>
		),
		exact: true,
	},
	{
		path: pages.settings.path,
		element: (
			<PrivateRoute
				roles={[Role.Basic, Role.Advanced, Role.Premium]}
				component={FlyWOWPage}
			/>
		),
		exact: true,
	},

	/**
	 * Advanced pages
	 */
	{
		path: pages.placeWOW.path,
		element: <PrivateRoute roles={[Role.Advanced, Role.Premium]} component={StartWOWPage} />,
		exact: true,
	},

	/**
	 * Premium pages
	 */
	{
		path: pages.viewWOW.path,
		element: <PrivateRoute roles={[Role.Premium]} component={StartWOWPage} />,
		exact: true,
	},

	/**
	 * Checkout & Free pages
	 */
	{
		path: pages.startWOWFree.path,
		element: <PrivateRoute roles={[Role.None]} component={StartWOWFreePage} />,
		exact: true,
	},
	{
		path: pages.checkout.path,
		element: <PrivateRoute roles={[Role.None]} component={CheckoutPage} />,
		exact: true,
	},
];

const contents = [...routes];

export default contents;
