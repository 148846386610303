import { ui } from '@clientio/rappid';
import i18n from '../../../../i18n';

const Position = ui.Halo.HandlePosition;

class HaloService {
	create(cellView) {
		this.halo = new ui.Halo({
			cellView,
			handles: HaloService.getHaloConfig(),
			useModelGeometry: true,
		});
		this.options = this.halo.options;
		this.halo.render();
	}

	static getHaloConfig() {
		return [
			{
				name: 'remove',
				position: Position.N,
				events: { pointerdown: 'removeElement' },
				attrs: {
					'.handle': {
						'data-tooltip-class-name': 'small',
						'data-tooltip': i18n.t('Click to remove the object', {
							ns: 'startWOW',
						}),
						'data-tooltip-position': 'right',
						'data-tooltip-padding': 15,
					},
				},
			},
		];
	}
}

export default HaloService;
