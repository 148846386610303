import { dia, shapes, ui, elementTools, g } from '@clientio/rappid';
import HaloService from './HaloService';
import ExportImportService from './ExportImportService';
import { selectionIsPieChart } from '../helpers/RappidServiceHelpers';
import PieChartService from './PieChartService';
import {
	PICTURE_CELL_ID,
	PIE_CHART_ID,
	PIE_CHART_SELECTOR_ID,
} from '../../../../common/data/selectorIDs';
import RappidServiceBase, { PAPER_SIZE } from '../../../../common/services/RappidServiceBase';

class RappidService extends RappidServiceBase {
	constructor(
		el,
		keyboardService,
		setElementIsSelected,
		setPieChartElementIsPresent,
		handleNewClick,
		setAllGeneralMenuValues,
		setAllPieChartMenuValues,
		setAllCelestialDirectionsMenuValues,
		setRedoUndo,
	) {
		super(el, keyboardService, handleNewClick, setRedoUndo);
		this.setElementIsSelected = setElementIsSelected;
		this.setPieChartElementIsPresent = setPieChartElementIsPresent;
		this.setAllGeneralMenuValues = setAllGeneralMenuValues;
		this.setAllPieChartMenuValues = setAllPieChartMenuValues;
		this.setAllCelestialDirectionsMenuValues = setAllCelestialDirectionsMenuValues;
	}

	startRappid(setSubmitModalStatus) {
		this.initializePaper();
		this.initializeSelection();
		this.initializeKeyboardShortcuts();
		this.initializeTooltips();

		this.haloService = new HaloService(setSubmitModalStatus, this.setAllPieChartMenuValues);
		this.exportImportService = new ExportImportService(this);
		this.pieChartService = new PieChartService(this);
	}

	initializeSelection() {
		this.clipboard = new ui.Clipboard();
		this.selection = new ui.Selection({ paper: this.paper, useModelGeometry: true });
		this.selection.collection.on('reset add remove', this.onSelectionChange.bind(this));

		const { keyboard } = this.keyboardService;

		// Initiate selecting when the user grabs the blank area of the paper while the Shift key is pressed.
		// Otherwise, initiate paper pan.
		this.paper.on('blank:pointerdown', (evt) => {
			this.setRedoUndo(this.commandManager.hasUndo(), this.commandManager.hasRedo());
			if (keyboard.isActive('shift', evt)) {
				this.selection.startSelecting(evt);
			} else {
				this.selection.collection.reset([]);
				this.paperScroller.startPanning(evt);
				this.paper.removeTools();
			}
		});

		this.paper.on('element:pointerdown', (elementView, evt) => {
			this.setRedoUndo(this.commandManager.hasUndo(), this.commandManager.hasRedo());
			// Select an element if CTRL/Meta key is pressed while the element is clicked.
			if (keyboard.isActive('ctrl meta', evt)) {
				this.selection.collection.add(elementView.model);
			}
		});

		this.graph.on('remove', (cell) => {
			this.setRedoUndo(this.commandManager.hasUndo(), this.commandManager.hasRedo());
			// If element is removed from the graph, remove from the selection too.
			if (this.selection.collection.has(cell)) {
				this.selection.collection.reset(
					this.selection.collection.models.filter((c) => c !== cell),
				);
			}
			this.setElementIsSelected(0);
			if (cell.id === PIE_CHART_ID) {
				this.setPieChartElementIsPresent(false);
			}

			if (cell.id === PICTURE_CELL_ID) {
				this.handleNewClick(false, false);
			}
		});

		this.graph.on('change:size', (cell) => {
			this.setRedoUndo(this.commandManager.hasUndo(), this.commandManager.hasRedo());
			this.setAllGeneralMenuValues(cell.size().height, cell.size().width);
		});

		this.selection.on(
			'selection-box:pointerdown',
			(elementView, evt) => {
				this.setRedoUndo(this.commandManager.hasUndo(), this.commandManager.hasRedo());
				// Unselect an element if the CTRL/Meta key is pressed while a selected element is clicked.
				if (keyboard.isActive('ctrl meta', evt)) {
					evt.preventDefault();
					this.selection.collection.remove(elementView.model);
				}
			},
			this,
		);
	}

	setAllValuesAfterRedoUndo() {
		const currentPieChartCell = this.graph
			.getCells()
			.filter((cell) => cell.id === PIE_CHART_ID)[0];
		const { collection } = this.selection;
		if (collection.length === 1) {
			const primaryCell = collection.first();
			const currentSize = primaryCell.size();
			this.setAllGeneralMenuValues(currentSize.height, currentSize.width);
			this.setElementIsSelected(primaryCell.id === PIE_CHART_ID ? 2 : 1);
		} else {
			this.setElementIsSelected(0);
			this.setAllGeneralMenuValues(0, 0);
		}

		if (currentPieChartCell != null) {
			this.setAllPieChartMenuValues(
				currentPieChartCell.attr('custom/is24Pie'),
				currentPieChartCell.attr('custom/angle'),
				currentPieChartCell.attr('custom/direction'),
				currentPieChartCell.attr('custom/pieChartKind'),
				currentPieChartCell.attr('l80/stroke'),
				currentPieChartCell.attr('l80/strokeWidth'),
				currentPieChartCell.attr('l80/strokeDasharray'),
				currentPieChartCell.attr('l240/stroke'),
				currentPieChartCell.attr('l240/strokeWidth'),
				currentPieChartCell.attr('l240/strokeDasharray'),
			);
			this.setAllCelestialDirectionsMenuValues(
				currentPieChartCell.attr('custom/useCelestialDirections'),
				currentPieChartCell.attr('n2/fill'),
				currentPieChartCell.attr('n2/fontSize'),
				currentPieChartCell.attr('n2/fontFamily'),
				currentPieChartCell.attr('custom/distanceToCenter'),
			);
		}
		this.setPieChartElementIsPresent(currentPieChartCell != null);
	}

	changeElementSize(newHeight, newWidth) {
		const { collection } = this.selection;
		if (collection.length === 1) {
			const primaryCell = collection.first();
			if (primaryCell.id === PIE_CHART_ID) {
				// Same size and height to keep the scaling
				primaryCell.resize(newHeight, newHeight);
				this.setAllGeneralMenuValues(newHeight, newHeight);
			} else {
				primaryCell.resize(newWidth, newHeight);
			}
		}
	}

	changePieValues(
		newIs24Pie,
		newCustomAngle,
		newDirection,
		newPieChartType,
		newMainColor,
		newMainStrokeWidth,
		newMainDashArray,
		newSecondaryColor,
		newSecondaryStrokeWidth,
		newSecondaryDashArray,
	) {
		const pieChartKindChanged = this.pieChartService.changePieValues(
			newIs24Pie,
			newCustomAngle,
			newDirection,
			newPieChartType,
			newMainColor,
			newMainStrokeWidth,
			newMainDashArray,
			newSecondaryColor,
			newSecondaryStrokeWidth,
			newSecondaryDashArray,
		);
		if (pieChartKindChanged) {
			this.selectPieChartCell();
		}
	}

	changeShowCelestialDirectionValues(
		newShowCelestialDirections,
		newTextColor,
		newTextSize,
		newDistanceToCenter,
		newTextFamily,
	) {
		this.pieChartService.changeShowCelestialDirectionValues(
			newShowCelestialDirections,
			newTextColor,
			newTextSize,
			newDistanceToCenter,
			newTextFamily,
		);
	}

	centerPieChartOnSelectArea() {
		this.pieChartService.centerPieChartOnSelectArea();
	}

	onSelectionChange() {
		const { paper, selection } = this;
		const { collection } = selection;
		paper.removeTools();
		ui.Halo.clear(paper);
		ui.FreeTransform.clear(paper);

		if (collection.length === 1) {
			const primaryCell = collection.first();
			const primaryCellView = paper.requireView(primaryCell);
			selection.destroySelectionBox(primaryCell);
			this.selectPrimaryCell(primaryCellView);

			this.setAllGeneralMenuValues(primaryCell.size().height, primaryCell.size().width);

			if (selectionIsPieChart(primaryCellView)) {
				this.setAllPieChartMenuValues(
					primaryCell.attr('custom/is24Pie'),
					primaryCell.attr('custom/angle'),
					primaryCell.attr('custom/direction'),
					primaryCell.attr('custom/pieChartKind'),
					primaryCell.attr('l80/stroke'),
					primaryCell.attr('l80/strokeWidth'),
					primaryCell.attr('l80/strokeDasharray'),
					primaryCell.attr('l240/stroke'),
					primaryCell.attr('l240/strokeWidth'),
					primaryCell.attr('l240/strokeDasharray'),
				);

				this.setAllCelestialDirectionsMenuValues(
					primaryCell.attr('custom/useCelestialDirections'),
					primaryCell.attr('n2/fill'),
					primaryCell.attr('n2/fontSize'),
					primaryCell.attr('n2/fontFamily'),
					primaryCell.attr('custom/distanceToCenter'),
				);
			}
		} else {
			selection.removeHandle('resize');
			this.setElementIsSelected(0);
			this.setAllGeneralMenuValues(0, 0);
		}
	}

	clearCurrentPaper() {
		this.setPieChartElementIsPresent(false);
		this.graph.clear();
		this.selection.collection.reset([]);
	}

	addPicture(pictureBase64String, image) {
		const pictureCell = new shapes.standard.Image({
			id: PICTURE_CELL_ID,
			position: {
				x: PAPER_SIZE / 2 - image.width / 2,
				y: PAPER_SIZE / 2 - image.height / 2,
			},
			size: {
				width: image.width,
				height: image.height,
			},
			attrs: {
				image: {
					'xlink:href': pictureBase64String,
					width: image.width,
					height: image.height,
				},
			},
		});

		this.graph.resetCells([pictureCell]);
		// We're in the async mode, make sure all the views are rendered for the PNG export
		// this.paper.dumpViews();
		this.selection.collection.reset(pictureCell);

		this.centerElements();
	}

	addSelector() {
		const currentPictureCell = this.graph
			.getCells()
			.filter((cell) => cell.id === PICTURE_CELL_ID)[0];
		const currentPieChartCell = this.graph
			.getCells()
			.filter((cell) => cell.id === PIE_CHART_ID)[0];
		let selectorRect = this.graph
			.getCells()
			.filter((cell) => cell.id === PIE_CHART_SELECTOR_ID)[0];

		if (selectorRect == null) {
			const swapValues = !(
				(Math.abs(currentPictureCell.attributes.angle) >= 0 &&
					Math.abs(currentPictureCell.attributes.angle) < 45) ||
				(Math.abs(currentPictureCell.attributes.angle) > 315 &&
					Math.abs(currentPictureCell.attributes.angle) <= 360) ||
				(Math.abs(currentPictureCell.attributes.angle) >= 135 &&
					Math.abs(currentPictureCell.attributes.angle) < 225)
			);
			selectorRect = new shapes.standard.Rectangle({
				id: PIE_CHART_SELECTOR_ID,
				position: {
					x: swapValues
						? currentPictureCell.position().y
						: currentPictureCell.position().x,
					y: swapValues
						? currentPictureCell.position().x
						: currentPictureCell.position().y,
				},
				size: {
					width: swapValues
						? currentPictureCell.getBBox().height
						: currentPictureCell.getBBox().width,
					height: swapValues
						? currentPictureCell.getBBox().width
						: currentPictureCell.getBBox().height,
				},
				attrs: {
					body: {
						fill: 'none',
						strokeWidth: 0,
					},
				},
			});

			currentPictureCell.embed(selectorRect);

			const cells = [currentPictureCell, selectorRect];

			if (currentPieChartCell != null) {
				cells.push(currentPieChartCell);
			}

			this.graph.resetCells(cells);
		}

		// Make sure new or old element gets selected immediately. This makes the UX better as
		// the user can immediately manipulate the new element.
		this.selection.collection.reset(selectorRect);
	}

	add8or24Pie(angle, direction, is24Pie, useCelestialDirections, pieChartKind, distanceToCenter) {
		this.pieChartService.add8or24Pie(
			angle,
			direction,
			is24Pie,
			useCelestialDirections,
			pieChartKind,
			distanceToCenter,
		);
	}

	selectPictureCell() {
		const currentPictureCell = this.graph
			.getCells()
			.filter((cell) => cell.id === PICTURE_CELL_ID)[0];
		this.selection.collection.reset(currentPictureCell);
	}

	selectPieChartCell() {
		const currentPictureCell = this.graph
			.getCells()
			.filter((cell) => cell.id === PIE_CHART_ID)[0];
		this.selection.collection.reset(currentPictureCell);
	}

	selectPrimaryElement(elementView) {
		const elementModel = elementView.model;

		if (elementModel.id === PIE_CHART_ID) {
			const that = this;
			const ResizeTool = elementTools.Control.extend({
				getPosition(view) {
					const { model } = view;
					const { width, height } = model.size();
					return { x: width, y: height };
				},
				setPosition(view, coordinates) {
					const { model, paper } = view;
					const center = model.getBBox().center();
					const grid = paper.options.gridSize;
					let width = g.snapToGrid(Math.max(coordinates.x, grid), grid);
					let height = g.snapToGrid(Math.max(coordinates.y, grid), grid);
					const currentSize = model.size();

					if (this.options.preserveAspectRatio) {
						const candidateWidth = (currentSize.width * height) / currentSize.height;
						const candidateHeight = (currentSize.height * width) / currentSize.width;
						if (candidateWidth < width) {
							height = candidateHeight;
						} else {
							width = candidateWidth;
						}
					}

					model.set(
						'size',
						{
							width: Math.trunc(width),
							height: Math.trunc(height),
						},
						{
							ui: true,
							tool: this.cid,
						},
					);
					const size = model.size();
					model.position(center.x - size.width / 2, center.y - size.height / 2, {
						ui: true,
						tool: this.cid,
					});

					that.pieChartService.handlePieChartSizeChange(model, {
						height: Math.trunc(height),
						width: Math.trunc(width),
					});
				},
			});

			elementView.addTools(
				new dia.ToolsView({
					tools: [
						new ResizeTool({
							preserveAspectRatio: !!elementModel.get('preserveAspectRatio'),
							snapToGrid: true,
							selector: 'pie',
							handleAttributes: {
								fill: 'white',
								stroke: 'black',
								r: '15',
								strokeWidth: '2',
							},
						}),
					],
				}),
			);
		} else {
			new ui.FreeTransform({
				cellView: elementView,
				allowRotation: false,
				preserveAspectRatio: !!elementModel.get('preserveAspectRatio'),
				allowOrthogonalResize: elementModel.get('allowOrthogonalResize') !== false,
			}).render();
		}

		this.setElementIsSelected(elementModel.id === PIE_CHART_ID ? 2 : 1);
		this.haloService.create(elementView, elementModel.id);
	}
}

export default RappidService;
