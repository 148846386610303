import { readTextFromFile } from '../../../../helpers/file-handle-helpers';
import {
	FLYING_STARS_MAIN_GROUP_ID,
	FLYING_STARS_SECONDARY_GROUP_ID,
	PIE_CHART_ID,
} from '../../../../common/data/selectorIDs';
import ExportImportServiceBase from '../../../../common/services/ExportImportServiceBase';
import { get24CelestialDirectionFromAngle } from '../../../../helpers/pie-chart-helpers';

class ExportImportService extends ExportImportServiceBase {
	constructor(rappidService) {
		super(rappidService);
		this.setFlyingStarsElementIsPresent = rappidService.setFlyingStarsElementIsPresent;
		this.setFlyingStarsAdditionalElementIsPresent =
			rappidService.setFlyingStarsAdditionalElementIsPresent;
		this.setPieChartValues = rappidService.setPieChartValues;
		this.setAllMainChartMenuValues = rappidService.setAllMainChartMenuValues;
		this.setAllAdditionalChartMenuValues = rappidService.setAllAdditionalChartMenuValues;
	}

	async uploadGraph(file, showNotification) {
		try {
			const fileText = await readTextFromFile(file);
			this.graph.fromJSON(JSON.parse(fileText));
			const cells = this.graph.getCells();

			localStorage.setItem('currentJointGraph', fileText);

			const pieChartCells = cells.filter((cell) => cell.id === PIE_CHART_ID);
			if (pieChartCells.length === 1) {
				const angle = pieChartCells[0].attr('custom/angle');
				this.setPieChartValues(angle, get24CelestialDirectionFromAngle(angle));

				const mainCells = cells.filter((cell) => cell.id === FLYING_STARS_MAIN_GROUP_ID);
				this.setFlyingStarsElementIsPresent(mainCells.length === 1);
				if (mainCells.length === 1) {
					const mainCell = mainCells[0];
					this.setAllMainChartMenuValues(
						mainCell.attr('nSitting/fill'),
						mainCell.attr('nSitting/fontSize'),
						mainCell.attr('custom/facingSittingOffset'),
						mainCell.attr('nBase/fill'),
						mainCell.attr('nBase/fontSize'),
						mainCell.attr('nYear/fill'),
						mainCell.attr('nYear/fontSize'),
						mainCell.attr('nYear/text') !== '',
						mainCell.attr('nSitting/fontFamily'),
						mainCell.attr('n/fill'),
						mainCell.attr('n/r'),
						mainCell.attr('custom/distanceToCenter'),
					);
				}

				const secondaryCells = cells.filter(
					(cell) => cell.id === FLYING_STARS_SECONDARY_GROUP_ID,
				);
				this.setFlyingStarsAdditionalElementIsPresent(secondaryCells.length === 1);
				if (secondaryCells.length === 1) {
					const secondaryCell = secondaryCells[0];
					this.setAllAdditionalChartMenuValues(
						secondaryCell.attr('nSitting/fill'),
						secondaryCell.attr('nSitting/fontSize'),
						secondaryCell.attr('custom/facingSittingOffset'),
						secondaryCell.attr('nBase/fill'),
						secondaryCell.attr('nBase/fontSize'),
						secondaryCell.attr('nYear/fill'),
						secondaryCell.attr('nYear/fontSize'),
						secondaryCell.attr('nYear/text') !== '',
						secondaryCell.attr('nSitting/fontFamily'),
					);
				}

				return true;
			}

			showNotification();
			return false;
		} catch (error) {
			showNotification();
			this.setFlyingStarsElementIsPresent(false);

			return false;
		}
	}
}

export default ExportImportService;
