import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import React from 'react';
import Modal, { ModalBody, ModalFooter, ModalHeader } from './bootstrap/Modal';
import Button from './bootstrap/Button';
import { OffCanvasTitle } from './bootstrap/OffCanvas';

const DialogBox = ({
	showDialog,
	setIsOpen,
	id,
	handleCancel,
	handleSubmit,
	bodyTextPrimary,
	bodyTextSecondary,
}) => {
	const { t: tg } = useTranslation('general');

	return (
		<Modal
			isOpen={showDialog}
			setIsOpen={setIsOpen}
			titleId={id}
			isCentered
			isScrollable
			size='lg'>
			<ModalHeader>
				<OffCanvasTitle id={`${id}Title`}>{tg('Warning')}</OffCanvasTitle>
			</ModalHeader>
			<ModalBody>
				<b>{bodyTextPrimary}</b>
				<br />
				{bodyTextSecondary}
			</ModalBody>
			<ModalFooter>
				<Button color='primary' onClick={handleSubmit}>
					{tg('Yes')}
				</Button>
				<Button color='info' isOutline onClick={handleCancel}>
					{tg('No')}
				</Button>
			</ModalFooter>
		</Modal>
	);
};

DialogBox.propTypes = {
	showDialog: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
	id: PropTypes.string.isRequired,
	handleCancel: PropTypes.func.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	bodyTextPrimary: PropTypes.string.isRequired,
	bodyTextSecondary: PropTypes.string.isRequired,
};

export default DialogBox;
