import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import Icon from '../../../components/icon/Icon';
import Button from '../../../components/bootstrap/Button';
import Spinner from '../../../components/bootstrap/Spinner';
import PRODUCTS from '../../../common/data/products';
import stripeService from '../../../services/stripe.service';
import showNotification from '../../../components/extras/showNotification';

const ProductDisplay = ({ isDisabled }) => {
	const { t: tg } = useTranslation('general');
	const { t } = useTranslation('checkout');
	const [isLoading, setIsLoading] = useState(false);
	const [paymentInterval, setPaymentInterval] = useState('monthly');
	const voucher = localStorage.getItem('stripeVoucherCode');

	const handleClick = (lookupKey) => {
		setIsLoading(true);
		stripeService.createCheckoutSession(lookupKey, paymentInterval, voucher).then(
			() => {
				showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon='Info' size='lg' className='me-1' />
						<span>{t('Stripe checkout portal opening')}</span>
					</span>,
					t(
						'You will be redirected to the Stripe checkout page and will be asked to complete the checkout on their site.',
					),
					'success',
				);
				setIsLoading(false);
			},
			() => {
				showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon='Error' size='lg' className='me-1' />
						<span>{tg('Error')}</span>
					</span>,
					t("Stripe checkout session can't be created, please try again."),
					'danger',
				);
				setIsLoading(false);
			},
		);
	};

	return (
		<Page>
			<div className='row scroll-margin'>
				<div className='col-12 mb-3'>
					<div className='display-4 fw-bold py-3'>{t('Select your Plan')}</div>
				</div>
			</div>
			<div className='row justify-content-center scroll-margin'>
				<div className='col-6 mb-3'>
					<div className='rounded-3 bg-l10-dark'>
						<div className='row row-cols-2 g-3 pb-3 px-3 mt-0'>
							<div className='col'>
								<Button
									isDisable={isLoading}
									color='dark'
									isLight={paymentInterval === 'yearly'}
									className='rounded-1 w-100'
									size='lg'
									onClick={() => {
										setPaymentInterval('monthly');
									}}>
									Monatlich
								</Button>
							</div>
							<div className='col'>
								<Button
									isDisable={isLoading}
									color='dark'
									isLight={paymentInterval === 'monthly'}
									className='rounded-1 w-100'
									size='lg'
									onClick={() => {
										setPaymentInterval('yearly');
									}}>
									Jährlich
								</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='row scroll-margin'>
				{PRODUCTS.filter((product) => product.type === paymentInterval).map((product) => {
					const buttonText = product.enabled ? t('Select Plan') : t('Coming Soon');
					return (
						<div className='col-xl-3 col-lg-6 col-md-12'>
							<Card stretch>
								<CardBody>
									<div className='row pt-5 g-4 align-items-center'>
										<div className='col-auto'>
											<Icon
												icon={product.icon}
												size='5x'
												color={product.color}
											/>
										</div>
										<div className='col'>
											<h2>{product.name}</h2>
										</div>
										<div className='col-12'>
											<h3 className='display-2 fw-bold'>
												<span className='display-2 fw-bold'>€</span>
												{product.price}
												<span className='display-6'>
													/
													{paymentInterval === 'monthly'
														? t('Month')
														: t('Year')}
												</span>
											</h3>
											<div className='text-muted'>
												<span>
													{t('excl.')} €{product.vat} {t('VAT')}
												</span>
											</div>
										</div>
										<div className='col-12'>
											<div
												className={
													product.features.sectorsPie
														? 'lead'
														: 'lead text-muted'
												}>
												<Icon
													icon={
														product.features.sectorsPie
															? 'Done Outline'
															: 'Close'
													}
													color={
														product.features.sectorsPie
															? 'success'
															: 'danger'
													}
												/>{' '}
												{t('8 or 24 Sectors Pie')}
											</div>
											<div
												className={
													product.features.celestialDirections
														? 'lead'
														: 'lead text-muted'
												}>
												<Icon
													icon={
														product.features.celestialDirections
															? 'Done Outline'
															: 'Close'
													}
													color={
														product.features.celestialDirections
															? 'success'
															: 'danger'
													}
												/>{' '}
												{t('Draw Celestial Directions')}
											</div>
											<div
												className={
													product.features.flyingStars
														? 'lead'
														: 'lead text-muted'
												}>
												<Icon
													icon={
														product.features.flyingStars
															? 'Done Outline'
															: 'Close'
													}
													color={
														product.features.flyingStars
															? 'success'
															: 'danger'
													}
												/>{' '}
												{t('Draw Flying Stars')}
											</div>
											<div
												className={
													product.features.fiveElements
														? 'lead'
														: 'lead text-muted'
												}>
												<Icon
													icon={
														product.features.fiveElements
															? 'Done Outline'
															: 'Close'
													}
													color={
														product.features.fiveElements
															? 'success'
															: 'danger'
													}
												/>{' '}
												{t('Draw 5 Elements')}
											</div>
											<div
												className={
													product.features.configurator
														? 'lead'
														: 'lead text-muted'
												}>
												<Icon
													icon={
														product.features.configurator
															? 'Done Outline'
															: 'Close'
													}
													color={
														product.features.configurator
															? 'success'
															: 'danger'
													}
												/>{' '}
												{t('WOW Configurator')}
											</div>
											<div
												className={
													product.features.eastWestGroups
														? 'lead'
														: 'lead text-muted'
												}>
												<Icon
													icon={
														product.features.eastWestGroups
															? 'Done Outline'
															: 'Close'
													}
													color={
														product.features.eastWestGroups
															? 'success'
															: 'danger'
													}
												/>{' '}
												{t('Draw East & West Groups')}
											</div>
											<div
												className={
													product.features.guaSector
														? 'lead'
														: 'lead text-muted'
												}>
												<Icon
													icon={
														product.features.guaSector
															? 'Done Outline'
															: 'Close'
													}
													color={
														product.features.guaSector
															? 'success'
															: 'danger'
													}
												/>{' '}
												{t('Gua Sector Evaluation')}
											</div>
											<div
												className={
													product.features.guaDirection
														? 'lead'
														: 'lead text-muted'
												}>
												<Icon
													icon={
														product.features.guaDirection
															? 'Done Outline'
															: 'Close'
													}
													color={
														product.features.guaDirection
															? 'success'
															: 'danger'
													}
												/>{' '}
												{t('Gua Direction Evaluation')}
											</div>
											<div
												className={
													product.features.pieFurniture
														? 'lead'
														: 'lead text-muted'
												}>
												<Icon
													icon={
														product.features.pieFurniture
															? 'Done Outline'
															: 'Close'
													}
													color={
														product.features.pieFurniture
															? 'success'
															: 'danger'
													}
												/>{' '}
												{t('Place Pie on Furniture')}
											</div>
											<div
												className={
													product.features.adaptionFurniturePlan
														? 'lead'
														: 'lead text-muted'
												}>
												<Icon
													icon={
														product.features.adaptionFurniturePlan
															? 'Done Outline'
															: 'Close'
													}
													color={
														product.features.adaptionFurniturePlan
															? 'success'
															: 'danger'
													}
												/>{' '}
												{t('Adaptation Furniture Floor Plan')}
											</div>
										</div>
										<div className='col-12'>
											<Button
												isDisable={
													isLoading || isDisabled || !product.enabled
												}
												onClick={() => handleClick(product.lookupKey)}
												type='submit'
												color={product.color}
												isLight={!product.enabled}
												className='w-100 py-3 text-uppercase'
												size='lg'>
												{isLoading && (
													<Spinner
														color={product.color}
														inButton
														isSmall
													/>
												)}
												{isLoading ? t('Loading...') : buttonText}
											</Button>
										</div>
									</div>
								</CardBody>
							</Card>
						</div>
					);
				})}
			</div>
		</Page>
	);
};

ProductDisplay.propTypes = {
	isDisabled: PropTypes.bool.isRequired,
};

export default ProductDisplay;
