import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/bootstrap/Button';
import pages from '../../../menu';
import { UserContext } from '../../../contexts/userContext';
import CommonDesc from '../../../components/common/CommonDesc';

const DefaultHeader = () => {
	const { t } = useTranslation('profile');
	const { currentUser } = useContext(UserContext);

	if (currentUser == null || !currentUser.emailConfirmed) {
		return (
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<CommonDesc isFullWidth={false}>
					<div className='row'>
						<div className='col-10' style={{ display: 'flex', alignItems: 'center' }}>
							{t(
								'Please confirm your email address in order to purchase a subscription and unlock all features.',
							)}
						</div>
						<div className='col-2'>
							<Button
								tag='a'
								to={pages.profile.path}
								color='info'
								className='text-uppercase'
								size='sm'>
								{t('Profile')}
							</Button>
						</div>
					</div>
				</CommonDesc>
			</div>
		);
	}

	return null;
};

export default DefaultHeader;
