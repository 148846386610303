import React, { useState } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import Spinner from '../../../components/bootstrap/Spinner';
import Card, {
	CardBody,
	CardFooter,
	CardFooterLeft,
	CardFooterRight,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import showNotification from '../../../components/extras/showNotification';
import Button from '../../../components/bootstrap/Button';
import Icon from '../../../components/icon/Icon';
import { TABS } from '../ProfilePage';
import userService from '../../../services/user.service';
import PasswordInput from '../../../components/PasswordInput';

const ChangePassword = () => {
	const [isLoading, setIsLoading] = useState(false);
	const { t } = useTranslation('profile');
	const { t: tl } = useTranslation('login');
	const { t: tg } = useTranslation('general');

	// #region validate
	const validate = (values) => {
		const errors = {};

		if (values.currentPassword) {
			if (!values.newPassword) {
				errors.newPassword = tg('Required');
			} else {
				errors.newPassword = '';

				if (values.newPassword.length < 8 || values.newPassword.length > 32) {
					errors.newPassword += tl(
						'The password must be at least 8 characters long, but no more than 32. ',
					);
				}
				if (!/\d/g.test(values.newPassword)) {
					errors.newPassword += tl('Requires at least one digit. ');
				}
				if (!/[a-z]/g.test(values.newPassword)) {
					errors.newPassword += tl('Requires at least one lowercase letter. ');
				}
				if (!/[A-Z]/g.test(values.newPassword)) {
					errors.newPassword += tl('Requires at least one uppercase letter. ');
				}
				if (!/[!@#$%^&*)(+=._-]/g.test(values.newPassword)) {
					errors.newPassword += tl('Requires at least one special character. ');
				}
			}

			if (errors.newPassword === '') {
				delete errors.newPassword;
			}

			if (!values.newPasswordConfirm) {
				errors.newPasswordConfirm = tg('Required');
			} else if (values.newPassword !== values.newPasswordConfirm) {
				errors.newPasswordConfirm = tl('Password need to match');
			}
		}

		return errors;
	};
	// #endregion

	const formik = useFormik({
		initialValues: {
			currentPassword: '',
			newPassword: '',
			newPasswordConfirm: '',
		},
		validate,
		onSubmit: (values) => {
			setIsLoading(true);
			userService
				.changePassword(
					values.currentPassword,
					values.newPassword,
					values.newPasswordConfirm,
				)
				.then(
					() => {
						showNotification(
							<span className='d-flex align-items-center'>
								<Icon icon='Info' size='lg' className='me-1' />
								<span>{t('Updated Successfully')}</span>
							</span>,
							t('The password has been changed successfully.'),
							'success',
						);
						setIsLoading(false);
						formik.resetForm();
					},
					(err) => {
						if (err != null && err.errors == null && err[0] === 'Incorrect password.') {
							showNotification(
								<span className='d-flex align-items-center'>
									<Icon icon='Error' size='lg' className='me-1' />
									<span>{t('Update not Successful')}</span>
								</span>,
								t('Your current password is incorrect.'),
								'danger',
							);
						} else {
							showNotification(
								<span className='d-flex align-items-center'>
									<Icon icon='Error' size='lg' className='me-1' />
									<span>{t('Update not Successful')}</span>
								</span>,
								t('The password has not been changed, please try again.'),
								'danger',
							);
						}

						setIsLoading(false);
					},
				);
		},
	});

	return (
		<Card stretch tag='form' noValidate onSubmit={formik.handleSubmit}>
			<CardHeader>
				<CardLabel icon='LocalPolice' iconColor='info'>
					<CardTitle>{t(TABS.CHANGE_PASSWORD)}</CardTitle>
				</CardLabel>
			</CardHeader>
			<CardBody className='pb-0' isScrollable>
				<div className='row g-4'>
					<div className='col-12'>
						<FormGroup id='currentPassword' label={t('Current password')} isFloating>
							<PasswordInput
								placeholder={t('Current password')}
								autoComplete='current-password'
								onChange={formik.handleChange}
								value={formik.values.currentPassword}
								isTouched={formik.touched.currentPassword}
								invalidFeedback={formik.errors.currentPassword}
							/>
						</FormGroup>
					</div>
					<div className='col-12'>
						<FormGroup id='newPassword' label={t('New password')} isFloating>
							<PasswordInput
								placeholder={t('New password')}
								autoComplete='new-password'
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.newPassword}
								isValid={formik.isValid}
								isTouched={formik.touched.newPassword}
								invalidFeedback={formik.errors.newPassword}
							/>
						</FormGroup>
					</div>
					<div className='col-12'>
						<FormGroup
							id='newPasswordConfirm'
							label={t('Confirm new password')}
							isFloating>
							<PasswordInput
								placeholder={t('Confirm new password')}
								autoComplete='new-password'
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.newPasswordConfirm}
								isValid={formik.isValid}
								isTouched={formik.touched.newPasswordConfirm}
								invalidFeedback={formik.errors.newPasswordConfirm}
							/>
						</FormGroup>
					</div>
				</div>
			</CardBody>
			<CardFooter>
				<CardFooterLeft>
					<Button color='info' isLink type='reset' onClick={formik.resetForm}>
						{t('Reset')}
					</Button>
				</CardFooterLeft>
				<CardFooterRight>
					<Button
						type='submit'
						color='info'
						isOutline
						isDisable={isLoading || formik.values.currentPassword === ''}
						icon={isLoading ? null : 'Save'}
						onClick={formik.handleSubmit}>
						{isLoading && <Spinner color='info' inButton isSmall />}
						{tg('Save')}
					</Button>
				</CardFooterRight>
			</CardFooter>
		</Card>
	);
};

export default ChangePassword;
