import { ui, g } from '@clientio/rappid';
import checkLogo from '../../../../assets/img/check.png';
import rotateLogo from '../../../../assets/img/rotateIcon.png';
import PieChartService from './PieChartService';
import i18n from '../../../../i18n';
import { PIE_CHART_ID, PIE_CHART_SELECTOR_ID } from '../../../../common/data/selectorIDs';

const Position = ui.Halo.HandlePosition;

class HaloService {
	constructor(setSubmitModalStatus, setAllPieChartMenuValues) {
		this.setSubmitModalStatus = setSubmitModalStatus;
		this.setAllPieChartMenuValues = setAllPieChartMenuValues;
	}

	create(cellView, haloType) {
		this.halo = new ui.Halo({
			cellView,
			handles: this.getHaloConfig(haloType),
			useModelGeometry: true,
			rotateAngleGrid: haloType === PIE_CHART_ID ? 0.5 : 1,
			setAllPieChartMenuValues: this.setAllPieChartMenuValues,
		});
		this.options = this.halo.options;
		this.halo.render();
	}

	getHaloConfig(haloType) {
		switch (haloType) {
			case PIE_CHART_SELECTOR_ID:
				return [
					{
						name: 'confirmSelection',
						position: Position.E,
						icon: checkLogo,
						events: {
							pointerdown: () => this.setSubmitModalStatus(true),
						},
						attrs: {
							'.handle': {
								'data-tooltip-class-name': 'small',
								'data-tooltip': i18n.t(
									'Confirm the selection and draw the pie chart',
									{ ns: 'startWOW' },
								),
								'data-tooltip-position': 'right',
								'data-tooltip-padding': 15,
							},
						},
					},
				];
			case PIE_CHART_ID:
				return [
					{
						name: 'remove',
						position: Position.N,
						events: { pointerdown: 'removeElement' },
						attrs: {
							'.handle': {
								'data-tooltip-class-name': 'small',
								'data-tooltip': i18n.t('Click to remove the object', {
									ns: 'startWOW',
								}),
								'data-tooltip-position': 'right',
								'data-tooltip-padding': 15,
							},
						},
					},
					{
						name: 'customRotate',
						icon: rotateLogo,
						position: Position.S,
						events: {
							pointerdown: this.startRotating,
							pointermove: this.doRotate,
							pointerup: this.stopBatch,
						},
						attrs: {
							'.handle': {
								'data-tooltip-class-name': 'small',
								'data-tooltip': i18n.t('Click and drag to rotate the object', {
									ns: 'startWOW',
								}),
								'data-tooltip-position': 'right',
								'data-tooltip-padding': 15,
							},
						},
					},
				];
			default:
				return [
					{
						name: 'remove',
						position: Position.N,
						events: { pointerdown: 'removeElement' },
						attrs: {
							'.handle': {
								'data-tooltip-class-name': 'small',
								'data-tooltip': i18n.t('Click to remove the object', {
									ns: 'startWOW',
								}),
								'data-tooltip-position': 'right',
								'data-tooltip-padding': 15,
							},
						},
					},
					{
						name: 'rotate',
						position: Position.S,
						events: {
							pointerdown: 'startRotating',
							pointermove: 'doRotate',
							pointerup: 'stopBatch',
						},
						attrs: {
							'.handle': {
								'data-tooltip-class-name': 'small',
								'data-tooltip': i18n.t('Click and drag to rotate the object', {
									ns: 'startWOW',
								}),
								'data-tooltip-position': 'right',
								'data-tooltip-padding': 15,
							},
						},
					},
				];
		}
	}

	stopBatch() {
		const { graph } = this.options;
		if (!graph.hasActiveBatch('halo')) return;
		graph.stopBatch('halo', { halo: this.cid });
	}

	startBatch() {
		this.options.graph.startBatch('halo', { halo: this.halo.cid });
	}

	startRotating(evt, x, y) {
		this.startBatch();

		const element = this.options.cellView.model;
		const center = element.getBBox().center();
		const elements = [element];

		this.eventData(evt, {
			center,
			elements,
			rotationStartAngles: elements.map((el) => {
				return el.attr('custom/angle');
			}),
			clientStartAngle: new g.Point(x, y).theta(center),
		});
	}

	doRotate(evt, x, y) {
		const data = this.eventData(evt);
		// Calculate an angle between the line starting at mouse coordinates, ending at the centre
		// of rotation and y-axis and deduct the angle from the start of rotation.
		const theta = data.clientStartAngle - new g.Point(x, y).theta(data.center);

		data.elements.forEach((element, index) => {
			const rotationStartAngle = data.rotationStartAngles[index];
			const newAngle = g.snapToGrid(rotationStartAngle - theta, this.options.rotateAngleGrid);
			PieChartService.handlePieChartAngleChange(
				element,
				newAngle,
				this.options.setAllPieChartMenuValues,
			);
		}, this);
	}
}

export default HaloService;
