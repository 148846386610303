import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Input, InputNumber, Row } from 'antd';
import FontSelectorAutocomplete from '../../../../components/extras/fontDropdown';
import DataContext from '../context/DataContext';
import ColorPicker from '../../../../components/ColorPicker';
import CustomTooltip from '../../../../components/CustomTooltip';

const AdditionalChartMenu = () => {
	const { t } = useTranslation('flyWOW');
	const {
		flyingStarsAdditionalElementIsPresent,
		yearEnergyIsActiveAdditionalChart,
		textColorSittingFacingAdditionalChart,
		textSizeSittingFacingAdditionalChart,
		raiseUpSittingFacingAdditionalChart,
		textColorBaseAdditionalChart,
		textSizeBaseAdditionalChart,
		textColorYearEnergyAdditionalChart,
		textSizeYearEnergyAdditionalChart,
		textFamilyAdditionalChart,

		handleTextColorSittingFacingAdditionalChartChange,
		handleTextSizeSittingFacingAdditionalChartChange,
		handleRaiseUpSittingFacingAdditionalChartChange,
		handleTextColorBaseAdditionalChartChange,
		handleTextSizeBaseAdditionalChartChange,
		handleTextColorYearEnergyAdditionalChartChange,
		handleTextSizeYearEnergyAdditionalChartChange,
		handleTextFamilyAdditionalChartChange,
	} = useContext(DataContext);

	return (
		<Row gutter={[16, 16]} style={{ padding: '0.5rem 1.5rem' }}>
			{/* Sitting Facing Section */}
			<Col flex={4}>
				<Input.Group compact>
					<CustomTooltip title={`${t('Text Color Sitting-Facing')}`}>
						<ColorPicker
							popup
							className='first-item'
							blockStyles={{
								width: '60px',
								height: '30px',
								marginTop: '1px',
								marginRight: '0',
							}}
							disabled={!flyingStarsAdditionalElementIsPresent}
							value={textColorSittingFacingAdditionalChart}
							onChange={handleTextColorSittingFacingAdditionalChartChange}
						/>
					</CustomTooltip>
					<CustomTooltip title={`${t('Text Size Sitting-Facing')}`}>
						<InputNumber
							disabled={!flyingStarsAdditionalElementIsPresent}
							onChange={handleTextSizeSittingFacingAdditionalChartChange}
							value={textSizeSittingFacingAdditionalChart}
							formatter={(value) => `${value} px`}
							parser={(value) => value.replace('px', '')}
							min={1}
							max={100}
							step={1}
						/>
					</CustomTooltip>
					<CustomTooltip title={`${t('Raise up Sitting-Facing')}`}>
						<InputNumber
							disabled={!flyingStarsAdditionalElementIsPresent}
							onChange={handleRaiseUpSittingFacingAdditionalChartChange}
							value={raiseUpSittingFacingAdditionalChart}
							formatter={(value) => `${value}%`}
							parser={(value) => value.replace('%', '')}
							min={20}
							max={100}
							step={1}
						/>
					</CustomTooltip>
				</Input.Group>
			</Col>
			{/* Base Energy Section */}
			<Col flex={2}>
				<Input.Group compact>
					<CustomTooltip title={`${t('Text Color Base')}`}>
						<ColorPicker
							popup
							className='first-item'
							blockStyles={{
								width: '60px',
								height: '30px',
								marginTop: '1px',
								marginRight: '0',
							}}
							disabled={!flyingStarsAdditionalElementIsPresent}
							value={textColorBaseAdditionalChart}
							onChange={handleTextColorBaseAdditionalChartChange}
						/>
					</CustomTooltip>
					<CustomTooltip title={`${t('Text Size Base')}`}>
						<InputNumber
							disabled={!flyingStarsAdditionalElementIsPresent}
							onChange={handleTextSizeBaseAdditionalChartChange}
							value={textSizeBaseAdditionalChart}
							formatter={(value) => `${value} px`}
							parser={(value) => value.replace('px', '')}
							min={1}
							max={100}
							step={1}
						/>
					</CustomTooltip>
				</Input.Group>
			</Col>
			{/* Year Energy Section */}
			{flyingStarsAdditionalElementIsPresent && yearEnergyIsActiveAdditionalChart && (
				<Col flex={2}>
					<Input.Group compact>
						<CustomTooltip title={`${t('Text Color Year Energy')}`}>
							<ColorPicker
								popup
								className='first-item'
								blockStyles={{
									width: '60px',
									height: '30px',
									marginTop: '1px',
									marginRight: '0',
								}}
								value={textColorYearEnergyAdditionalChart}
								onChange={handleTextColorYearEnergyAdditionalChartChange}
							/>
						</CustomTooltip>
						<CustomTooltip title={`${t('Text Size Year Energy')}`}>
							<InputNumber
								onChange={handleTextSizeYearEnergyAdditionalChartChange}
								value={textSizeYearEnergyAdditionalChart}
								formatter={(value) => `${value} px`}
								parser={(value) => value.replace('px', '')}
								min={1}
								max={100}
								step={1}
							/>
						</CustomTooltip>
					</Input.Group>
				</Col>
			)}
			<Col flex={yearEnergyIsActiveAdditionalChart ? 4 : 6}>
				<FontSelectorAutocomplete
					bodyStyle={{ width: '65%' }}
					tooltipText={`${t('Text Family')}`}
					fontName={textFamilyAdditionalChart}
					setFontName={handleTextFamilyAdditionalChartChange}
					isDisabled={!flyingStarsAdditionalElementIsPresent}
				/>
			</Col>
		</Row>
	);
};

export default AdditionalChartMenu;
