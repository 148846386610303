import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';
import CustomTooltip from '../CustomTooltip';

const options = [
	{
		label: 'DIN',
		value: 'wf_standard-font, helvetica, arial, sans-serif',
	},
	{
		label: 'Arial',
		value: 'Arial',
	},
	{
		label: 'Arial Black',
		value: '"Arial Black"',
	},
	{
		label: 'Arial Unicode MS',
		value: '"Arial Unicode MS"',
	},
	{
		label: 'Calibri',
		value: 'Calibri',
	},
	{
		label: 'Cambria',
		value: 'Cambria',
	},
	{
		label: 'Cambria Math',
		value: '"Cambria Math"',
	},
	{
		label: 'Candara',
		value: 'Candara',
	},
	{
		label: 'Comic Sans MS',
		value: '"Comic Sans MS"',
	},
	{
		label: 'Consolas',
		value: 'Consolas',
	},
	{
		label: 'Constantia',
		value: 'Constantia',
	},
	{
		label: 'Corbel',
		value: 'Corbel',
	},
	{
		label: 'Courier New',
		value: '"Courier New"',
	},
	{
		label: 'Georgia',
		value: 'Georgia',
	},
	{
		label: 'Lucida Sans Unicode',
		value: '"Lucida Sans Unicode"',
	},
	{
		label: 'Segoe (Bold)',
		value: '"Segoe UI Bold", wf_segoe-ui_bold, helvetica, arial, sans-serif',
	},
	{
		label: 'Segoe UI',
		value: '"Segoe UI", wf_segoe-ui_normal, helvetica, arial, sans-serif',
	},
	{
		label: 'Segoe UI Light',
		value: '"Segoe UI Light", wf_segoe-ui_bold, helvetica, arial, sans-serif',
	},
	{
		label: 'Symbol',
		value: 'Symbol',
	},
	{
		label: 'Tahoma',
		value: 'Tahoma',
	},
	{
		label: 'Times New Roman',
		value: '"Times New Roman"',
	},
	{
		label: 'Trebuchet MS',
		value: '"Trebuchet MS"',
	},
	{
		label: 'Verdana',
		value: 'Verdana',
	},
	{
		label: 'Wingdings',
		value: 'Wingdings',
	},
];

export const getFontFamilyObjectByValue = (fontFamilyValue) => {
	const result = options.filter((fontFamily) => fontFamily.value === fontFamilyValue);
	return [result == null || result.length === 0 ? options[1] : result[0]];
};

const FontSelectorAutocomplete = ({
	setFontName,
	fontName,
	isDisabled,
	tooltipText,
	bodyStyle,
}) => {
	const { t } = useTranslation('general');

	return (
		<CustomTooltip title={tooltipText}>
			<Select
				style={bodyStyle}
				showSearch
				placeholder={t('Choose a font...')}
				optionFilterProp='children'
				value={fontName}
				onChange={setFontName}
				filterOption={(input, option) =>
					option.label.toLowerCase().includes(input.toLowerCase())
				}
				disabled={isDisabled}
				options={options}
				notFoundContent={t('No matches found.')}
			/>
		</CustomTooltip>
	);
};
FontSelectorAutocomplete.propTypes = {
	setFontName: PropTypes.func.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	fontName: PropTypes.object.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	bodyStyle: PropTypes.object.isRequired,
	isDisabled: PropTypes.bool.isRequired,
	tooltipText: PropTypes.string.isRequired,
};

export default FontSelectorAutocomplete;
