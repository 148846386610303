import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import Card, { CardBody } from '../../components/bootstrap/Card';
import Page from '../../layout/Page/Page';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Logo from '../../components/Logo';
import Button from '../../components/bootstrap/Button';
import Spinner from '../../components/bootstrap/Spinner';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import Input from '../../components/bootstrap/forms/Input';
import showNotification from '../../components/extras/showNotification';
import Icon from '../../components/icon/Icon';
import authenticationService from '../../services/authentication.service';
import pages from '../../menu';

const ForgotPasswordPage = () => {
	const [isLoading, setIsLoading] = useState(false);
	const { t: tg } = useTranslation('general');
	const { t } = useTranslation('login');

	const validatePasswordReset = (values) => {
		const errors = {};
		if (!values.emailAddress) {
			errors.emailAddress = tg('Required');
		} else if (!/^[A-Z\d._%+-]+@[A-Z0\d-]+\.[A-Z]+$/i.test(values.emailAddress)) {
			errors.emailAddress = t('Invalid email address');
		}

		return errors;
	};

	const formikPasswordReset = useFormik({
		initialValues: {
			emailAddress: '',
		},
		validate: validatePasswordReset,
		onSubmit: (values) => {
			setIsLoading(true);
			authenticationService.resetPasswordInit(values.emailAddress).then(
				() => {
					showNotification(
						<span className='d-flex align-items-center'>
							<Icon icon='Info' size='lg' className='me-1' />
							<span>{t('Email has been sent')}</span>
						</span>,
						t('Please use the link we sent you via email to reset your password.'),
					);
					setIsLoading(false);
					formikPasswordReset.resetForm();
				},
				() => {
					showNotification(
						<span className='d-flex align-items-center'>
							<Icon icon='Info' size='lg' className='me-1' />
							<span>{t('Email has been sent')}</span>
						</span>,
						t('Please use the link we sent you via email to reset your password.'),
					);
					setIsLoading(false);
					formikPasswordReset.resetForm();
				},
			);
		},
	});

	return (
		<PageWrapper title={t(pages.resetPassword.text)} className='bg-warning'>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='shadow-3d-container col-xl-4 col-lg-6 col-md-8'>
						<Card className='shadow-3d-dark'>
							<CardBody>
								<div className='text-center my-5'>
									<Link
										to='/'
										className='text-decoration-none  fw-bold display-2 text-dark'>
										<Logo width={220} />
									</Link>
								</div>

								<div className='text-center h1 fw-bold mt-5'>
									{t('Forgot Password? No problem!')}
								</div>
								<div className='text-center h4 text-muted mb-5'>
									{t('Please enter the e-mail address')}
									<br />
									{t('under which you have registered with WOW Gènerator.')}
								</div>

								<div className='row g-4'>
									<form
										className='row g-4'
										onSubmit={formikPasswordReset.handleSubmit}>
										<div className='col-12'>
											<FormGroup
												id='emailAddress'
												isFloating
												label={t('Email')}>
												<Input
													type='email'
													autoComplete='email'
													onChange={formikPasswordReset.handleChange}
													onBlur={formikPasswordReset.handleBlur}
													value={formikPasswordReset.values.emailAddress}
													isValid={formikPasswordReset.isValid}
													isTouched={
														formikPasswordReset.touched.emailAddress
													}
													invalidFeedback={
														formikPasswordReset.errors.emailAddress
													}
												/>
											</FormGroup>
										</div>
										<div className='col-12'>
											<Button
												isDisable={isLoading}
												type='submit'
												color='warning'
												className='w-100 py-3'>
												{isLoading && (
													<Spinner color='dark' inButton isSmall />
												)}
												{isLoading
													? t('Loading...')
													: t('Request new password')}
											</Button>
										</div>
										<div className='col-12 border-bottom' />
										<div className='col-12' style={{ marginTop: 0 }}>
											<Button
												tag='a'
												to={pages.login.path}
												color='link'
												className='w-100 py-3'>
												{t('Back to Login')}
											</Button>
										</div>
									</form>
								</div>
							</CardBody>
						</Card>
						<div className='text-center'>
							<a
								href='https://wowgenerator.de/privacy-policy/'
								target='_blank'
								rel='noreferrer'
								className='text-decoration-none me-3 link-dark'>
								{t('Privacy policy')}
							</a>
							<a
								href='https://wowgenerator.de/terms-of-use/'
								target='_blank'
								rel='noreferrer'
								className='link-light text-decoration-none link-dark'>
								{t('Terms of use')}
							</a>
						</div>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default ForgotPasswordPage;
