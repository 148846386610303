import {
	FLYING_STARS_MAIN_GROUP_ID,
	FLYING_STARS_SECONDARY_GROUP_ID,
	PICTURE_CELL_ID,
	PIE_CHART_ID,
} from '../../../../common/data/selectorIDs';
import {
	createFlyingStars8SectorsPieElement,
	createFlyingStars9RectanglesChartElement,
} from '../models/JointShapes';
import { CELESTIAL_DIRECTIONS } from '../../../../common/services/RappidServiceBase';
import {
	update8PieCircleElement,
	update8PieCircleElementColorAndSize,
	update8PieValue,
} from '../helpers/RappidServiceHelpers';

class FlyingStarsService {
	constructor(rappidService) {
		this.paper = rappidService.paper;
		this.graph = rappidService.graph;
		this.selection = rappidService.selection;
		this.setAllGeneralMenuValues = rappidService.setAllGeneralMenuValues;
		this.setFlyingStarsElementIsPresent = rappidService.setFlyingStarsElementIsPresent;
		this.setFlyingStarsAdditionalElementIsPresent =
			rappidService.setFlyingStarsAdditionalElementIsPresent;
		this.setCircleRadius = rappidService.setCircleRadius;
	}

	add8SectorsPie(values, facingSittingBaseValues, yearEnergyValues) {
		const cells = this.graph.getCells();
		const currentPieChartCell = cells.filter((cell) => cell.id === PIE_CHART_ID)[0];
		const pieCellBBox = currentPieChartCell.getBBox();
		// Remove old cell
		this.graph.removeCells(cells.filter((cell) => cell.id === FLYING_STARS_MAIN_GROUP_ID));

		const flyingStarsCell = createFlyingStars8SectorsPieElement(
			values,
			facingSittingBaseValues,
			yearEnergyValues,
			pieCellBBox,
			currentPieChartCell,
		);

		currentPieChartCell.embed(flyingStarsCell);

		this.graph.addCell(flyingStarsCell);

		// Make sure new element gets selected immediately. This makes the UX better as
		// the user can immediately manipulate the new element.
		flyingStarsCell.toFront({ deep: true });
		this.selection.collection.reset(flyingStarsCell);
		this.setFlyingStarsElementIsPresent(true);
	}

	add9RectanglesChart(values, facingSittingBaseValues, yearEnergyValues, isMainChart) {
		const cells = this.graph.getCells();
		const currentPictureCell = cells.filter((cell) => cell.id === PICTURE_CELL_ID)[0];
		const currentPieChartCell = cells.filter((cell) => cell.id === PIE_CHART_ID)[0];
		const pieCellBBox = currentPieChartCell.getBBox();
		const pictureCellBBox = currentPictureCell.getBBox();
		// Remove old cell
		this.graph.removeCells(
			cells.filter(
				(cell) =>
					cell.id ===
					(isMainChart ? FLYING_STARS_MAIN_GROUP_ID : FLYING_STARS_SECONDARY_GROUP_ID),
			),
		);
		const flyingStarsCell = createFlyingStars9RectanglesChartElement(
			values,
			facingSittingBaseValues,
			yearEnergyValues,
			pieCellBBox,
			pictureCellBBox,
			currentPieChartCell,
			isMainChart,
		);

		if (isMainChart) {
			currentPieChartCell.embed(flyingStarsCell);
		}

		this.graph.addCell(flyingStarsCell);

		// Make sure new element gets selected immediately. This makes the UX better as
		// the user can immediately manipulate the new element.
		flyingStarsCell.toFront({ deep: true });
		this.selection.collection.reset(flyingStarsCell);
		if (isMainChart) {
			this.setFlyingStarsElementIsPresent(true);
		}
		this.setFlyingStarsAdditionalElementIsPresent(true);
	}

	change8SectorsPieValues(
		newDistanceToCenter,
		newTextColorSittingFacing,
		newTextSizeSittingFacing,
		newRaiseUpSittingFacing,
		newTextColorBase,
		newTextSizeBase,
		newTextColorYearEnergy,
		newTextSizeYearEnergy,
		newTextFamily,
	) {
		const flyingStarsElement = this.graph
			.getCells()
			.filter((cell) => cell.id === FLYING_STARS_MAIN_GROUP_ID)[0];
		const pieChartElement = this.graph.getCells().filter((cell) => cell.id === PIE_CHART_ID)[0];
		let newCircleSize = null;
		if (flyingStarsElement != null) {
			const fcOffset = newRaiseUpSittingFacing / 100;
			CELESTIAL_DIRECTIONS.forEach((currentDirection, index) => {
				if (currentDirection.endsWith('2')) {
					const parsedDirection = currentDirection.slice(0, -1);
					const { coordinates, circleSize } = update8PieCircleElement(
						flyingStarsElement,
						pieChartElement,
						index,
						parsedDirection,
						newDistanceToCenter,
						newTextSizeSittingFacing,
						newTextSizeBase,
						newTextSizeYearEnergy,
					);
					newCircleSize = circleSize;
					const calculatedFCOffset = (circleSize / 2) * fcOffset;
					update8PieValue(
						flyingStarsElement,
						`${parsedDirection}Sitting`,
						coordinates.x - calculatedFCOffset,
						coordinates.y - calculatedFCOffset,
						newTextColorSittingFacing,
						newTextSizeSittingFacing,
						newTextFamily,
					);
					update8PieValue(
						flyingStarsElement,
						`${parsedDirection}Facing`,
						coordinates.x + calculatedFCOffset,
						coordinates.y - calculatedFCOffset,
						newTextColorSittingFacing,
						newTextSizeSittingFacing,
						newTextFamily,
					);
					update8PieValue(
						flyingStarsElement,
						`${parsedDirection}Base`,
						coordinates.x,
						coordinates.y,
						newTextColorBase,
						newTextSizeBase,
						newTextFamily,
					);
					update8PieValue(
						flyingStarsElement,
						`${parsedDirection}Year`,
						coordinates.x,
						coordinates.y + circleSize / 1.4,
						newTextColorYearEnergy,
						newTextSizeYearEnergy,
						newTextFamily,
					);
				}
			});
			if (newCircleSize != null) {
				this.setCircleRadius(newCircleSize);
			}
			flyingStarsElement.attr('custom/distanceToCenter', newDistanceToCenter);
			flyingStarsElement.attr('custom/facingSittingOffset', newRaiseUpSittingFacing);
		}
	}

	change8SectorCircleValues(newCircleColor, newCircleRadius) {
		const element = this.graph
			.getCells()
			.filter((cell) => cell.id === FLYING_STARS_MAIN_GROUP_ID)[0];
		if (element != null) {
			CELESTIAL_DIRECTIONS.forEach((currentDirection) => {
				if (currentDirection.endsWith('2')) {
					const parsedDirection = currentDirection.slice(0, -1);
					update8PieCircleElementColorAndSize(
						element,
						parsedDirection,
						newCircleColor,
						newCircleRadius,
					);
				}
			});
		}
	}

	change9RectanglesValues(
		newTextColorSittingFacing,
		newTextSizeSittingFacing,
		newRaiseUpSittingFacing,
		newTextColorBase,
		newTextSizeBase,
		newTextColorYearEnergy,
		newTextSizeYearEnergy,
		newTextFamily,
	) {
		const element = this.graph
			.getCells()
			.filter((cell) => cell.id === FLYING_STARS_SECONDARY_GROUP_ID)[0];
		if (element != null) {
			const fcOffset = newRaiseUpSittingFacing / 200;
			const rectWidth = element.attr('custom/rectWidth');
			const rectHeight = element.attr('custom/rectHeight');
			CELESTIAL_DIRECTIONS.concat(['mid2']).forEach((currentDirection) => {
				if (currentDirection.endsWith('2')) {
					const parsedDirection = currentDirection.slice(0, -1);
					const coordinates = {
						x: element.attr(`${parsedDirection}Rect/x`),
						y: element.attr(`${parsedDirection}Rect/y`),
					};
					update8PieValue(
						element,
						`${parsedDirection}Sitting`,
						coordinates.x + rectWidth * (0.55 - fcOffset),
						coordinates.y + rectHeight * (0.55 - fcOffset),
						newTextColorSittingFacing,
						newTextSizeSittingFacing,
						newTextFamily,
					);
					update8PieValue(
						element,
						`${parsedDirection}Facing`,
						coordinates.x + rectWidth * (0.45 + fcOffset),
						coordinates.y + rectHeight * (0.55 - fcOffset),
						newTextColorSittingFacing,
						newTextSizeSittingFacing,
						newTextFamily,
					);
					update8PieValue(
						element,
						`${parsedDirection}Base`,
						coordinates.x + rectWidth * 0.5,
						coordinates.y + rectHeight * 0.5,
						newTextColorBase,
						newTextSizeBase,
						newTextFamily,
					);
					update8PieValue(
						element,
						`${parsedDirection}Year`,
						coordinates.x + rectWidth * 0.5,
						coordinates.y + rectHeight * 0.9,
						newTextColorYearEnergy,
						newTextSizeYearEnergy,
						newTextFamily,
					);
				}
			});
			element.attr('custom/facingSittingOffset', newRaiseUpSittingFacing);
		}
	}
}

export default FlyingStarsService;
