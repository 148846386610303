import handleResponse from '../helpers/handle-response';
import authenticationService, { BACKEND_URL } from './authentication.service';

const stripeService = {
	openPortal,
	createCheckoutSession,
	verifyCheckoutSuccess,
};

function openPortal() {
	const currentUser = authenticationService.currentUserValue;
	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${currentUser.token}`,
		},
	};

	return fetch(`${BACKEND_URL}/payment/createPortalSession`, requestOptions)
		.then(handleResponse)
		.then(
			(result) => {
				if (result && result.data && result.data.url !== null && result.data.url !== '') {
					window.location.replace(result.data.url);
					return Promise.resolve();
				}
				return Promise.reject(new Error('Stripe URL is empty.'));
			},
			(result) => {
				return Promise.reject(result);
			},
		)
		.catch(() => {
			return Promise.reject();
		});
}

function createCheckoutSession(productLookupKey, paymentInterval, voucherCode) {
	const currentUser = authenticationService.currentUserValue;
	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${currentUser.token}`,
		},
	};

	return fetch(
		`${BACKEND_URL}/payment/createcheckout?productLookupKey=${productLookupKey}&paymentInterval=${paymentInterval}&voucher=${voucherCode}`,
		requestOptions,
	)
		.then(handleResponse)
		.then(
			(result) => {
				if (result && result.data && result.data.url !== null && result.data.url !== '') {
					window.location = result.data.url;
					return Promise.resolve();
				}
				return Promise.reject(new Error('Stripe URL is empty.'));
			},
			(result) => {
				return Promise.reject(result);
			},
		)
		.catch(() => {
			return Promise.reject();
		});
}

function verifyCheckoutSuccess() {
	const currentUser = authenticationService.currentUserValue;
	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${currentUser.token}`,
		},
	};

	return fetch(`${BACKEND_URL}/payment/checkoutSuccess`, requestOptions)
		.then(handleResponse)
		.then(
			(result) => {
				if (!result || result.status !== 200)
					return Promise.resolve({ status: result.status, newRole: null });

				const newUser = authenticationService.currentUserValue;
				newUser.role = result.data.newRole;
				newUser.token = result.data.token;
				localStorage.setItem('currentUser', JSON.stringify(newUser));
				authenticationService.setNext(newUser);

				return Promise.resolve({ statusCode: result.status, newRole: newUser.role });
			},
			(result) => {
				return Promise.reject(result);
			},
		)
		.catch(() => {
			return Promise.reject();
		});
}

export default stripeService;
