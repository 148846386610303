import React from 'react';
// eslint-disable-next-line camelcase
import { UNSAFE_NavigationContext } from 'react-router-dom';

function useBlocker(blocker, when = true) {
	const { navigator } = React.useContext(UNSAFE_NavigationContext);

	React.useEffect(() => {
		if (!when) return null;

		const unblock = navigator.block((tx) => {
			const autoUnblockingTx = {
				...tx,
				retry() {
					unblock();
					tx.retry();
				},
			};

			blocker(autoUnblockingTx);
		});

		return unblock;
	}, [navigator, blocker, when]);
}

export default useBlocker;
