// eslint-disable-next-line import/prefer-default-export
import { CELESTIAL_DIRECTIONS } from '../common/services/RappidServiceBase';

export function getPieChartCoordinates(
	pieChartKind,
	currentAngle,
	width,
	height,
	distanceToCenter = 1,
) {
	let a = width * 0.5 * distanceToCenter;
	let b = height * 0.5 * distanceToCenter;
	let x;
	let y;

	if (pieChartKind === 'R') {
		a = width * 0.5;
		b = height * 0.5;
		const gL = width * 0.5 * distanceToCenter;
		const gW = height * 0.5 * distanceToCenter;
		const mNinety = Math.PI / 2;
		let angleInDegree = currentAngle * (180 / Math.PI);

		while (angleInDegree > 360) {
			angleInDegree -= 360;
		}
		if (angleInDegree > 180) {
			angleInDegree = -180 + (angleInDegree - 180);
		}

		const rectangleAngle = angleInDegree * (Math.PI / 180);
		if (rectangleAngle === mNinety) {
			// x = O.x y = O.y+G_{w}
			x = a;
			y = b + gW;
		} else if (rectangleAngle === -mNinety) {
			// x = O.x y = O.y-G_{w}
			x = a;
			y = b - gW;
		} else if (Math.atan2(-gW, -gL) <= rectangleAngle && rectangleAngle < Math.atan2(-gW, gL)) {
			// pBottom(a) -> x = O.x-(G_{w}/tan(a)) y = O.y-G_{w}
			x = a - gW / Math.tan(rectangleAngle);
			y = b - gW;
		} else if (Math.atan2(-gW, gL) <= rectangleAngle && rectangleAngle < Math.atan2(gW, gL)) {
			// pRight(a) -> x = O.x+G_{l} y = O.y+G_{l} * tan(a)
			x = a + gL;
			y = b + gL * Math.tan(rectangleAngle);
		} else if (Math.atan2(gW, gL) <= rectangleAngle && rectangleAngle < Math.atan2(gW, -gL)) {
			// pTop(a) -> x = O.x+(G_{w}/tan(a) y = O.y+G_{w}
			x = a + gW / Math.tan(rectangleAngle);
			y = b + gW;
		} else {
			// pLeft(a) -> x = O.x-G_{l} y = O.y-G_{l} * tan(a)
			x = a - gL;
			y = b - gL * Math.tan(rectangleAngle);
		}
	} else {
		const ro =
			pieChartKind === 'E'
				? (a * b) /
				  Math.sqrt((b * Math.cos(currentAngle)) ** 2 + (a * Math.sin(currentAngle)) ** 2)
				: 1;

		const factor =
			pieChartKind === 'S'
				? Math.sqrt(1.0) /
				  Math.max(
						Math.abs(ro * Math.cos(currentAngle)),
						Math.abs(ro * Math.sin(currentAngle)),
				  )
				: 1;

		if (pieChartKind === 'E') {
			x = factor * ro * Math.cos(currentAngle) + width * 0.5;
			y = factor * ro * Math.sin(currentAngle) + height * 0.5;
		} else {
			x = factor * ro * Math.cos(currentAngle) * a + width * 0.5;
			y = factor * ro * Math.sin(currentAngle) * b + height * 0.5;
		}
	}

	return { x, y };
}

export function get24CelestialDirectionFromAngle(angle) {
	if ((angle >= 0 && angle < 7.5) || angle >= 352.6) {
		return 'n2';
	}

	let i = 1;
	let found = false;
	const lowerBorder = 7.5;
	const step = 15;
	while (!found && i < 24) {
		found = angle >= lowerBorder * i && angle < lowerBorder + step * i;
		i += 1;
	}

	if (i > 23) i = 0;

	return CELESTIAL_DIRECTIONS[i];
}

export function getAngleFrom24CelestialDirection(
	initialAngle,
	initialCelestialDirection,
	celestialDirection,
) {
	let result;
	const difference =
		CELESTIAL_DIRECTIONS.indexOf(celestialDirection) -
		CELESTIAL_DIRECTIONS.indexOf(initialCelestialDirection);

	if (difference > 0) {
		result = difference * 15 + initialAngle;
	} else {
		// Difference < 0
		result = (CELESTIAL_DIRECTIONS.length + difference) * 15 + initialAngle - 360;
	}

	if (result > 360) {
		return result - 360;
	}
	if (result < 0) {
		return 360 + result;
	}
	return result;
}

export function getAngleForDirectionFromCustomAngle(angle, direction) {
	let factor;
	switch (direction) {
		case 'L':
			factor = 135;
			break;
		case 'T':
			factor = 225;
			break;
		case 'R':
			factor = -45;
			break;
		case 'B':
		default:
			factor = 45;
			break;
	}
	return 360 - angle + factor > 360 ? 360 - angle + factor - 360 : 360 - angle + factor;
}
