import React, { useState } from 'react';
import { useEffectOnce } from 'react-use';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Card, { CardBody } from '../../components/bootstrap/Card';
import Page from '../../layout/Page/Page';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Logo from '../../components/Logo';
import Button from '../../components/bootstrap/Button';
import Spinner from '../../components/bootstrap/Spinner';
import showNotification from '../../components/extras/showNotification';
import Icon from '../../components/icon/Icon';
import pages from '../../menu';
import authenticationService from '../../services/authentication.service';
import userService from '../../services/user.service';

const ConfirmEmailPage = () => {
	const [eMail, setEMail] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [allParamsAvailable, setAllParamsAvailable] = useState(true);
	const { t } = useTranslation('login');

	useEffectOnce(() => {
		const query = new URLSearchParams(window.location.search);

		const tempToken = query.get('token');
		const tempEMail = query.get('email');
		setEMail(tempEMail);

		setAllParamsAvailable(
			tempToken != null && tempToken !== '' && tempEMail != null && tempEMail !== '',
		);

		if (tempToken != null && tempToken !== '' && tempEMail != null && tempEMail !== '') {
			setIsLoading(true);
			authenticationService.confirmEmail(tempEMail, tempToken).then(
				() => {
					setIsLoading(false);
				},
				() => {
					// TODO Fehlermeldung?
					setIsLoading(false);
				},
			);
		}
	});

	const handleClick = () => {
		setIsLoading(true);
		userService.initConfirmEmail(eMail).then(
			() => {
				showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon='Info' size='lg' className='me-1' />
						<span>{t('Confirmation email sent')}</span>
					</span>,
					t('The confirmation email has been sent.'),
				);
				setIsLoading(false);
			},
			(err) => {
				if (err != null && err.errors == null && err[0] === 'E-Mail is already confirmed') {
					showNotification(
						<span className='d-flex align-items-center'>
							<Icon icon='Error' size='lg' className='me-1' />
							<span>{t('Email is already confirmed')}</span>
						</span>,
						t(
							'Your email address is already confirmed, try and logout and login again, if you still get the warning.',
						),
						'danger',
					);
				} else {
					showNotification(
						<span className='d-flex align-items-center'>
							<Icon icon='Error' size='lg' className='me-1' />
							<span>{t('Confirmation email not sent')}</span>
						</span>,
						t(
							'The confirmation email could not been sent to you, please refresh the page and try again.',
						),
						'danger',
					);
				}
				setIsLoading(false);
			},
		);
	};

	return (
		<PageWrapper title={t(pages.confirmEmail.text)} className='bg-info'>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='shadow-3d-container col-xl-4 col-lg-6 col-md-8'>
						<Card className='shadow-3d-dark'>
							<CardBody>
								<div className='text-center my-5'>
									<Link
										to='/'
										className='text-decoration-none  fw-bold display-2 text-dark'>
										<Logo width={220} />
									</Link>
								</div>

								{!allParamsAvailable && (
									<>
										<div className='text-center h1 fw-bold mt-5'>
											{t('Registration successful')}
										</div>
										<div className='text-center h4 text-muted mb-5'>
											<p>{t('We have sent a confirmation email to')}</p>
											<p>
												<b>{eMail}</b>
											</p>
											<p>
												{t(
													'After confirming your email you are able to use all the functionalities of the app.',
												)}
											</p>
										</div>

										<div className='row g-4'>
											<div className='col-12'>
												<Button
													onClick={handleClick}
													isDisable={isLoading || eMail == null}
													type='submit'
													color='info'
													className='w-100 py-3'>
													{isLoading && (
														<Spinner color='light' inButton isSmall />
													)}
													{isLoading
														? t('Loading...')
														: t('Resend Confirmation Email')}
												</Button>
											</div>
										</div>
									</>
								)}

								{allParamsAvailable && (
									<>
										{!isLoading && (
											<>
												<div className='text-center h1 fw-bold mt-5'>
													{t('Confirmation successful')}
												</div>
												<div className='text-center h4 text-muted mb-5'>
													<p>
														{t(
															'Your email has been successfully confirmed.',
														)}
													</p>
													<p>
														{t(
															'Return to the dashboard and select your plan.',
														)}
													</p>
												</div>
											</>
										)}
										<div className='row g-4'>
											<div className='col-12'>
												<Button
													tag='a'
													to={pages.dashboard.path}
													isDisable={isLoading}
													color='info'
													className='w-100 py-3'>
													{isLoading && (
														<Spinner color='light' inButton isSmall />
													)}
													{isLoading ? t('Loading...') : t('To Login')}
												</Button>
											</div>
										</div>
									</>
								)}
							</CardBody>
						</Card>
						<div className='text-center'>
							<a
								href='https://wowgenerator.de/privacy-policy/'
								target='_blank'
								rel='noreferrer'
								className='text-decoration-none me-3 link-light'>
								{t('Privacy policy')}
							</a>
							<a
								href='https://wowgenerator.de/terms-of-use/'
								target='_blank'
								rel='noreferrer'
								className='link-light text-decoration-none link-light'>
								{t('Terms of use')}
							</a>
						</div>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default ConfirmEmailPage;
