import React, { useContext, useEffect, useLayoutEffect, useRef } from 'react';
import { ThemeProvider } from 'react-jss';
import ReactNotification from 'react-notifications-component';
import { useFullscreen } from 'react-use';
import { Route, Routes, useSearchParams } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import ThemeContext from '../contexts/themeContext';
import pages from '../menu';

import Aside from '../layout/Aside/Aside';
import Wrapper from '../layout/Wrapper/Wrapper';
import Portal from '../layout/Portal/Portal';

import { Toast, ToastContainer } from '../components/bootstrap/Toasts';
import COLORS from '../common/data/enumColors';
import { getOS } from '../helpers/helpers';
// eslint-disable-next-line import/no-named-as-default
import UserContextProvider from '../contexts/userContext';

const App = () => {
	getOS();
	const theme = {
		theme: 'light',
		primary: COLORS.PRIMARY.code,
		secondary: COLORS.SECONDARY.code,
		success: COLORS.SUCCESS.code,
		info: COLORS.INFO.code,
		warning: COLORS.WARNING.code,
		danger: COLORS.DANGER.code,
		dark: COLORS.DARK.code,
		light: COLORS.LIGHT.code,
	};

	/**
	 * Full Screen
	 */
	const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
	const ref = useRef(null);
	useFullscreen(ref, fullScreenStatus, {
		onClose: () => setFullScreenStatus(false),
	});

	/**
	 * Modern Design
	 */
	useLayoutEffect(() => {
		if (process.env.REACT_APP_MODERN_DESGIN === 'true') {
			document.body.classList.add('modern-design');
		} else {
			document.body.classList.remove('modern-design');
		}
	});
	/**
	 * Search Params
	 */
	const [searchParams] = useSearchParams();
	useEffect(() => {
		if (searchParams.has('voucher')) {
			localStorage.setItem('stripeVoucherCode', searchParams.get('voucher'));
		}
	}, [searchParams]);

	//	Add paths to the array that you don't want to be "Aside".
	const withoutAsidePages = [
		pages.login.path,
		pages.signUp.path,
		pages.resetPassword.path,
		pages.resetPasswordConfirm.path,
		pages.confirmEmail.path,
		pages.changeEmailConfirm.path,
	];

	return (
		<UserContextProvider>
			<ThemeProvider theme={theme}>
				<ToastProvider components={{ ToastContainer, Toast }}>
					<div
						ref={ref}
						className='app'
						style={{
							backgroundColor: fullScreenStatus && 'var(--bs-body-bg)',
							zIndex: fullScreenStatus && 1,
							overflow: fullScreenStatus && 'scroll',
						}}>
						<Routes>
							{withoutAsidePages.map((path) => (
								<Route key={path} path={path} />
							))}
							<Route path='*' element={<Aside />} />
						</Routes>
						<Wrapper />
					</div>
					<Portal id='portal-notification'>
						<ReactNotification />
					</Portal>
				</ToastProvider>
			</ThemeProvider>
		</UserContextProvider>
	);
};

export default App;
