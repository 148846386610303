import { ui } from '@clientio/rappid';

class KeyboardServiceBase {
	constructor() {
		this.keyboard = new ui.Keyboard();
	}

	create(graph, clipboard, selection, paperScroller) {
		this.keyboard.on({
			'delete backspace': (evt) => {
				evt.preventDefault();
				graph.removeCells(selection.collection.toArray());
			},

			// Wurde in Ticket #82 erst einmal ausgeblendet
			// 'ctrl+z meta+z': () => {
			// 	performUndo();
			// 	selection.cancelSelection();
			// },

			// 'ctrl+y meta+y': () => {
			// 	performRedo();
			// 	selection.cancelSelection();
			// },

			'ctrl+a meta+a': (evt) => {
				evt.preventDefault();
				selection.collection.reset(graph.getElements());
			},

			'ctrl+plus meta+plus': (evt) => {
				evt.preventDefault();
				paperScroller.zoom(0.2, { max: 5, grid: 0.2 });
			},

			'ctrl+minus meta+minus': (evt) => {
				evt.preventDefault();
				paperScroller.zoom(-0.2, { min: 0.2, grid: 0.2 });
			},

			'keydown:shift': () => {
				paperScroller.setCursor('crosshair');
			},

			'keyup:shift': () => {
				paperScroller.setCursor('grab');
			},
		});
	}
}

export default KeyboardServiceBase;
