import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Card, { CardBody } from '../../components/bootstrap/Card';
import Page from '../../layout/Page/Page';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Logo from '../../components/Logo';
import Button from '../../components/bootstrap/Button';
import Spinner from '../../components/bootstrap/Spinner';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import Input from '../../components/bootstrap/forms/Input';
import showNotification from '../../components/extras/showNotification';
import Icon from '../../components/icon/Icon';
import authenticationService from '../../services/authentication.service';
import pages from '../../menu';
import PasswordInput from '../../components/PasswordInput';
import LanguageSelector from '../../components/LanguageSelector';
import Checks from '../../components/bootstrap/forms/Checks';
import Role from '../../common/data/role';

// eslint-disable-next-line react/prop-types
const LoginHeader = ({ isNewUser }) => {
	const { t } = useTranslation('login');
	if (isNewUser) {
		return (
			<>
				<div className='text-center h1 fw-bold mt-5'>{t('Create Account')}</div>
				<div className='text-center h4 text-muted mb-5'>{t('Sign up to get started!')}</div>
			</>
		);
	}
	return (
		<>
			<div className='text-center h1 fw-bold mt-5'>{t('Welcome')}</div>
			<div className='text-center h4 text-muted mb-5'>{t('Sign in to continue!')}</div>
		</>
	);
};

const constructor = (navigate) => {
	// redirect to home if already logged in
	if (authenticationService.currentUserValue) {
		navigate('/');
	}
};

const LoginPage = ({ isSignUp, fromPage }) => {
	const [isNewUser, setIsNewUser] = useState(isSignUp);
	const [isLoading, setIsLoading] = useState(false);

	const { t: tg } = useTranslation('general');
	const { t } = useTranslation('login');
	const navigate = useNavigate();

	// #region Validation
	const validateRegister = (values) => {
		const errors = {};
		if (!values.signupEmailAddress) {
			errors.signupEmailAddress = tg('Required');
		} else if (!/^[A-Z\d._%+-]+@[A-Z0\d-]+\.[A-Z]+$/i.test(values.signupEmailAddress)) {
			errors.signupEmailAddress = t('Invalid email address');
		}

		if (!values.signupPassword) {
			errors.signupPassword = tg('Required');
		} else {
			errors.signupPassword = '';

			if (values.signupPassword.length < 8 || values.signupPassword.length > 32) {
				errors.signupPassword += t(
					'The password must be at least 8 characters long, but no more than 32. ',
				);
			}
			if (!/\d/g.test(values.signupPassword)) {
				errors.signupPassword += t('Requires at least one digit. ');
			}
			if (!/[a-z]/g.test(values.signupPassword)) {
				errors.signupPassword += t('Requires at least one lowercase letter. ');
			}
			if (!/[A-Z]/g.test(values.signupPassword)) {
				errors.signupPassword += t('Requires at least one uppercase letter. ');
			}
			if (!/[!@#$%^&*)(+=._-]/g.test(values.signupPassword)) {
				errors.signupPassword += t('Requires at least one special character. ');
			}
		}

		if (errors.signupPassword === '') {
			delete errors.signupPassword;
		}

		if (!values.signupConfirmationPassword) {
			errors.signupConfirmationPassword = tg('Required');
		} else if (values.signupPassword !== values.signupConfirmationPassword) {
			errors.signupConfirmationPassword = t('Password need to match');
		}

		if (!values.signupFirstName) {
			errors.signupFirstName = tg('Required');
		}

		if (!values.signupLastName) {
			errors.signupLastName = tg('Required');
		}

		if (!values.termsOfUse) {
			errors.termsOfUse = tg('Required');
		}

		return errors;
	};

	const validateLogin = (values) => {
		const errors = {};
		if (!values.loginEmailAddress) {
			errors.loginEmailAddress = tg('Required');
		} else if (!/^[A-Z\d._%+-]+@[A-Z0\d-]+\.[A-Z]+$/i.test(values.loginEmailAddress)) {
			errors.loginEmailAddress = t('Invalid email address');
		}

		if (!values.loginPassword) {
			errors.loginPassword = tg('Required');
		}

		return errors;
	};
	// #endregion

	const formikRegister = useFormik({
		initialValues: {
			signupEmailAddress: '',
			signupPassword: '',
			signupConfirmationPassword: '',

			signupFirstName: '',
			signupLastName: '',

			termsOfUse: false,
		},
		validate: validateRegister,
		onSubmit: (values) => {
			setIsLoading(true);
			authenticationService
				.register(
					values.signupEmailAddress,
					values.signupPassword,
					values.signupConfirmationPassword,
					values.signupFirstName,
					values.signupLastName,
				)
				.then(
					() => {
						showNotification(
							<span className='d-flex align-items-center'>
								<Icon icon='Info' size='lg' className='me-1' />
								<span>{t('Registration successful')}</span>
							</span>,
							t(
								'Please confirm your email in order to purchase a subscription within the app.',
							),
						);
						navigate({
							pathname: pages.confirmEmail.path,
							search: `?email=${encodeURI(values.signupEmailAddress)}`,
						});
						setIsNewUser(false);
						setIsLoading(false);
						formikRegister.resetForm();
						formikLogin.resetForm();
					},
					(error) => {
						const emailError = error?.errors?.Email;
						setIsLoading(false);
						showNotification(
							<span className='d-flex align-items-center'>
								<Icon icon='Error' size='lg' className='me-1' />
								<span>{t('Registration not successful')}</span>
							</span>,
							emailError !== null
								? t(emailError[0])
								: t('Try again with different data.'),
							'danger',
						);
					},
				);
		},
	});

	const formikLogin = useFormik({
		initialValues: {
			loginEmailAddress: '',
			loginPassword: '',
		},
		validate: validateLogin,
		onSubmit: (values) => {
			setIsLoading(true);
			authenticationService.login(values.loginEmailAddress, values.loginPassword).then(
				(user) => {
					if (user.role === Role.None) {
						navigate({
							pathname: pages.startWOWFree.path,
						});
					} else {
						// TODO Wenn mir langweilig ist die richtige vorherige Seite bekommen.
						navigate(fromPage);
					}
					setIsLoading(false);
				},
				() => {
					setIsLoading(false);
					showNotification(
						<span className='d-flex align-items-center'>
							<Icon icon='Error' size='lg' className='me-1' />
							<span>{t('Login not successful')}</span>
						</span>,
						t('Email or password is incorrect.'),
						'danger',
					);
				},
			);
		},
	});

	constructor(navigate);

	return (
		<PageWrapper title={isNewUser ? t('Sign Up') : t('Login')} className='bg-warning'>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div
						className={classNames('shadow-3d-container', {
							'col-xl-4 col-lg-6 col-md-8': !isNewUser,
							'col-lg-6 col-md-8': isNewUser,
						})}>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div
									className='text-center my-4'
									style={{ marginTop: '0 !important' }}>
									<Link
										to='/'
										className='text-decoration-none  fw-bold display-2 text-dark'>
										<Logo width={220} />
									</Link>
								</div>
								<div className='rounded-3 bg-l10-dark'>
									<div className='row row-cols-2 g-3 pb-3 px-3 mt-0'>
										<div className='col'>
											<Button
												isDisable={isLoading}
												color='dark'
												isLight={!!isNewUser}
												className='rounded-1 w-100'
												size='lg'
												onClick={() => {
													setIsNewUser(!isNewUser);
													formikRegister.resetForm();
													formikLogin.resetForm();
												}}>
												{t('Login')}
											</Button>
										</div>
										<div className='col'>
											<Button
												isDisable={isLoading}
												color='dark'
												isLight={!isNewUser}
												className='rounded-1 w-100'
												size='lg'
												onClick={() => {
													setIsNewUser(!isNewUser);
													formikRegister.resetForm();
													formikLogin.resetForm();
												}}>
												{t('Sign Up')}
											</Button>
										</div>
									</div>
								</div>

								<LoginHeader isNewUser={isNewUser} />

								{isNewUser ? (
									<form
										className='row g-4'
										onSubmit={formikRegister.handleSubmit}>
										<div className='col-6'>
											<FormGroup
												id='signupFirstName'
												isFloating
												label={t('Name')}>
												<Input
													autoComplete='given-name'
													onChange={formikRegister.handleChange}
													onBlur={formikRegister.handleBlur}
													value={formikRegister.values.signupFirstName}
													isValid={formikRegister.isValid}
													isTouched={
														formikRegister.touched.signupFirstName
													}
													invalidFeedback={
														formikRegister.errors.signupFirstName
													}
												/>
											</FormGroup>
										</div>
										<div className='col-6'>
											<FormGroup
												id='signupLastName'
												isFloating
												label={t('Surname')}>
												<Input
													autoComplete='family-name'
													onChange={formikRegister.handleChange}
													onBlur={formikRegister.handleBlur}
													value={formikRegister.values.signupLastName}
													isValid={formikRegister.isValid}
													isTouched={
														formikRegister.touched.signupLastName
													}
													invalidFeedback={
														formikRegister.errors.signupLastName
													}
												/>
											</FormGroup>
										</div>
										<div className='col-12'>
											<FormGroup
												id='signupEmailAddress'
												isFloating
												label={t('Email')}>
												<Input
													type='email'
													autoComplete='email'
													onChange={formikRegister.handleChange}
													onBlur={formikRegister.handleBlur}
													value={formikRegister.values.signupEmailAddress}
													isValid={formikRegister.isValid}
													isTouched={
														formikRegister.touched.signupEmailAddress
													}
													invalidFeedback={
														formikRegister.errors.signupEmailAddress
													}
												/>
											</FormGroup>
										</div>
										<div className='col-12'>
											<FormGroup
												id='signupPassword'
												isFloating
												label={t('Password')}>
												<PasswordInput
													autoComplete='password'
													onChange={formikRegister.handleChange}
													onBlur={formikRegister.handleBlur}
													value={formikRegister.values.signupPassword}
													isValid={formikRegister.isValid}
													isTouched={
														formikRegister.touched.signupPassword
													}
													invalidFeedback={
														formikRegister.errors.signupPassword
													}
												/>
											</FormGroup>
										</div>
										<div className='col-12'>
											<FormGroup
												id='signupConfirmationPassword'
												isFloating
												label={t('Confirm your password')}>
												<PasswordInput
													autoComplete='password'
													onChange={formikRegister.handleChange}
													onBlur={formikRegister.handleBlur}
													value={
														formikRegister.values
															.signupConfirmationPassword
													}
													isValid={formikRegister.isValid}
													isTouched={
														formikRegister.touched
															.signupConfirmationPassword
													}
													invalidFeedback={
														formikRegister.errors
															.signupConfirmationPassword
													}
												/>
											</FormGroup>
										</div>
										<div className='col-12'>
											<FormGroup id='signupTermsOfUse' isFloating>
												<Checks
													type='checkbox'
													label={
														<span>
															{t('I accept the ')}
															<a
																href='https://wowgenerator.de/privacy-policy/'
																target='_blank'
																rel='noreferrer'>
																{t('Privacy policy')}
															</a>
															{t(' and ')}
															<a
																href='https://wowgenerator.de/terms-of-use/'
																target='_blank'
																rel='noreferrer'>
																{t('Terms of use')}.
															</a>
														</span>
													}
													name='termsOfUse'
													onChange={formikRegister.handleChange}
													checked={formikRegister.values.termsOfUse}
													isValid={formikRegister.isValid}
													isTouched={formikRegister.touched.termsOfUse}
													invalidFeedback={
														formikRegister.errors.termsOfUse
													}
												/>
											</FormGroup>
										</div>
										<div className='col-12'>
											<Button
												isDisable={isLoading}
												type='submit'
												color='warning'
												className='w-100 py-3'>
												{isLoading && (
													<Spinner color='light' inButton isSmall />
												)}
												{isLoading ? t('Loading...') : t('Sign Up')}
											</Button>
										</div>
										{/*
											<SocialLogin
												isLoading={isLoading}
												setIsLoading={setIsLoading}
											/> */}
									</form>
								) : (
									<form className='row g-4' onSubmit={formikLogin.handleSubmit}>
										<div className='col-12'>
											<FormGroup
												id='loginEmailAddress'
												isFloating
												label={t('Email')}>
												<Input
													type='email'
													autoComplete='email'
													onChange={formikLogin.handleChange}
													onBlur={formikLogin.handleBlur}
													value={formikLogin.values.loginEmailAddress}
													isValid={formikLogin.isValid}
													isTouched={
														formikLogin.touched.loginEmailAddress
													}
													invalidFeedback={
														formikLogin.errors.loginEmailAddress
													}
												/>
											</FormGroup>
										</div>
										<div className='col-12'>
											<FormGroup
												id='loginPassword'
												isFloating
												label={t('Password')}>
												<PasswordInput
													autoComplete='password'
													onChange={formikLogin.handleChange}
													onBlur={formikLogin.handleBlur}
													value={formikLogin.values.loginPassword}
													isValid={formikLogin.isValid}
													isTouched={formikLogin.touched.loginPassword}
													invalidFeedback={
														formikLogin.errors.loginPassword
													}
												/>
											</FormGroup>
										</div>
										<div className='col-12'>
											<Button
												isDisable={isLoading}
												type='submit'
												color='warning'
												className='w-100 py-3'>
												{isLoading && (
													<Spinner color='dark' inButton isSmall />
												)}
												{isLoading ? t('Loading...') : t('Login')}
											</Button>
										</div>
										{/*
											<SocialLogin
												isLoading={isLoading}
												setIsLoading={setIsLoading}
											/> */}
										<div className='col-12 border-bottom' />
										<div className='col-12' style={{ marginTop: 0 }}>
											<Button
												tag='a'
												to={pages.resetPassword.path}
												color='link'
												className='w-100 py-3'>
												{t('Forgot Password?')}
											</Button>
										</div>
									</form>
								)}

								<div className='text-center'>
									<LanguageSelector />
								</div>
							</CardBody>
						</Card>
						<div className='text-center'>
							<a
								href='https://wowgenerator.de/privacy-policy/'
								target='_blank'
								rel='noreferrer'
								className={classNames('text-decoration-none me-3', {
									'link-light': isNewUser,
									'link-dark': !isNewUser,
								})}>
								{t('Privacy policy')}
							</a>
							<a
								href='https://wowgenerator.de/terms-of-use/'
								target='_blank'
								rel='noreferrer'
								className={classNames('link-light text-decoration-none', {
									'link-light': isNewUser,
									'link-dark': !isNewUser,
								})}>
								{t('Terms of use')}
							</a>
						</div>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

LoginPage.propTypes = {
	isSignUp: PropTypes.bool,
	fromPage: PropTypes.string,
};
LoginPage.defaultProps = {
	isSignUp: false,
	fromPage: '/',
};

export default LoginPage;
