const PRODUCTS = [
	{
		name: 'Start WOW',
		type: 'yearly',
		color: 'primary',
		icon: 'CircleSquare',
		price: '120',
		vat: '22,80',
		lookupKey: 'fengshui_start',
		features: {
			sectorsPie: true,
			celestialDirections: true,
			flyingStars: false,
			fiveElements: false,
			configurator: false,
			eastWestGroups: false,
			guaSector: false,
			guaDirection: false,
			pieFurniture: false,
			adaptionFurniturePlan: false,
		},
		enabled: true,
	},
	{
		name: 'Fly WOW',
		type: 'yearly',
		color: 'secondary',
		icon: 'MoonStars',
		price: '290',
		vat: '55,10',
		lookupKey: 'fengshui_fly',
		features: {
			sectorsPie: true,
			celestialDirections: true,
			flyingStars: true,
			fiveElements: false,
			configurator: false,
			eastWestGroups: false,
			guaSector: false,
			guaDirection: false,
			pieFurniture: false,
			adaptionFurniturePlan: false,
		},
		enabled: true,
	},
	{
		name: 'Place WOW',
		type: 'yearly',
		color: 'info',
		icon: 'Grid1X2',
		price: '480',
		vat: '91,20',
		lookupKey: 'fengshui_place',
		features: {
			sectorsPie: true,
			celestialDirections: true,
			flyingStars: true,
			fiveElements: true,
			configurator: true,
			eastWestGroups: true,
			guaSector: true,
			guaDirection: false,
			pieFurniture: false,
			adaptionFurniturePlan: false,
		},
		enabled: false,
	},
	{
		name: 'View WOW',
		type: 'yearly',
		color: 'warning',
		icon: 'Chair',
		price: '720',
		vat: '136,80',
		lookupKey: 'fengshui_view',
		features: {
			sectorsPie: true,
			celestialDirections: true,
			flyingStars: true,
			fiveElements: true,
			configurator: true,
			eastWestGroups: true,
			guaSector: true,
			guaDirection: true,
			pieFurniture: true,
			adaptionFurniturePlan: true,
		},
		enabled: false,
	},
	{
		name: 'Start WOW',
		type: 'monthly',
		color: 'primary',
		icon: 'CircleSquare',
		price: '12',
		vat: '2,28',
		lookupKey: 'fengshui_start',
		features: {
			sectorsPie: true,
			celestialDirections: true,
			flyingStars: false,
			fiveElements: false,
			configurator: false,
			eastWestGroups: false,
			guaSector: false,
			guaDirection: false,
			pieFurniture: false,
			adaptionFurniturePlan: false,
		},
		enabled: true,
	},
	{
		name: 'Fly WOW',
		type: 'monthly',
		color: 'secondary',
		icon: 'MoonStars',
		price: '29',
		vat: '5,51',
		lookupKey: 'fengshui_fly',
		features: {
			sectorsPie: true,
			celestialDirections: true,
			flyingStars: true,
			fiveElements: false,
			configurator: false,
			eastWestGroups: false,
			guaSector: false,
			guaDirection: false,
			pieFurniture: false,
			adaptionFurniturePlan: false,
		},
		enabled: true,
	},
	{
		name: 'Place WOW',
		type: 'monthly',
		color: 'info',
		icon: 'Grid1X2',
		price: '48',
		vat: '9,12',
		lookupKey: 'fengshui_place',
		features: {
			sectorsPie: true,
			celestialDirections: true,
			flyingStars: true,
			fiveElements: true,
			configurator: true,
			eastWestGroups: true,
			guaSector: true,
			guaDirection: false,
			pieFurniture: false,
			adaptionFurniturePlan: false,
		},
		enabled: false,
	},
	{
		name: 'View WOW',
		type: 'monthly',
		color: 'warning',
		icon: 'Chair',
		price: '72',
		vat: '13,68',
		lookupKey: 'fengshui_view',
		features: {
			sectorsPie: true,
			celestialDirections: true,
			flyingStars: true,
			fiveElements: true,
			configurator: true,
			eastWestGroups: true,
			guaSector: true,
			guaDirection: true,
			pieFurniture: true,
			adaptionFurniturePlan: true,
		},
		enabled: false,
	},
];

export default PRODUCTS;
