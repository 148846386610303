import { TinyColor } from '@ctrl/tinycolor';
import PieChartService from '../services/PieChartService';
import { PIE_CHART_ID } from '../../../../common/data/selectorIDs';
import { getPieChartCoordinates } from '../../../../helpers/pie-chart-helpers';
import i18n from '../../../../i18n';

export function updatePiePaths(element, is24Pie, pieChartKind, currentSize, customAngle) {
	PieChartService.get8Or24PiePath(
		false,
		pieChartKind,
		currentSize.width,
		currentSize.height,
		customAngle,
	).forEach((coordinates, index) => {
		element.attr(`l8${index}/points`, coordinates);
	});

	PieChartService.get8Or24PiePath(
		true,
		pieChartKind,
		currentSize.width,
		currentSize.height,
		customAngle,
	).forEach((coordinates, index) => {
		element.attr(`l24${index}/points`, is24Pie === '1' ? coordinates : '0,0');
	});
}

export function updateCelestialDirections(
	element,
	direction,
	angle,
	index,
	size,
	newDistanceToCenter,
	is24Pie,
	textColor,
) {
	const { width, height } = size;
	const newIndex = index + 2 + (angle / 360) * 24;
	const currentAngle = (newIndex * 2 * Math.PI) / 24;
	const currentUseCelestialDirections = element.attr('custom/useCelestialDirections');
	const pieChartKind = element.attr('custom/pieChartKind');
	const coordinates = getPieChartCoordinates(
		pieChartKind,
		currentAngle,
		width,
		height,
		newDistanceToCenter,
	);

	const colorIsNotNone =
		currentUseCelestialDirections === '1' && (direction.endsWith('2') || is24Pie === '1');

	element.attr(`${direction}/x`, coordinates.x);
	element.attr(`${direction}/y`, coordinates.y);
	element.attr(`${direction}/fill`, colorIsNotNone ? textColor : 'none');
	element.attr(
		`${direction}/text`,
		direction.endsWith('2') && is24Pie === '0'
			? i18n.t(direction.slice(0, -1).toUpperCase(), { ns: 'general' })
			: i18n.t(direction.slice(0, -1).toUpperCase(), { ns: 'general' }) + direction.slice(-1),
	);
	element.attr(
		`${direction}/style`,
		`fill:${colorIsNotNone ? new TinyColor(textColor).toRgbString() : 'none'};opacity:${
			colorIsNotNone ? new TinyColor(textColor).getAlpha() : 0
		};font-family:${element.attr(`${direction}/fontFamily`)};font-size:${element.attr(
			`${direction}/fontSize`,
		)}px;`,
	);
}

export function updateCelestialDirectionsTextValues(
	primaryCell,
	direction,
	textColor,
	textSize,
	textFamily,
	useCelestialDirections,
	is24Pie,
) {
	const colorIsNotNone =
		useCelestialDirections === '1' && (direction.endsWith('2') || is24Pie === '1');
	primaryCell.attr(`${direction}/fill`, colorIsNotNone ? textColor : 'none');
	primaryCell.attr(`${direction}/fontSize`, textSize);
	primaryCell.attr(`${direction}/fontFamily`, textFamily);
	primaryCell.attr(
		`${direction}/style`,
		`fill:${colorIsNotNone ? new TinyColor(textColor).toRgbString() : 'none'};opacity:${
			colorIsNotNone ? new TinyColor(textColor).getAlpha() : 0
		};font-family:${textFamily};font-size:${textSize}px;`,
	);
}

export function selectionIsPieChart(cellView) {
	const cell = cellView.model;
	return cell.isElement() && cell.id === PIE_CHART_ID;
}
