import React from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import { useMeasure } from 'react-use';
import { isWebpSupported } from 'react-image-webp/dist/utils';
import { useTranslation } from 'react-i18next';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import DashboardImage from '../../assets/img/dashboard/Focus_Feng_Shui.png';
import DashboardImageWebp from '../../assets/img/dashboard/Focus_Feng_Shui.webp';
import Page from '../../layout/Page/Page';
import Logo from '../../components/Logo';

const useStyles = createUseStyles({
	// stylelint-disable
	intro: {
		zIndex: 200,
		overflow: 'hidden',
	},
	// stylelint-enable
});

const DashboardPage = () => {
	const { t } = useTranslation('dashboard');
	const classes = useStyles();
	const [ref] = useMeasure();

	return (
		<PageWrapper ref={ref} title='Feng Shui WOW Gènerator'>
			<Page>
				<div id='intro' className='scroll-margin'>
					<div className={classNames('row', 'h-fluid-min-100', classes.intro)}>
						<div
							className={classNames(
								'col-lg-6',
								'col-12',
								'd-flex',
								'align-items-center',
								'justify-content-center',
							)}>
							<div>
								<img
									style={{ width: '90%' }}
									alt='Feng Shui Team'
									src={isWebpSupported() ? DashboardImageWebp : DashboardImage}
								/>
							</div>
						</div>
						<div
							className={classNames(
								'col-lg-6',
								'col-12',
								'd-flex',
								'align-items-center',
								'justify-content-center',
							)}>
							<div>
								<h1 className='display-1 mb-4'>
									{t('Welcome to')} <Logo height={70} />
								</h1>
								<p className='display-6 mb-4'>
									{t('Everything is designed to make it easy.')}
								</p>
								<p className='display-6 mb-4'>
									{t('Take your coffee and focus on your Feng Shui.')}
								</p>
							</div>
						</div>
					</div>
					<div className={classNames('row', 'h-fluid-min-100', classes.intro)}>
						<div
							className={classNames(
								'col-12',
								'd-flex',
								'align-items-center',
								'justify-content-center',
							)}>
							<iframe
								style={{
									width: '50vw',
									height: '25vw',
									marginTop: 'calc(var(--bs-gutter-x) * 2)',
								}}
								src='https://www.youtube.com/embed/glb_ksRC0H0'
								title='WOW Gènerator Schnellstart'
								allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
								allowFullScreen
							/>
						</div>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default DashboardPage;
