import React from 'react';
import { useTranslation } from 'react-i18next';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from './bootstrap/Dropdown';
import Button from './bootstrap/Button';
import LANG, { getLangWithKey } from '../lang';
import showNotification from './extras/showNotification';
import Icon from './icon/Icon';

// eslint-disable-next-line react/prop-types
const LanguageSelector = ({ isUserMenu }) => {
	const styledBtn = isUserMenu
		? {}
		: {
				color: 'light',
				hoverShadow: 'default',
				isLight: true,
				size: 'lg',
		  };

	const { i18n } = useTranslation();
	const { t } = useTranslation('menu');

	const changeLanguage = (lng) => {
		i18n.changeLanguage(lng);
		showNotification(
			<span className='d-flex align-items-center'>
				<Icon icon={getLangWithKey(lng)?.icon} size='lg' className='me-1' />
				<span>{`${t('Language changed to')} ${getLangWithKey(lng)?.text}`}</span>
			</span>,
			t('You updated the language of the site.'),
		);
	};

	return (
		<Dropdown>
			<DropdownToggle hasIcon={false}>
				<Button
					// eslint-disable-next-line react/jsx-props-no-spreading
					{...styledBtn}
					icon={getLangWithKey(i18n.resolvedLanguage)?.icon}
					aria-label='Change language'
					data-tour='lang-selector'
					className={isUserMenu ? 'dropdown-item' : ''}>
					{isUserMenu ? getLangWithKey(i18n.language)?.text : null}
				</Button>
			</DropdownToggle>
			<DropdownMenu isAlignmentEnd data-tour='lang-selector-menu'>
				{Object.keys(LANG).map((i) => (
					<DropdownItem key={LANG[i].lng}>
						<Button icon={LANG[i].icon} onClick={() => changeLanguage(LANG[i].lng)}>
							{LANG[i].text}
						</Button>
					</DropdownItem>
				))}
			</DropdownMenu>
		</Dropdown>
	);
};

export default LanguageSelector;
