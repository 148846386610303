import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import rgbObjectToRGBString from '../../../../helpers/color-helpers';
import { getFontFamilyObjectByValue } from '../../../../components/extras/fontDropdown';

const DataContext = createContext(null);

export const DataContextProvider = ({ children }) => {
	const [service, setService] = useState();
	const [file, setFile] = useState(null);
	const [fileName, setFileName] = useState('');
	const [containerHeight, setContainerHeight] = useState(0);
	const [containerWidth, setContainerWidth] = useState(0);
	const [submitModalStatus, setSubmitModalStatus] = useState(false);
	const [elementIsSelected, setElementIsSelected] = useState(0);
	const [pieChartElementIsPresent, setPieChartElementIsPresent] = useState(false);
	const [hasUndo, setHasUndo] = useState(false);
	const [hasRedo, setHasRedo] = useState(false);

	const [gridSize, setGridSize] = useState(5);
	const [elementHeight, setElementHeight] = useState(0);
	const [elementWidth, setElementWidth] = useState(0);

	const [is24Pie, setIs24Pie] = useState('0');
	const [customAngle, setCustomAngle] = useState(0);
	const [direction, setDirection] = useState('T');
	const [pieChartType, setPieChartType] = useState('C');
	const [mainColor, setMainColor] = useState('');
	const [mainStrokeWidth, setMainStrokeWidth] = useState(1);
	const [mainDashArray, setMainDashArray] = useState(0);
	const [secondaryColor, setSecondaryColor] = useState('');
	const [secondaryStrokeWidth, setSecondaryStrokeWidth] = useState(0.5);
	const [secondaryDashArray, setSecondaryDashArray] = useState(0);

	const [showCelestialDirections, setShowCelestialDirections] = useState('0');
	const [textColor, setTextColor] = useState('');
	const [textSize, setTextSize] = useState(0);
	const [distanceToCenter, setDistanceToCenter] = useState(50);
	const [textFamily, setTextFamily] = useState([
		{
			displayName: 'Arial',
			value: 'Arial',
		},
	]);

	const [bodyTextCreateOrUploadNewFile, setBodyTextCreateOrUploadNewFile] = useState(true);
	const [showHandleNewClickPrompt, setShowHandleNewClickPrompt] = useState(false);

	const handleIs24PieChange = (e) => {
		const newIs24Pie = e;
		setIs24Pie(newIs24Pie);
		service.changePieValues(
			newIs24Pie,
			customAngle,
			direction,
			pieChartType,
			mainColor,
			mainStrokeWidth,
			mainDashArray,
			secondaryColor,
			secondaryStrokeWidth,
			secondaryDashArray,
		);
	};

	const handleCustomAngleChange = (e) => {
		const newCustomAngle = Number(e);
		setCustomAngle(newCustomAngle);
		service.changePieValues(
			is24Pie,
			newCustomAngle,
			direction,
			pieChartType,
			mainColor,
			mainStrokeWidth,
			mainDashArray,
			secondaryColor,
			secondaryStrokeWidth,
			secondaryDashArray,
		);
	};

	const handleDirectionChange = (e) => {
		const newDirection = e;
		setDirection(newDirection);
		service.changePieValues(
			is24Pie,
			customAngle,
			newDirection,
			pieChartType,
			mainColor,
			mainStrokeWidth,
			mainDashArray,
			secondaryColor,
			secondaryStrokeWidth,
			secondaryDashArray,
		);
	};

	const handlePieChartTypeChange = (e) => {
		const newPieChartType = e;
		setPieChartType(newPieChartType);
		service.changePieValues(
			is24Pie,
			customAngle,
			direction,
			newPieChartType,
			mainColor,
			mainStrokeWidth,
			mainDashArray,
			secondaryColor,
			secondaryStrokeWidth,
			secondaryDashArray,
		);
	};

	const handleMainColorChange = (e) => {
		const newMainColor = rgbObjectToRGBString(e.rgb);
		setMainColor(newMainColor);
		service.changePieValues(
			is24Pie,
			customAngle,
			direction,
			pieChartType,
			newMainColor,
			mainStrokeWidth,
			mainDashArray,
			secondaryColor,
			secondaryStrokeWidth,
			secondaryDashArray,
		);
	};

	const handleMainStrokeWidthChange = (e) => {
		const newMainStrokeWidth = Number(e);
		setMainStrokeWidth(newMainStrokeWidth);
		service.changePieValues(
			is24Pie,
			customAngle,
			direction,
			pieChartType,
			mainColor,
			newMainStrokeWidth,
			mainDashArray,
			secondaryColor,
			secondaryStrokeWidth,
			secondaryDashArray,
		);
	};

	const handleMainDashArrayChange = (e) => {
		const newMainDashArray = Number(e);
		setMainDashArray(newMainDashArray);
		service.changePieValues(
			is24Pie,
			customAngle,
			direction,
			pieChartType,
			mainColor,
			mainStrokeWidth,
			newMainDashArray,
			secondaryColor,
			secondaryStrokeWidth,
			secondaryDashArray,
		);
	};

	const handleSecondaryColorChange = (e) => {
		const newSecondaryColor = rgbObjectToRGBString(e.rgb);
		setSecondaryColor(newSecondaryColor);
		service.changePieValues(
			is24Pie,
			customAngle,
			direction,
			pieChartType,
			mainColor,
			mainStrokeWidth,
			mainDashArray,
			newSecondaryColor,
			secondaryStrokeWidth,
			secondaryDashArray,
		);
	};

	const handleSecondaryStrokeWidthChange = (e) => {
		const newSecondaryStrokeWidth = Number(e);
		setSecondaryStrokeWidth(newSecondaryStrokeWidth);
		service.changePieValues(
			is24Pie,
			customAngle,
			direction,
			pieChartType,
			mainColor,
			mainStrokeWidth,
			mainDashArray,
			secondaryColor,
			newSecondaryStrokeWidth,
			secondaryDashArray,
		);
	};

	const handleSecondaryDashArrayChange = (e) => {
		const newSecondaryDashArray = Number(e);
		setSecondaryDashArray(newSecondaryDashArray);
		service.changePieValues(
			is24Pie,
			customAngle,
			direction,
			pieChartType,
			mainColor,
			mainStrokeWidth,
			mainDashArray,
			secondaryColor,
			secondaryStrokeWidth,
			newSecondaryDashArray,
		);
	};

	const setAllGeneralMenuValues = (newElementHeight, newElementWidth) => {
		setElementHeight(newElementHeight);
		setElementWidth(newElementWidth);
	};

	const setAllPieChartMenuValues = (
		newIs24Pie,
		newCustomAngle,
		newDirection,
		newPieChartType,
		newMainColor,
		newMainStrokeWidth,
		newMainDashArray,
		newSecondaryColor,
		newSecondaryStrokeWidth,
		newSecondaryDashArray,
	) => {
		setIs24Pie(newIs24Pie);
		setCustomAngle(newCustomAngle);
		setDirection(newDirection);
		setPieChartType(newPieChartType);
		setMainColor(newMainColor);
		setMainStrokeWidth(newMainStrokeWidth == null ? 0 : newMainStrokeWidth);
		setMainDashArray(newMainDashArray == null ? 0 : newMainDashArray);
		setSecondaryColor(newSecondaryColor == null ? 0 : newSecondaryColor);
		setSecondaryStrokeWidth(newSecondaryStrokeWidth == null ? 0 : newSecondaryStrokeWidth);
		setSecondaryDashArray(newSecondaryDashArray == null ? 0 : newSecondaryDashArray);
	};

	const handleShowCelestialDirectionsChange = (e) => {
		const newShowCelestialDirections = e;
		setShowCelestialDirections(newShowCelestialDirections);
		service.changeShowCelestialDirectionValues(
			newShowCelestialDirections,
			textColor,
			textSize,
			distanceToCenter,
			textFamily,
		);
	};

	const handleTextColorChange = (e) => {
		const newTextColor = rgbObjectToRGBString(e.rgb);
		setTextColor(newTextColor);
		service.changeShowCelestialDirectionValues(
			showCelestialDirections,
			newTextColor,
			textSize,
			distanceToCenter,
			textFamily,
		);
	};

	const handleTextSizeChange = (e) => {
		const newTextSize = Number(e);
		setTextSize(newTextSize);
		service.changeShowCelestialDirectionValues(
			showCelestialDirections,
			textColor,
			newTextSize,
			distanceToCenter,
			textFamily,
		);
	};

	const handleDistanceToCenterChange = (e) => {
		const newDistanceToCenter = Number(e);
		setDistanceToCenter(newDistanceToCenter);
		service.changeShowCelestialDirectionValues(
			showCelestialDirections,
			textColor,
			textSize,
			newDistanceToCenter,
			textFamily,
		);
	};

	const handleTextFamilyChange = (e) => {
		const newTextFamily = getFontFamilyObjectByValue(e);
		setTextFamily(newTextFamily);
		service.changeShowCelestialDirectionValues(
			showCelestialDirections,
			textColor,
			textSize,
			distanceToCenter,
			newTextFamily,
		);
	};

	const setAllCelestialDirectionsMenuValues = (
		newShowCelestialDirections,
		newTextColor,
		newTextSize,
		newTextFamily,
		newDistanceToCenter,
	) => {
		setShowCelestialDirections(newShowCelestialDirections);
		setTextColor(newTextColor);
		setTextSize(newTextSize == null ? 0 : newTextSize);
		setTextFamily(
			newTextFamily == null
				? getFontFamilyObjectByValue('Arial')
				: getFontFamilyObjectByValue(newTextFamily),
		);
		setDistanceToCenter(newDistanceToCenter == null ? 50 : newDistanceToCenter);
	};

	const handleSelectorClick = () => {
		service.addSelector();
	};

	const setRedoUndo = (newHasUndo, newHasRedo) => {
		setHasUndo(newHasUndo == null ? service.commandManager.hasUndo() : newHasUndo);
		setHasRedo(newHasRedo == null ? service.commandManager.hasRedo() : newHasRedo);
	};

	const handleUndoClick = () => {
		service.performUndo();
		setHasUndo(service.commandManager.hasUndo());
		setHasRedo(service.commandManager.hasRedo());
	};

	const handleRedoClick = () => {
		service.performRedo();
		setHasRedo(service.commandManager.hasRedo());
		setHasUndo(service.commandManager.hasUndo());
	};

	const handleGridSizeChange = (e) => {
		const newGridSize = Number(e);
		setGridSize(newGridSize);
		service.changeGridSize(newGridSize);
	};

	const handleHeightChange = (e) => {
		const newElementHeight = Number(e);
		setElementHeight(newElementHeight);
		service.changeElementSize(newElementHeight, elementWidth);
	};

	const handleWidthChange = (e) => {
		const newElementWidth = Number(e);
		setElementWidth(newElementWidth);
		service.changeElementSize(elementHeight, newElementWidth);
	};

	const handleNewClick = (notSaved, openFile) => {
		if (notSaved) {
			setBodyTextCreateOrUploadNewFile(openFile);
			setShowHandleNewClickPrompt(true);
			return false;
		}

		document.getElementById('uploadFileInput').value = null;
		localStorage.removeItem('currentJointGraph');
		setElementIsSelected(0);
		setFileName('');
		setFile(null);
		setRedoUndo(false, false);
		setShowHandleNewClickPrompt(false);

		return true;
	};

	// eslint-disable-next-line react/jsx-no-constructed-context-values
	const values = {
		service,
		setService,
		file,
		setFile,
		fileName,
		setFileName,
		containerHeight,
		setContainerHeight,
		containerWidth,
		setContainerWidth,
		submitModalStatus,
		setSubmitModalStatus,
		elementIsSelected,
		setElementIsSelected,
		pieChartElementIsPresent,
		setPieChartElementIsPresent,

		hasUndo,
		hasRedo,
		setRedoUndo,
		handleUndoClick,
		handleRedoClick,
		gridSize,
		setGridSize,
		elementHeight,
		setElementHeight,
		elementWidth,
		setElementWidth,

		is24Pie,
		setIs24Pie,
		customAngle,
		setCustomAngle,
		direction,
		pieChartType,
		setDirection,
		mainColor,
		setMainColor,
		mainStrokeWidth,
		setMainStrokeWidth,
		mainDashArray,
		setMainDashArray,
		secondaryColor,
		setSecondaryColor,
		secondaryStrokeWidth,
		setSecondaryStrokeWidth,
		secondaryDashArray,
		setSecondaryDashArray,

		showCelestialDirections,
		setShowCelestialDirections,
		textColor,
		setTextColor,
		textSize,
		setTextSize,
		distanceToCenter,
		setDistanceToCenter,
		textFamily,
		setTextFamily,

		bodyTextCreateOrUploadNewFile,
		setBodyTextCreateOrUploadNewFile,
		showHandleNewClickPrompt,
		setShowHandleNewClickPrompt,

		handleIs24PieChange,
		handleCustomAngleChange,
		handleDirectionChange,
		handlePieChartTypeChange,
		handleMainColorChange,
		handleMainStrokeWidthChange,
		handleMainDashArrayChange,
		handleSecondaryColorChange,
		handleSecondaryStrokeWidthChange,
		handleSecondaryDashArrayChange,
		setAllGeneralMenuValues,
		setAllPieChartMenuValues,
		setAllCelestialDirectionsMenuValues,
		handleShowCelestialDirectionsChange,
		handleTextColorChange,
		handleTextSizeChange,
		handleDistanceToCenterChange,
		handleTextFamilyChange,
		handleSelectorClick,
		handleGridSizeChange,
		handleHeightChange,
		handleWidthChange,
		handleNewClick,
	};

	return <DataContext.Provider value={values}>{children}</DataContext.Provider>;
};
DataContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default DataContext;
