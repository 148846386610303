import { BehaviorSubject } from 'rxjs';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import handleResponse, { handleResponseWithoutRedirect } from '../helpers/handle-response';

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

export const BACKEND_URL = `${process.env.REACT_APP_BACKEND_BASE_URL}`;

const authenticationService = {
	login,
	loginWithGoogle,
	loginWithFacebook,
	loginWithApple,
	register,
	confirmEmail,
	logout,
	testJwt,
	resetPasswordInit,
	resetPasswordConfirm,
	deleteAccount,
	setNext,
	currentUser: currentUserSubject.asObservable(),
	get currentUserValue() {
		return currentUserSubject.value;
	},
};

function login(email, password) {
	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({ email, password }),
	};

	return customFetch(requestOptions, `/user/authenticate`);
}

function loginWithApple(tokenId) {
	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({ tokenId }),
	};

	return customFetch(requestOptions, '/user/authenticate/external/google');
}

function loginWithGoogle(tokenId) {
	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({ tokenId }),
	};

	return customFetch(requestOptions, '/user/authenticate/external/google');
}

function loginWithFacebook(accessToken) {
	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({ accessToken }),
	};

	return customFetch(requestOptions, '/user/authenticate/external/facebook');
}

function register(email, password, passwordConfirmation, firstName, lastName) {
	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({ email, password, passwordConfirmation, firstName, lastName }),
	};

	return fetch(`${BACKEND_URL}/user/register`, requestOptions).then(
		handleResponseWithoutRedirect,
	);
}

function confirmEmail(email, token) {
	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({ email, token }),
	};

	return fetch(`${BACKEND_URL}/user/confirmEmail`, requestOptions)
		.then(handleResponseWithoutRedirect)
		.then(
			() => {
				const newUser = currentUserSubject.value;
				newUser.emailConfirmed = true;
				// store user details
				localStorage.setItem('currentUser', JSON.stringify(newUser));
				currentUserSubject.next(newUser);
			},
			(result) => result,
		);
}

function resetPasswordInit(email) {
	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({ email }),
	};

	return fetch(`${BACKEND_URL}/user/initPasswordReset`, requestOptions).then(
		handleResponseWithoutRedirect,
	);
}

function resetPasswordConfirm(token, email, newPassword, passwordConfirmation) {
	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({ token, email, newPassword, passwordConfirmation }),
	};

	return fetch(`${BACKEND_URL}/user/confirmPasswordReset`, requestOptions).then(
		handleResponseWithoutRedirect,
	);
}

function logout() {
	// remove user from local storage to log user out
	localStorage.removeItem('currentUser');
	currentUserSubject.next(null);
}

function testJwt() {
	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${currentUserSubject.value.token}`,
		},
	};
	return fetch(`${BACKEND_URL}/user/test`, requestOptions)
		.then(handleResponse)
		.then(() => {
			// eslint-disable-next-line camelcase
			const decodedToken = jwt_decode(currentUserSubject.value.token);
			return Promise.resolve(decodedToken.role === currentUserSubject.value.role);
		});
}

function deleteAccount() {
	const requestOptions = {
		method: 'DELETE',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${currentUserSubject.value.token}`,
		},
	};

	return fetch(`${BACKEND_URL}/user`, requestOptions)
		.then(
			(response) => {
				if (!response.ok) {
					return Promise.reject(response.statusText);
				}

				return logout();
			},
			(result) => {
				return Promise.reject(result);
			},
		)
		.catch(() => {
			return Promise.reject();
		});
}

function setNext(newValue) {
	currentUserSubject.next(newValue);
}

function customFetch(requestOptions, url) {
	return fetch(`${BACKEND_URL}${url}`, requestOptions)
		.then(handleResponseWithoutRedirect)
		.then((user) => {
			// store user details and jwt token in local storage to keep user logged in between page refreshes
			localStorage.setItem('currentUser', JSON.stringify(user));
			currentUserSubject.next(user);

			return user;
		});
}

export default authenticationService;
