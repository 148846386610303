import React, { useContext, useState } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import Spinner from '../../../components/bootstrap/Spinner';
import Card, {
	CardBody,
	CardFooter,
	CardFooterLeft,
	CardFooterRight,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../components/bootstrap/Card';
import CommonDesc from '../../../components/common/CommonDesc';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import showNotification from '../../../components/extras/showNotification';
import Button from '../../../components/bootstrap/Button';
import Icon from '../../../components/icon/Icon';
import { TABS } from '../ProfilePage';
import userService from '../../../services/user.service';
import { UserContext } from '../../../contexts/userContext';

const AccountDetails = () => {
	const { currentUser } = useContext(UserContext);
	const [isLoading, setIsLoading] = useState(false);
	const { t } = useTranslation('profile');
	const { t: tl } = useTranslation('login');
	const { t: tg } = useTranslation('general');

	// #region validate
	const validate = (values) => {
		const errors = {};

		if (!values.emailAddress) {
			errors.emailAddress = tg('Required');
		} else if (!/^[A-Z\d._%+-]+@[A-Z\d.-]+\.[A-Z]+$/i.test(values.emailAddress)) {
			errors.emailAddress = tl('Invalid email address');
		}

		return errors;
	};
	// #endregion

	const formik = useFormik({
		initialValues: {
			emailAddress: currentUser?.email,
		},
		validate,
		onSubmit: (values) => {
			setIsLoading(true);
			userService.initChangeEmail(currentUser?.email, values.emailAddress).then(
				() => {
					showNotification(
						<span className='d-flex align-items-center'>
							<Icon icon='Info' size='lg' className='me-1' />
							<span>{tl('Confirmation email sent')}</span>
						</span>,
						t(
							'The email change has been initialized, please confirm the change using the link we sent to your new email.',
						),
						'success',
					);
					setIsLoading(false);
				},
				() => {
					// TODO Fehlermeldung, wenn E-mail bereits vergeben.
					showNotification(
						<span className='d-flex align-items-center'>
							<Icon icon='Error' size='lg' className='me-1' />
							<span>{tl('Confirmation email not sent')}</span>
						</span>,
						t('The email change could not been initialized, please try again.'),
						'danger',
					);
					setIsLoading(false);
				},
			);
		},
	});

	const handleClick = () => {
		setIsLoading(true);
		userService.initConfirmEmail(currentUser?.email).then(
			() => {
				showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon='Info' size='lg' className='me-1' />
						<span>{tl('Confirmation email sent')}</span>
					</span>,
					tl('The confirmation email has been sent.'),
					'success',
				);
				setIsLoading(false);
			},
			(err) => {
				if (err != null && err.errors == null && err[0] === 'E-Mail is already confirmed') {
					showNotification(
						<span className='d-flex align-items-center'>
							<Icon icon='Error' size='lg' className='me-1' />
							<span>{tl('Email is already confirmed')}</span>
						</span>,
						tl(
							'Your email address is already confirmed, try and logout and login again, if you still get the warning.',
						),
						'danger',
					);
				} else {
					showNotification(
						<span className='d-flex align-items-center'>
							<Icon icon='Error' size='lg' className='me-1' />
							<span>{tl('Confirmation email not sent')}</span>
						</span>,
						tl(
							'The confirmation email could not been sent to you, please refresh the page and try again.',
						),
						'danger',
					);
				}
				setIsLoading(false);
			},
		);
	};

	return (
		<Card stretch tag='form' noValidate onSubmit={formik.handleSubmit}>
			<CardHeader>
				<CardLabel icon='Contacts' iconColor='info'>
					<CardTitle>{t(TABS.ACCOUNT_DETAIL)}</CardTitle>
				</CardLabel>
			</CardHeader>
			<CardBody className='pb-0' isScrollable>
				<div className='row g-4'>
					<div className='col-md-6'>
						<FormGroup id='firstName' label={t('First Name')} isFloating>
							<Input
								readOnly
								placeholder={t('First Name')}
								autoComplete='additional-name'
								value={currentUser?.firstName}
							/>
						</FormGroup>
					</div>
					<div className='col-md-6'>
						<FormGroup id='lastName' label={t('Last Name')} isFloating>
							<Input
								readOnly
								placeholder={t('Last Name')}
								autoComplete='family-name'
								value={currentUser?.lastName}
							/>
						</FormGroup>
					</div>
					<div className='col-12'>
						<FormGroup id='emailAddress' label={t('Email address')} isFloating>
							<Input
								type='email'
								placeholder={t('Email address')}
								autoComplete='email'
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.emailAddress}
								isValid={formik.isValid}
								isTouched={formik.touched.emailAddress}
								invalidFeedback={formik.errors.emailAddress}
							/>
						</FormGroup>
					</div>
					{currentUser != null && !currentUser.emailConfirmed && (
						<div className='col-12'>
							<Card>
								<CardHeader>
									<CardLabel icon='MarkEmailRead' iconColor='warning'>
										<CardTitle>{t('Confirm Email')}</CardTitle>
									</CardLabel>
								</CardHeader>
								<CardBody>
									<CommonDesc>
										{t(
											'Please confirm your email address in order to purchase a subscription and unlock all features.',
										)}
									</CommonDesc>
								</CardBody>
								<CardFooter>
									<CardFooterRight>
										<Button
											color='info'
											isOutline
											isDisable={isLoading}
											icon={isLoading ? null : 'Send'}
											onClick={handleClick}>
											{isLoading && <Spinner color='info' inButton isSmall />}
											{t('Send Confirmation')}
										</Button>
									</CardFooterRight>
								</CardFooter>
							</Card>
						</div>
					)}
				</div>
			</CardBody>
			<CardFooter>
				<CardFooterLeft>
					<Button color='info' isLink type='reset' onClick={formik.resetForm}>
						{t('Reset')}
					</Button>
				</CardFooterLeft>
				<CardFooterRight>
					<Button
						type='submit'
						color='info'
						isOutline
						isDisable={isLoading || formik.values.emailAddress === currentUser?.email}
						icon={isLoading ? null : 'Save'}
						onClick={formik.handleSubmit}>
						{isLoading && <Spinner color='info' inButton isSmall />}
						{tg('Save')}
					</Button>
				</CardFooterRight>
			</CardFooter>
		</Card>
	);
};

export default AccountDetails;
