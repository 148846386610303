import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import { getFontFamilyObjectByValue } from '../../../../components/extras/fontDropdown';
import rgbObjectToRGBString from '../../../../helpers/color-helpers';

const DataContext = createContext(null);

export const DataContextProvider = ({ children }) => {
	const [service, setService] = useState();
	const [file, setFile] = useState(null);
	const [fileName, setFileName] = useState('');
	const [containerHeight, setContainerHeight] = useState(0);
	const [containerWidth, setContainerWidth] = useState(0);
	const [submitModalStatus, setSubmitModalStatus] = useState(false);
	const [elementIsSelected, setElementIsSelected] = useState(0);
	const [flyingStarsElementIsPresent, setFlyingStarsElementIsPresent] = useState(false);
	const [flyingStarsAdditionalElementIsPresent, setFlyingStarsAdditionalElementIsPresent] =
		useState(false);
	const [yearEnergyIsActive, setYearEnergyIsActive] = useState(false);
	const [yearEnergyIsActiveAdditionalChart, setYearEnergyIsActiveAdditionalChart] =
		useState(false);
	const [hasUndo, setHasUndo] = useState(false);
	const [hasRedo, setHasRedo] = useState(false);

	const [gridSize, setGridSize] = useState(5);
	const [elementHeight, setElementHeight] = useState(0);
	const [elementWidth, setElementWidth] = useState(0);

	const [bodyTextCreateOrUploadNewFile, setBodyTextCreateOrUploadNewFile] = useState(true);
	const [showHandleNewClickPrompt, setShowHandleNewClickPrompt] = useState(false);

	const [initialAngle, setInitialAngle] = useState(0);
	const [initialCelestialDirection, setInitialCelestialDirection] = useState(0);

	const [distanceToCenter, setDistanceToCenter] = useState(0);
	const [textColorSittingFacing, setTextColorSittingFacing] = useState('');
	const [textSizeSittingFacing, setTextSizeSittingFacing] = useState(0);
	const [raiseUpSittingFacing, setRaiseUpSittingFacing] = useState(0);
	const [textColorBase, setTextColorBase] = useState('');
	const [textSizeBase, setTextSizeBase] = useState(0);
	const [textColorYearEnergy, setTextColorYearEnergy] = useState('');
	const [textSizeYearEnergy, setTextSizeYearEnergy] = useState(0);
	const [textFamily, setTextFamily] = useState([
		{
			displayName: 'Arial',
			value: 'Arial',
		},
	]);
	const [circleColor, setCircleColor] = useState('');
	const [circleRadius, setCircleRadius] = useState(0);

	const [textColorSittingFacingAdditionalChart, setTextColorSittingFacingAdditionalChart] =
		useState('');
	const [textSizeSittingFacingAdditionalChart, setTextSizeSittingFacingAdditionalChart] =
		useState(0);
	const [raiseUpSittingFacingAdditionalChart, setRaiseUpSittingFacingAdditionalChart] =
		useState(0);
	const [textColorBaseAdditionalChart, setTextColorBaseAdditionalChart] = useState('');
	const [textSizeBaseAdditionalChart, setTextSizeBaseAdditionalChart] = useState(0);
	const [textColorYearEnergyAdditionalChart, setTextColorYearEnergyAdditionalChart] =
		useState('');
	const [textSizeYearEnergyAdditionalChart, setTextSizeYearEnergyAdditionalChart] = useState(0);
	const [textFamilyAdditionalChart, setTextFamilyAdditionalChart] = useState([
		{
			displayName: 'Arial',
			value: 'Arial',
		},
	]);

	const setAllGeneralMenuValues = (newElementHeight, newElementWidth) => {
		setElementHeight(newElementHeight);
		setElementWidth(newElementWidth);
	};

	const setAllMainChartMenuValues = (
		newTextColorSittingFacing,
		newTextSizeSittingFacing,
		newRaiseUpSittingFacing,
		newTextColorBase,
		newTextSizeBase,
		newTextColorYearEnergy,
		newTextSizeYearEnergy,
		newYearEnergyIsActive,
		newTextFamily,
		newCircleColor,
		newCircleRadius,
		newDistanceToCenter,
	) => {
		setTextColorSittingFacing(newTextColorSittingFacing);
		setTextSizeSittingFacing(newTextSizeSittingFacing);
		setRaiseUpSittingFacing(newRaiseUpSittingFacing);
		setTextColorBase(newTextColorBase);
		setTextSizeBase(newTextSizeBase);
		setTextColorYearEnergy(newTextColorYearEnergy);
		setTextSizeYearEnergy(newTextSizeYearEnergy);
		setYearEnergyIsActive(newYearEnergyIsActive);
		setTextFamily(
			newTextFamily == null
				? getFontFamilyObjectByValue('Arial')
				: getFontFamilyObjectByValue(newTextFamily),
		);
		setCircleColor(newCircleColor);
		setCircleRadius(newCircleRadius);
		setDistanceToCenter(newDistanceToCenter);
	};

	const setAllAdditionalChartMenuValues = (
		newTextColorSittingFacingAdditionalChart,
		newTextSizeSittingFacingAdditionalChart,
		newRaiseUpSittingFacingAdditionalChart,
		newTextColorBaseAdditionalChart,
		newTextSizeBaseAdditionalChart,
		newTextColorYearEnergyAdditionalChart,
		newTextSizeYearEnergyAdditionalChart,
		newYearEnergyIsActiveAdditionalChart,
		newTextFamilyAdditionalChart,
	) => {
		setTextColorSittingFacingAdditionalChart(newTextColorSittingFacingAdditionalChart);
		setTextSizeSittingFacingAdditionalChart(newTextSizeSittingFacingAdditionalChart);
		setRaiseUpSittingFacingAdditionalChart(newRaiseUpSittingFacingAdditionalChart);
		setTextColorBaseAdditionalChart(newTextColorBaseAdditionalChart);
		setTextSizeBaseAdditionalChart(newTextSizeBaseAdditionalChart);
		setTextColorYearEnergyAdditionalChart(newTextColorYearEnergyAdditionalChart);
		setTextSizeYearEnergyAdditionalChart(newTextSizeYearEnergyAdditionalChart);
		setYearEnergyIsActiveAdditionalChart(newYearEnergyIsActiveAdditionalChart);
		setTextFamilyAdditionalChart(
			newTextFamilyAdditionalChart == null
				? getFontFamilyObjectByValue('Arial')
				: getFontFamilyObjectByValue(newTextFamilyAdditionalChart),
		);
	};

	const setPieChartValues = (newInitialAngle, newInitialCelestialDirection) => {
		setInitialAngle(newInitialAngle);
		setInitialCelestialDirection(newInitialCelestialDirection);
	};

	const setRedoUndo = (newHasUndo, newHasRedo) => {
		setHasUndo(newHasUndo == null ? service.commandManager.hasUndo() : newHasUndo);
		setHasRedo(newHasRedo == null ? service.commandManager.hasRedo() : newHasRedo);
	};

	const handleUndoClick = () => {
		service.performUndo();
		setHasUndo(service.commandManager.hasUndo());
		setHasRedo(service.commandManager.hasRedo());
	};

	const handleRedoClick = () => {
		service.performRedo();
		setHasRedo(service.commandManager.hasRedo());
		setHasUndo(service.commandManager.hasUndo());
	};

	const handleGridSizeChange = (e) => {
		const newGridSize = Number(e);
		setGridSize(newGridSize);
		service.changeGridSize(newGridSize);
	};

	const handleHeightChange = (e) => {
		const newElementHeight = Number(e);
		setElementHeight(newElementHeight);
		service.changeElementSize(newElementHeight, elementWidth);
	};

	const handleWidthChange = (e) => {
		const newElementWidth = Number(e);
		setElementWidth(newElementWidth);
		service.changeElementSize(elementHeight, newElementWidth);
	};

	const handleNewClick = (notSaved, openFile) => {
		if (notSaved) {
			setBodyTextCreateOrUploadNewFile(openFile);
			setShowHandleNewClickPrompt(true);
			return false;
		}

		document.getElementById('uploadFileInput').value = null;
		localStorage.removeItem('currentJointGraph');
		setElementIsSelected(0);
		setFileName('');
		setFile(null);
		setRedoUndo(false, false);
		setShowHandleNewClickPrompt(false);

		return true;
	};

	const handleDistanceToCenterChange = (e) => {
		const newValue = e;
		setDistanceToCenter(newValue);
		service.changePieValues(
			true,
			newValue,
			textColorSittingFacing,
			textSizeSittingFacing,
			raiseUpSittingFacing,
			textColorBase,
			textSizeBase,
			textColorYearEnergy,
			textSizeYearEnergy,
			textFamily[0].value,
		);
	};

	const handleTextColorSittingFacingChange = (e) => {
		const newValue = rgbObjectToRGBString(e.rgb);
		setTextColorSittingFacing(newValue);
		service.changePieValues(
			true,
			distanceToCenter,
			newValue,
			textSizeSittingFacing,
			raiseUpSittingFacing,
			textColorBase,
			textSizeBase,
			textColorYearEnergy,
			textSizeYearEnergy,
			textFamily[0].value,
		);
	};

	const handleTextSizeSittingFacingChange = (e) => {
		const newValue = e;
		setTextSizeSittingFacing(newValue);
		service.changePieValues(
			true,
			distanceToCenter,
			textColorSittingFacing,
			newValue,
			raiseUpSittingFacing,
			textColorBase,
			textSizeBase,
			textColorYearEnergy,
			textSizeYearEnergy,
			textFamily[0].value,
		);
	};

	const handleRaiseUpSittingFacingChange = (e) => {
		const newValue = e;
		setRaiseUpSittingFacing(newValue);
		service.changePieValues(
			true,
			distanceToCenter,
			textColorSittingFacing,
			textSizeSittingFacing,
			newValue,
			textColorBase,
			textSizeBase,
			textColorYearEnergy,
			textSizeYearEnergy,
			textFamily[0].value,
		);
	};

	const handleTextColorBaseChange = (e) => {
		const newValue = rgbObjectToRGBString(e.rgb);
		setTextColorBase(newValue);
		service.changePieValues(
			true,
			distanceToCenter,
			textColorSittingFacing,
			textSizeSittingFacing,
			raiseUpSittingFacing,
			newValue,
			textSizeBase,
			textColorYearEnergy,
			textSizeYearEnergy,
			textFamily[0].value,
		);
	};

	const handleTextSizeBaseChange = (e) => {
		const newValue = e;
		setTextSizeBase(newValue);
		service.changePieValues(
			true,
			distanceToCenter,
			textColorSittingFacing,
			textSizeSittingFacing,
			raiseUpSittingFacing,
			textColorBase,
			newValue,
			textColorYearEnergy,
			textSizeYearEnergy,
			textFamily[0].value,
		);
	};

	const handleTextColorYearEnergyChange = (e) => {
		const newValue = rgbObjectToRGBString(e.rgb);
		setTextColorYearEnergy(newValue);
		service.changePieValues(
			true,
			distanceToCenter,
			textColorSittingFacing,
			textSizeSittingFacing,
			raiseUpSittingFacing,
			textColorBase,
			textSizeBase,
			newValue,
			textSizeYearEnergy,
			textFamily[0].value,
		);
	};

	const handleTextSizeYearEnergyChange = (e) => {
		const newValue = e;
		setTextSizeYearEnergy(newValue);
		service.changePieValues(
			true,
			distanceToCenter,
			textColorSittingFacing,
			textSizeSittingFacing,
			raiseUpSittingFacing,
			textColorBase,
			textSizeBase,
			textColorYearEnergy,
			newValue,
			textFamily[0].value,
		);
	};

	const handleTextFamilyChange = (e) => {
		const newValue = getFontFamilyObjectByValue(e);
		setTextFamily(newValue);
		service.changePieValues(
			true,
			distanceToCenter,
			textColorSittingFacing,
			textSizeSittingFacing,
			raiseUpSittingFacing,
			textColorBase,
			textSizeBase,
			textColorYearEnergy,
			textSizeYearEnergy,
			newValue[0].value,
		);
	};

	const handleCircleColorChange = (e) => {
		const newValue = rgbObjectToRGBString(e.rgb);
		setCircleColor(newValue);
		service.change8SectorCircleValues(newValue, circleRadius);
	};

	const handleCircleRadiusChange = (e) => {
		const newValue = e;
		setCircleRadius(newValue);
		service.change8SectorCircleValues(circleColor, newValue);
	};

	const handleTextColorSittingFacingAdditionalChartChange = (e) => {
		const newValue = rgbObjectToRGBString(e.rgb);
		setTextColorSittingFacingAdditionalChart(newValue);
		service.changePieValues(
			false,
			distanceToCenter,
			newValue,
			textSizeSittingFacingAdditionalChart,
			raiseUpSittingFacingAdditionalChart,
			textColorBaseAdditionalChart,
			textSizeBaseAdditionalChart,
			textColorYearEnergyAdditionalChart,
			textSizeYearEnergyAdditionalChart,
			textFamilyAdditionalChart[0].value,
		);
	};

	const handleTextSizeSittingFacingAdditionalChartChange = (e) => {
		const newValue = e;
		setTextSizeSittingFacingAdditionalChart(newValue);
		service.changePieValues(
			false,
			distanceToCenter,
			textColorSittingFacingAdditionalChart,
			newValue,
			raiseUpSittingFacingAdditionalChart,
			textColorBaseAdditionalChart,
			textSizeBaseAdditionalChart,
			textColorYearEnergyAdditionalChart,
			textSizeYearEnergyAdditionalChart,
			textFamilyAdditionalChart[0].value,
		);
	};

	const handleRaiseUpSittingFacingAdditionalChartChange = (e) => {
		const newValue = e;
		setRaiseUpSittingFacingAdditionalChart(newValue);
		service.changePieValues(
			false,
			distanceToCenter,
			textColorSittingFacingAdditionalChart,
			textSizeSittingFacingAdditionalChart,
			newValue,
			textColorBaseAdditionalChart,
			textSizeBaseAdditionalChart,
			textColorYearEnergyAdditionalChart,
			textSizeYearEnergyAdditionalChart,
			textFamilyAdditionalChart[0].value,
		);
	};

	const handleTextColorBaseAdditionalChartChange = (e) => {
		const newValue = rgbObjectToRGBString(e.rgb);
		setTextColorBaseAdditionalChart(newValue);
		service.changePieValues(
			false,
			distanceToCenter,
			textColorSittingFacingAdditionalChart,
			textSizeSittingFacingAdditionalChart,
			raiseUpSittingFacingAdditionalChart,
			newValue,
			textSizeBaseAdditionalChart,
			textColorYearEnergyAdditionalChart,
			textSizeYearEnergyAdditionalChart,
			textFamilyAdditionalChart[0].value,
		);
	};

	const handleTextSizeBaseAdditionalChartChange = (e) => {
		const newValue = e;
		setTextSizeBaseAdditionalChart(newValue);
		service.changePieValues(
			false,
			distanceToCenter,
			textColorSittingFacingAdditionalChart,
			textSizeSittingFacingAdditionalChart,
			raiseUpSittingFacingAdditionalChart,
			textColorBaseAdditionalChart,
			newValue,
			textColorYearEnergyAdditionalChart,
			textSizeYearEnergyAdditionalChart,
			textFamilyAdditionalChart[0].value,
		);
	};

	const handleTextColorYearEnergyAdditionalChartChange = (e) => {
		const newValue = rgbObjectToRGBString(e.rgb);
		setTextColorYearEnergyAdditionalChart(newValue);
		service.changePieValues(
			false,
			distanceToCenter,
			textColorSittingFacingAdditionalChart,
			textSizeSittingFacingAdditionalChart,
			raiseUpSittingFacingAdditionalChart,
			textColorBaseAdditionalChart,
			textSizeBaseAdditionalChart,
			newValue,
			textSizeYearEnergyAdditionalChart,
			textFamilyAdditionalChart[0].value,
		);
	};

	const handleTextSizeYearEnergyAdditionalChartChange = (e) => {
		const newValue = e;
		setTextSizeYearEnergyAdditionalChart(newValue);
		service.changePieValues(
			false,
			distanceToCenter,
			textColorSittingFacingAdditionalChart,
			textSizeSittingFacingAdditionalChart,
			raiseUpSittingFacingAdditionalChart,
			textColorBaseAdditionalChart,
			textSizeBaseAdditionalChart,
			textColorYearEnergyAdditionalChart,
			newValue,
			textFamilyAdditionalChart[0].value,
		);
	};

	const handleTextFamilyAdditionalChartChange = (e) => {
		const newValue = getFontFamilyObjectByValue(e);
		setTextFamilyAdditionalChart(newValue);
		service.changePieValues(
			false,
			distanceToCenter,
			textColorSittingFacingAdditionalChart,
			textSizeSittingFacingAdditionalChart,
			raiseUpSittingFacingAdditionalChart,
			textColorBaseAdditionalChart,
			textSizeBaseAdditionalChart,
			textColorYearEnergyAdditionalChart,
			textSizeYearEnergyAdditionalChart,
			newValue[0].value,
		);
	};

	// eslint-disable-next-line react/jsx-no-constructed-context-values
	const values = {
		service,
		setService,
		file,
		setFile,
		fileName,
		setFileName,
		containerHeight,
		setContainerHeight,
		containerWidth,
		setContainerWidth,
		submitModalStatus,
		setSubmitModalStatus,
		elementIsSelected,
		setElementIsSelected,
		flyingStarsElementIsPresent,
		setFlyingStarsElementIsPresent,
		flyingStarsAdditionalElementIsPresent,
		setFlyingStarsAdditionalElementIsPresent,
		yearEnergyIsActive,
		setYearEnergyIsActive,
		yearEnergyIsActiveAdditionalChart,

		initialAngle,
		setInitialAngle,
		initialCelestialDirection,
		setInitialCelestialDirection,
		setPieChartValues,

		hasUndo,
		hasRedo,
		setRedoUndo,
		handleUndoClick,
		handleRedoClick,
		gridSize,
		setGridSize,
		elementHeight,
		setElementHeight,
		elementWidth,
		setElementWidth,

		bodyTextCreateOrUploadNewFile,
		setBodyTextCreateOrUploadNewFile,
		showHandleNewClickPrompt,
		setShowHandleNewClickPrompt,

		setAllGeneralMenuValues,
		setAllMainChartMenuValues,
		setAllAdditionalChartMenuValues,
		handleGridSizeChange,
		handleHeightChange,
		handleWidthChange,
		handleNewClick,

		distanceToCenter,
		textColorSittingFacing,
		textSizeSittingFacing,
		raiseUpSittingFacing,
		textColorBase,
		textSizeBase,
		textColorYearEnergy,
		textSizeYearEnergy,
		textFamily,
		circleColor,
		circleRadius,

		setCircleRadius,
		handleDistanceToCenterChange,
		handleTextColorSittingFacingChange,
		handleTextSizeSittingFacingChange,
		handleRaiseUpSittingFacingChange,
		handleTextColorBaseChange,
		handleTextSizeBaseChange,
		handleTextColorYearEnergyChange,
		handleTextSizeYearEnergyChange,
		handleTextFamilyChange,
		handleCircleColorChange,
		handleCircleRadiusChange,

		textColorSittingFacingAdditionalChart,
		textSizeSittingFacingAdditionalChart,
		raiseUpSittingFacingAdditionalChart,
		textColorBaseAdditionalChart,
		textSizeBaseAdditionalChart,
		textColorYearEnergyAdditionalChart,
		textSizeYearEnergyAdditionalChart,
		textFamilyAdditionalChart,

		handleTextColorSittingFacingAdditionalChartChange,
		handleTextSizeSittingFacingAdditionalChartChange,
		handleRaiseUpSittingFacingAdditionalChartChange,
		handleTextColorBaseAdditionalChartChange,
		handleTextSizeBaseAdditionalChartChange,
		handleTextColorYearEnergyAdditionalChartChange,
		handleTextSizeYearEnergyAdditionalChartChange,
		handleTextFamilyAdditionalChartChange,
	};

	return <DataContext.Provider value={values}>{children}</DataContext.Provider>;
};
DataContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default DataContext;
