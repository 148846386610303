import React from 'react';
import StartWOWFreePageImpl from './StartWOWFreePageImpl';
import { DataContextProvider } from './context/DataContext';

const StartWOWFreePage = () => {
	return (
		<DataContextProvider>
			<StartWOWFreePageImpl />
		</DataContextProvider>
	);
};

export default StartWOWFreePage;
