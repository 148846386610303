import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import authenticationService from "../services/authentication.service";

const PrivateRoute = ({ component: Component, roles }) => {
	const currentUser = authenticationService.currentUserValue;
	if (!currentUser) {
		// not logged in so Navigate to login page
		let location = useLocation();
		return <Navigate to={{ pathname: '/auth/login', state: { from: location.pathname } }} />;
	}

	// check if route is restricted by role
	if (roles && !roles.includes(currentUser.role)) {
		// role not authorised so Navigate to home page
		return <Navigate to={{ pathname: '/' }} />;
	}

	// authorised so return component
	return <Component />;
};

export default PrivateRoute;
