import authenticationService from '../services/authentication.service';

function handleResponse(response) {
	return response.text().then((text) => {
		let data = null;

		if (text && IsJsonString(text)) {
			data = JSON.parse(text);
		}

		if (!response.ok) {
			if ([401, 403].indexOf(response.status) !== -1) {
				// auto logout if 401 Unauthorized or 403 Forbidden response returned from api
				authenticationService.logout();
				// eslint-disable-next-line no-restricted-globals
				location.reload(true);
			}

			const error = data || response.statusText;
			return Promise.reject(error);
		}

		return data == null ? response : { data, status: response.status };
	});
}

export function handleResponseWithoutRedirect(response) {
	return response.text().then((text) => {
		let data = null;

		if (text && IsJsonString(text)) {
			data = JSON.parse(text);
		}

		if (!response.ok) {
			const error = data || response.statusText;
			return Promise.reject(error);
		}

		return data == null ? response : data;
	});
}

function IsJsonString(str) {
	try {
		JSON.parse(str);
	} catch (e) {
		return false;
	}
	return true;
}

export default handleResponse;
