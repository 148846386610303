import { ui } from '@clientio/rappid';
import HaloService from './HaloService';
import ExportImportService from './ExportImportService';
import RappidServiceBase from '../../../../common/services/RappidServiceBase';
import FlyingStarsService from './FlyingStarsService';
import {
	getFacingSittingValuesFromDatabase,
	getYearEnergyValuesFromDatabase,
} from '../../../../helpers/fetch-helpers';
import {
	FLYING_STARS_MAIN_GROUP_ID,
	FLYING_STARS_SECONDARY_GROUP_ID,
} from '../../../../common/data/selectorIDs';
import {
	selectionIsFlyingStarsAdditionalChart,
	selectionIsFlyingStarsMainChart,
} from '../helpers/RappidServiceHelpers';

class RappidService extends RappidServiceBase {
	constructor(
		el,
		keyboardService,
		handleNewClick,
		setRedoUndo,
		setAllGeneralMenuValues,
		setFlyingStarsElementIsPresent,
		setFlyingStarsAdditionalElementIsPresent,
		setPieChartValues,
		setAllMainChartMenuValues,
		setAllAdditionalChartMenuValues,
		setCircleRadius,
	) {
		super(el, keyboardService, handleNewClick, setRedoUndo);
		this.setAllGeneralMenuValues = setAllGeneralMenuValues;
		this.setFlyingStarsElementIsPresent = setFlyingStarsElementIsPresent;
		this.setFlyingStarsAdditionalElementIsPresent = setFlyingStarsAdditionalElementIsPresent;
		this.setPieChartValues = setPieChartValues;
		this.setAllMainChartMenuValues = setAllMainChartMenuValues;
		this.setAllAdditionalChartMenuValues = setAllAdditionalChartMenuValues;
		this.setCircleRadius = setCircleRadius;
	}

	startRappid() {
		this.initializePaper();
		this.initializeSelection();
		this.initializeKeyboardShortcuts();
		this.initializeTooltips();

		this.haloService = new HaloService();
		this.exportImportService = new ExportImportService(this);
		this.flyingStarsService = new FlyingStarsService(this);
	}

	async addMainCharts(values) {
		const facingSittingBaseValues = await getFacingSittingValuesFromDatabase(
			values.celestialDirection,
			values.period,
		);
		const yearEnergyValues = values.showYearEnergy
			? await getYearEnergyValuesFromDatabase(values.yearEnergy)
			: null;

		switch (values.is8SectorsOr9Rectangles) {
			case '0':
				this.flyingStarsService.add8SectorsPie(
					values,
					facingSittingBaseValues,
					yearEnergyValues,
				);
				break;
			case '1':
				this.flyingStarsService.add9RectanglesChart(
					values,
					facingSittingBaseValues,
					yearEnergyValues,
					false,
				);
				break;
			case '2':
			default:
				this.flyingStarsService.add8SectorsPie(
					values,
					facingSittingBaseValues,
					yearEnergyValues,
				);
				this.flyingStarsService.add9RectanglesChart(
					values,
					facingSittingBaseValues,
					yearEnergyValues,
					false,
				);
		}

		this.centerElements();
	}

	selectMainChartCell() {
		const currentMainChart = this.graph
			.getCells()
			.filter((cell) => cell.id === FLYING_STARS_MAIN_GROUP_ID)[0];
		this.selection.collection.reset(currentMainChart);
	}

	selectAdditionalChartCell() {
		const currentSecondaryChart = this.graph
			.getCells()
			.filter((cell) => cell.id === FLYING_STARS_SECONDARY_GROUP_ID)[0];
		this.selection.collection.reset(currentSecondaryChart);
	}

	changePieValues(
		mainChart,
		newDistanceToCenter,
		newTextColorSittingFacing,
		newTextSizeSittingFacing,
		newRaiseUpSittingFacing,
		newTextColorBase,
		newTextSizeBase,
		newTextColorYearEnergy,
		newTextSizeYearEnergy,
		newTextFamily,
	) {
		if (mainChart) {
			this.flyingStarsService.change8SectorsPieValues(
				newDistanceToCenter,
				newTextColorSittingFacing,
				newTextSizeSittingFacing,
				newRaiseUpSittingFacing,
				newTextColorBase,
				newTextSizeBase,
				newTextColorYearEnergy,
				newTextSizeYearEnergy,
				newTextFamily,
			);
		} else {
			this.flyingStarsService.change9RectanglesValues(
				newTextColorSittingFacing,
				newTextSizeSittingFacing,
				newRaiseUpSittingFacing,
				newTextColorBase,
				newTextSizeBase,
				newTextColorYearEnergy,
				newTextSizeYearEnergy,
				newTextFamily,
			);
		}
	}

	change8SectorCircleValues(newCircleColor, newCircleRadius) {
		this.flyingStarsService.change8SectorCircleValues(newCircleColor, newCircleRadius);
	}

	onSelectionChange() {
		const { paper, selection } = this;
		const { collection } = selection;
		paper.removeTools();
		ui.Halo.clear(paper);
		ui.FreeTransform.clear(paper);

		if (collection.length === 1) {
			const primaryCell = collection.first();
			const primaryCellView = paper.requireView(primaryCell);
			selection.destroySelectionBox(primaryCell);
			this.selectPrimaryCell(primaryCellView);

			if (selectionIsFlyingStarsMainChart(primaryCellView)) {
				this.setAllMainChartMenuValues(
					primaryCell.attr('nSitting/fill'),
					primaryCell.attr('nSitting/fontSize'),
					primaryCell.attr('custom/facingSittingOffset'),
					primaryCell.attr('nBase/fill'),
					primaryCell.attr('nBase/fontSize'),
					primaryCell.attr('nYear/fill'),
					primaryCell.attr('nYear/fontSize'),
					primaryCell.attr('nYear/text') !== '',
					primaryCell.attr('nSitting/fontFamily'),
					primaryCell.attr('n/fill'),
					primaryCell.attr('n/r'),
					primaryCell.attr('custom/distanceToCenter'),
				);
			} else if (selectionIsFlyingStarsAdditionalChart(primaryCellView)) {
				this.setAllAdditionalChartMenuValues(
					primaryCell.attr('nSitting/fill'),
					primaryCell.attr('nSitting/fontSize'),
					primaryCell.attr('custom/facingSittingOffset'),
					primaryCell.attr('nBase/fill'),
					primaryCell.attr('nBase/fontSize'),
					primaryCell.attr('nYear/fill'),
					primaryCell.attr('nYear/fontSize'),
					primaryCell.attr('nYear/text') !== '',
					primaryCell.attr('nSitting/fontFamily'),
				);
			}
		} else {
			selection.removeHandle('resize');
		}
	}
}

export default RappidService;
