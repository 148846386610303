import React from 'react';
import PropTypes from 'prop-types';
import Button from './bootstrap/Button';
import CustomTooltip from './CustomTooltip';

const TabMenu = ({ listTab, t, activeListTab, handleActiveListTab }) => {
	const getStatusActiveListTabColor = (tabName) => {
		if (activeListTab === tabName) return 'primary';
		return 'light';
	};

	return (
		<div style={{ padding: '0.5rem 1.5rem' }}>
			<div className='bg-light p-2 rounded-3'>
				{Object.keys(listTab).map((key) => {
					const menuIsDisabled = listTab[key].disabled;
					return (
						<CustomTooltip
							title={t(
								`${listTab[key].name} ${menuIsDisabled ? 'Inactive' : 'Active'}`,
							)}>
							<span
								style={{
									cursor: menuIsDisabled ? 'not-allowed' : 'pointer',
								}}>
								<Button
									key={key}
									size='sm'
									style={{
										pointerEvents: menuIsDisabled ? 'none' : 'auto',
									}}
									isDisable={menuIsDisabled}
									color={getStatusActiveListTabColor(listTab[key].name)}
									onClick={() => handleActiveListTab(listTab[key].name)}>
									{t(listTab[key].name)}
								</Button>
							</span>
						</CustomTooltip>
					);
				})}
			</div>
		</div>
	);
};
TabMenu.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	listTab: PropTypes.object.isRequired,
	t: PropTypes.func.isRequired,
	activeListTab: PropTypes.string.isRequired,
	handleActiveListTab: PropTypes.func.isRequired,
};

export default TabMenu;
