import { jsPDF } from 'jspdf';
import _ from 'lodash';
import { downloadFile, readTextFromFile } from '../../helpers/file-handle-helpers';
import { isSafari } from '../../helpers/helpers';

export const FILE_TYPE_NAME = '.wowgenerator';

class ExportImportServiceBase {
	constructor(rappidService) {
		this.exportStylesheet = '.scalable * { vector-effect: non-scaling-stroke }';
		this.paper = rappidService.paper;
		this.graph = rappidService.graph;
		this.firstLoad = true;
	}

	openAsSVG(fileName, extName) {
		this.handleFirstSaveCall();
		setTimeout(
			() => {
				this.paper.hideTools().toSVG(
					(svg) => {
						downloadFile(
							`data:image/svg+xml,${encodeURIComponent(svg)}`,
							`${fileName}-${extName}`,
						);
						localStorage.setItem(
							'currentJointGraph',
							JSON.stringify(this.graph.toJSON()),
						);
						this.paper.showTools();
					},
					{
						padding: 10,
						preserveDimensions: true,
						convertImagesToDataUris: true,
						useComputedStyles: false,
						stylesheet: this.exportStylesheet,
					},
				);
			},
			this.firstLoad ? 150 : 0,
		);
	}

	openAsPNG(fileName, extName) {
		this.handleFirstSaveCall();
		setTimeout(
			() => {
				this.paper.hideTools().toPNG(
					(dataURL) => {
						downloadFile(dataURL, `${fileName}-${extName}`);
						localStorage.setItem(
							'currentJointGraph',
							JSON.stringify(this.graph.toJSON()),
						);
						this.paper.showTools();
					},
					{
						padding: 10,
						useComputedStyles: false,
						stylesheet: this.exportStylesheet,
					},
				);
			},
			this.firstLoad ? 150 : 0,
		);
	}

	openAsJPEG(fileName, extName) {
		this.handleFirstSaveCall();
		setTimeout(
			() => {
				this.paper.hideTools().toJPEG(
					(dataURL) => {
						downloadFile(dataURL, `${fileName}-${extName}`);
						localStorage.setItem(
							'currentJointGraph',
							JSON.stringify(this.graph.toJSON()),
						);
						this.paper.showTools();
					},
					{
						type: 'image/jpeg',
						padding: 10,
						useComputedStyles: false,
						stylesheet: this.exportStylesheet,
					},
				);
			},
			this.firstLoad ? 150 : 0,
		);
	}

	openAsPDF(fileName, extName) {
		this.handleFirstSaveCall();
		setTimeout(
			() => {
				const size = this.paper.getContentArea();
				this.paper.hideTools().toPNG(
					(dataURL) => {
						// eslint-disable-next-line new-cap
						const pdf = new jsPDF({
							unit: 'px',
							format: [size.width + 20, size.height + 20],
							orientation: size.width > size.height ? 'l' : 'p',
						});
						pdf.addImage(dataURL, 'PNG', 0, 0, size.width, size.height);
						pdf.save(`${fileName}-${extName}.pdf`);
						localStorage.setItem(
							'currentJointGraph',
							JSON.stringify(this.graph.toJSON()),
						);
						this.paper.showTools();
					},
					{
						padding: 10,
						useComputedStyles: false,
						stylesheet: this.exportStylesheet,
					},
				);
			},
			this.firstLoad ? 150 : 0,
		);
	}

	handleFirstSaveCall() {
		// Dirty fix, but in some browsers the image is not exported on the first save call.
		if (isSafari() || this.firstLoad) {
			this.paper.hideTools().toPNG();
			this.firstLoad = false;
		}
	}

	async saveGraph(fileName, extName) {
		const fileContent = JSON.stringify(this.graph.toJSON());
		const dataURL = `data:text/plain;base64,${btoa(fileContent)}`;
		downloadFile(dataURL, `${fileName}.${extName}`);
		localStorage.setItem('currentJointGraph', fileContent);
	}

	graphIsNotSaved() {
		const graphJson = JSON.parse(localStorage.getItem('currentJointGraph'));

		if (graphJson != null) {
			return !_(graphJson.cells)
				.xorWith(JSON.parse(JSON.stringify(this.graph.toJSON())).cells, _.isEqual)
				.isEmpty();
		}

		return this.graph.getCells().length > 0;
	}

	async uploadGraph(file, showNotification) {
		try {
			const fileText = await readTextFromFile(file);
			this.graph.fromJSON(JSON.parse(fileText));

			localStorage.setItem('currentJointGraph', fileText);

			return true;
		} catch (error) {
			showNotification();

			return false;
		}
	}
}

export default ExportImportServiceBase;
