import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Input, InputNumber, Row } from 'antd';
import FontSelectorAutocomplete from '../../../../components/extras/fontDropdown';
import DataContext from '../context/DataContext';
import ColorPicker from '../../../../components/ColorPicker';
import CustomTooltip from '../../../../components/CustomTooltip';

const MainChartMenu = () => {
	const { t } = useTranslation('flyWOW');
	const {
		flyingStarsElementIsPresent,
		yearEnergyIsActive,
		distanceToCenter,
		textColorSittingFacing,
		textSizeSittingFacing,
		raiseUpSittingFacing,
		textColorBase,
		textSizeBase,
		textColorYearEnergy,
		textSizeYearEnergy,
		textFamily,
		circleColor,
		circleRadius,

		handleDistanceToCenterChange,
		handleTextColorSittingFacingChange,
		handleTextSizeSittingFacingChange,
		handleRaiseUpSittingFacingChange,
		handleTextColorBaseChange,
		handleTextSizeBaseChange,
		handleTextColorYearEnergyChange,
		handleTextSizeYearEnergyChange,
		handleTextFamilyChange,
		handleCircleColorChange,
		handleCircleRadiusChange,
	} = useContext(DataContext);

	return (
		<Row gutter={[16, 16]} style={{ padding: '0.5rem 1.5rem' }}>
			{/*			<Col flex={2}>
				<CustomTooltip title={`${t('Convert to side chart')}`}>
					<Button
						disabled={flyingStarsElementIsPresent}
						onClick={() => alert('not implemented')}>
						{t('Convert to side chart')}
					</Button>
				</CustomTooltip>
			</Col> */}

			{/* Distance to center Section */}
			<Col flex={1}>
				<CustomTooltip title={`${t('Distance to Center')}`}>
					<InputNumber
						disabled={!flyingStarsElementIsPresent}
						onChange={handleDistanceToCenterChange}
						value={distanceToCenter}
						formatter={(value) => `${value}%`}
						parser={(value) => value.replace('%', '')}
						min={10}
						max={200}
						step={1}
					/>
				</CustomTooltip>
			</Col>
			{/* Sitting Facing Section */}
			<Col flex={2}>
				<Input.Group compact>
					<CustomTooltip title={`${t('Text Color Sitting-Facing')}`}>
						<ColorPicker
							popup
							className='first-item'
							blockStyles={{
								width: '60px',
								height: '30px',
								marginTop: '1px',
								marginRight: '0',
							}}
							disabled={!flyingStarsElementIsPresent}
							value={textColorSittingFacing}
							onChange={handleTextColorSittingFacingChange}
						/>
					</CustomTooltip>
					<CustomTooltip title={`${t('Text Size Sitting-Facing')}`}>
						<InputNumber
							disabled={!flyingStarsElementIsPresent}
							onChange={handleTextSizeSittingFacingChange}
							value={textSizeSittingFacing}
							formatter={(value) => `${value} px`}
							parser={(value) => value.replace('px', '')}
							min={1}
							max={100}
							step={1}
						/>
					</CustomTooltip>
					<CustomTooltip title={`${t('Raise up Sitting-Facing')}`}>
						<InputNumber
							disabled={!flyingStarsElementIsPresent}
							onChange={handleRaiseUpSittingFacingChange}
							value={raiseUpSittingFacing}
							formatter={(value) => `${value}%`}
							parser={(value) => value.replace('%', '')}
							min={25}
							max={100}
							step={1}
						/>
					</CustomTooltip>
				</Input.Group>
			</Col>
			{/* Base Energy Section */}
			<Col flex={2}>
				<Input.Group compact>
					<CustomTooltip title={`${t('Text Color Base')}`}>
						<ColorPicker
							popup
							className='first-item'
							blockStyles={{
								width: '60px',
								height: '30px',
								marginTop: '1px',
								marginRight: '0',
							}}
							disabled={!flyingStarsElementIsPresent}
							value={textColorBase}
							onChange={handleTextColorBaseChange}
						/>
					</CustomTooltip>
					<CustomTooltip title={`${t('Text Size Base')}`}>
						<InputNumber
							disabled={!flyingStarsElementIsPresent}
							onChange={handleTextSizeBaseChange}
							value={textSizeBase}
							formatter={(value) => `${value} px`}
							parser={(value) => value.replace('px', '')}
							min={1}
							max={100}
							step={1}
						/>
					</CustomTooltip>
				</Input.Group>
			</Col>
			{/* Year Energy Section */}
			{flyingStarsElementIsPresent && yearEnergyIsActive && (
				<Col flex={2}>
					<Input.Group compact>
						<CustomTooltip title={`${t('Text Color Year Energy')}`}>
							<ColorPicker
								popup
								className='first-item'
								blockStyles={{
									width: '60px',
									height: '30px',
									marginTop: '1px',
									marginRight: '0',
								}}
								value={textColorYearEnergy}
								onChange={handleTextColorYearEnergyChange}
							/>
						</CustomTooltip>
						<CustomTooltip title={`${t('Text Size Year Energy')}`}>
							<InputNumber
								onChange={handleTextSizeYearEnergyChange}
								value={textSizeYearEnergy}
								formatter={(value) => `${value} px`}
								parser={(value) => value.replace('px', '')}
								min={1}
								max={100}
								step={1}
							/>
						</CustomTooltip>
					</Input.Group>
				</Col>
			)}
			<Col flex={yearEnergyIsActive ? 3 : 5}>
				<FontSelectorAutocomplete
					bodyStyle={{ width: '65%' }}
					tooltipText={`${t('Text Family')}`}
					fontName={textFamily}
					setFontName={handleTextFamilyChange}
					isDisabled={!flyingStarsElementIsPresent}
				/>
			</Col>
			<Col flex={2}>
				<Input.Group compact>
					<CustomTooltip title={`${t('Circle Color')}`}>
						<ColorPicker
							popup
							className='first-item'
							blockStyles={{
								width: '60px',
								height: '30px',
								marginTop: '1px',
								marginRight: '0',
							}}
							disabled={!flyingStarsElementIsPresent}
							value={circleColor}
							onChange={handleCircleColorChange}
						/>
					</CustomTooltip>
					<CustomTooltip title={`${t('Circle Radius')}`}>
						<InputNumber
							disabled={!flyingStarsElementIsPresent}
							onChange={handleCircleRadiusChange}
							value={circleRadius}
							min={1}
							max={100}
							step={1}
						/>
					</CustomTooltip>
				</Input.Group>
			</Col>
		</Row>
	);
};

export default MainChartMenu;
