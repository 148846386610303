import React from 'react';
import FlyWOWPageImpl from './FlyWOWPageImpl';
import { DataContextProvider } from './context/DataContext';

const FlyWOWPage = () => {
	return (
		<DataContextProvider>
			<FlyWOWPageImpl />
		</DataContextProvider>
	);
};

export default FlyWOWPage;
