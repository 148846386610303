import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Page from '../../layout/Page/Page';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Card, {
	CardBody,
	CardFooter,
	CardFooterLeft,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../../components/bootstrap/Card';
import Button from '../../components/bootstrap/Button';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../../components/bootstrap/Modal';
import { OffCanvasTitle } from '../../components/bootstrap/OffCanvas';
import Icon from '../../components/icon/Icon';
import showNotification from '../../components/extras/showNotification';
import Role from '../../common/data/role';
import authenticationService from '../../services/authentication.service';
import pages from '../../menu';
import AccountDetails from './components/AccountDetails';
import PaymentDetails from './components/PaymentDetails';
import ChangePassword from './components/ChangePassword';
import { UserContext } from '../../contexts/userContext';
import ResetSubscription from './components/ResetSubscription';

export const TABS = {
	ACCOUNT_DETAIL: 'Account Details',
	CHANGE_PASSWORD: 'Change Password',
	PAYMENT_DETAILS: 'Payment Details',
	RESET_SUBSCRIPTION: 'TEST ONLY | RESET_SUBSCRIPTION',
};

const ProfilePage = () => {
	const { currentUser } = useContext(UserContext);
	const navigate = useNavigate();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isModalLoading, setIsModalLoading] = useState(false);
	const [activeTab, setActiveTab] = useState(TABS.ACCOUNT_DETAIL);
	const { t: tg } = useTranslation('general');
	const { t } = useTranslation('profile');

	const handleDeleteClick = () => {
		setIsModalLoading(true);
		authenticationService.deleteAccount().then(
			() => {
				setIsModalLoading(false);
				navigate(`${pages.login.path}`);
				showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon='Info' size='lg' className='me-1' />
						<span>{t('Account deleted')}</span>
					</span>,
					t('Your account has been deleted, all the data has been removed.'),
				);
			},
			() => {
				showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon='Error' size='lg' className='me-1' />
						<span>{tg('Error')}</span>
					</span>,
					t(
						'The account could not be deleted because you are not authorised to make this request.',
					),
					'danger',
				);
				setIsModalLoading(false);
			},
		);
	};

	return (
		<PageWrapper title={t(pages.profile.text)}>
			<Page>
				<div className='row h-100'>
					<div className='col-xl-3 col-lg-4 col-md-6'>
						<Card stretch>
							<CardHeader>
								<CardLabel icon='Person' iconColor='info'>
									<CardTitle>{t('Account Settings')}</CardTitle>
									<CardSubTitle>{t('Personal Information')}</CardSubTitle>
								</CardLabel>
							</CardHeader>
							<CardBody isScrollable>
								<div className='row g-3'>
									<div className='col-12'>
										<Button
											icon='Contacts'
											color='info'
											className='w-100 p-3'
											isLight={TABS.ACCOUNT_DETAIL !== activeTab}
											onClick={() => setActiveTab(TABS.ACCOUNT_DETAIL)}>
											{t(TABS.ACCOUNT_DETAIL)}
										</Button>
									</div>

									<div className='col-12'>
										<Button
											icon='LocalPolice'
											color='info'
											className='w-100 p-3'
											isLight={TABS.CHANGE_PASSWORD !== activeTab}
											onClick={() => setActiveTab(TABS.CHANGE_PASSWORD)}>
											{t(TABS.CHANGE_PASSWORD)}
										</Button>
									</div>

									{currentUser != null && currentUser.role !== Role.None && (
										<div className='col-12'>
											<Button
												icon='Style'
												color='info'
												className='w-100 p-3'
												isLight={TABS.PAYMENT_DETAILS !== activeTab}
												onClick={() => setActiveTab(TABS.PAYMENT_DETAILS)}>
												{t(TABS.PAYMENT_DETAILS)}
											</Button>
										</div>
									)}
									{currentUser !== null &&
										process.env.REACT_APP_ENABLE_SUBSCRIPTION_RESET ===
											'true' &&
										currentUser.role !== Role.None && (
											<div className='col-12'>
												<Button
													icon='Style'
													color='info'
													className='w-100 p-3'
													isLight={TABS.RESET_SUBSCRIPTION !== activeTab}
													onClick={() =>
														setActiveTab(TABS.RESET_SUBSCRIPTION)
													}>
													{t(TABS.RESET_SUBSCRIPTION)}
												</Button>
											</div>
										)}
								</div>
							</CardBody>
							<CardFooter>
								<CardFooterLeft className='w-100'>
									<Button
										onClick={() => setIsModalOpen(true)}
										icon='Delete'
										color='danger'
										isLight
										className='w-100 p-3'>
										{t('Delete Account')}
									</Button>
								</CardFooterLeft>
							</CardFooter>
						</Card>
					</div>

					<div className='col-xl-9 col-lg-8 col-md-6'>
						{TABS.ACCOUNT_DETAIL === activeTab && <AccountDetails />}
						{TABS.CHANGE_PASSWORD === activeTab && <ChangePassword />}
						{currentUser != null &&
							currentUser.role !== Role.None &&
							TABS.PAYMENT_DETAILS === activeTab && <PaymentDetails />}
						{currentUser != null &&
							process.env.REACT_APP_ENABLE_SUBSCRIPTION_RESET === 'true' &&
							currentUser.role !== Role.None &&
							TABS.RESET_SUBSCRIPTION === activeTab && <ResetSubscription />}
					</div>

					<Modal
						setIsOpen={setIsModalOpen}
						isOpen={isModalOpen}
						titleId='deleteAccountModal'
						isCentered
						isScrollable
						size='lg'>
						<ModalHeader setIsOpen={setIsModalOpen}>
							<OffCanvasTitle id='deleteAccountModal'>
								{t('Delete Account')}
							</OffCanvasTitle>
						</ModalHeader>
						<ModalBody>
							<div className='row g-4'>
								<div className='col-12'>
									<p>{t('Confirm Delete 1')}</p>
									<p>{t('Confirm Delete 2')}</p>
								</div>
							</div>
						</ModalBody>
						<ModalFooter className='bg-transparent'>
							<div className='row g-4'>
								<div className='col-6'>
									<Button
										isDisable={isModalLoading}
										color='danger'
										onClick={handleDeleteClick}>
										{tg('Confirm')}
									</Button>
								</div>
								<div className='col-6'>
									<Button
										isDisable={isModalLoading}
										color='info'
										isOutline
										onClick={() => setIsModalOpen(false)}>
										{tg('Cancel')}
									</Button>
								</div>
							</div>
						</ModalFooter>
					</Modal>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default ProfilePage;
