import { BACKEND_URL } from '../services/authentication.service';

export function getFacingSittingValuesFromDatabase(celestialDirection, period) {
	const requestOptions = {
		method: 'GET',
		headers: {
			accept: 'application/octet-stream',
		},
	};
	return fetch(
		`${BACKEND_URL}/Fly/temporal?facing=${celestialDirection}&period=${period}`,
		requestOptions,
	)
		.then((response) => {
			return response.json();
		})
		.then((data) => {
			return data;
		});
}

export function getYearEnergyValuesFromDatabase(year) {
	const requestOptions = {
		method: 'GET',
		headers: {
			accept: 'application/octet-stream',
		},
	};
	return fetch(`${BACKEND_URL}/Fly/yearstar?year=${year}`, requestOptions)
		.then((response) => {
			return response.json();
		})
		.then((data) => {
			return data;
		});
}
