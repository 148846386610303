import { readTextFromFile } from '../../../../helpers/file-handle-helpers';
import { PIE_CHART_ID } from '../../../../common/data/selectorIDs';
import ExportImportServiceBase from '../../../../common/services/ExportImportServiceBase';

class ExportImportService extends ExportImportServiceBase {
	constructor(rappidService) {
		super(rappidService);
		this.setPieChartElementIsPresent = rappidService.setPieChartElementIsPresent;
	}

	async uploadGraph(file, showNotification) {
		try {
			const fileText = await readTextFromFile(file);
			this.graph.fromJSON(JSON.parse(fileText));

			localStorage.setItem('currentJointGraph', fileText);

			this.setPieChartElementIsPresent(
				this.graph.getCells().filter((cell) => cell.id === PIE_CHART_ID).length === 1,
			);

			return true;
		} catch (error) {
			showNotification();
			this.setPieChartElementIsPresent(false);

			return false;
		}
	}
}

export default ExportImportService;
