const LANG = {
	EN: {
		text: 'English',
		lng: 'en',
		icon: 'CustomUsa',
	},
	DE: {
		text: 'Deutsch',
		lng: 'de',
		icon: 'CustomGermany',
	},
};

export const getLangWithKey = (key) => {
	return LANG[Object.keys(LANG).filter((f) => LANG[f].lng === key)];
};

export default LANG;
