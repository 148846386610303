import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../../../../components/bootstrap/Modal';
import { OffCanvasTitle } from '../../../../components/bootstrap/OffCanvas';
import Checks, { ChecksGroup } from '../../../../components/bootstrap/forms/Checks';
import Button from '../../../../components/bootstrap/Button';
import Input from '../../../../components/bootstrap/forms/Input';
import DataContext from '../context/DataContext';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import CustomTooltip from '../../../../components/CustomTooltip';

const SettingsModal = ({ setActiveListTab, listTabName }) => {
	const { t: tg } = useTranslation('general');
	const { t } = useTranslation('startWOW');

	const {
		submitModalStatus,
		setSubmitModalStatus,
		service,
		setShowCelestialDirections,
		distanceToCenter,
	} = useContext(DataContext);

	const validate = (values) => {
		const errors = {};
		if (values.angle == null || values.angle === '') {
			errors.angle = tg('Required');
		} else if (values.angle < 0 || values.angle > 360) {
			errors.angle = t('The value needs to be between 0 and 360 degrees.');
		}

		return errors;
	};

	const formik = useFormik({
		initialValues: {
			angle: '',
			direction: 'B',
			is24Pie: '0',
			useCelestialDirections: '0',
			pieChartKind: 'C',
		},
		validate,
		onSubmit: (values) => {
			setSubmitModalStatus(false);
			setShowCelestialDirections(values.useCelestialDirections);
			service.add8or24Pie(
				values.angle,
				values.direction,
				values.is24Pie,
				values.useCelestialDirections,
				values.pieChartKind,
				distanceToCenter,
			);
			setActiveListTab(listTabName);
			formik.resetForm();
		},
	});

	return (
		<Modal
			setIsOpen={setSubmitModalStatus}
			isOpen={submitModalStatus}
			titleId='submitSelectionModal'
			isCentered
			isScrollable
			size='lg'>
			<ModalHeader setIsOpen={setSubmitModalStatus}>
				<OffCanvasTitle id='submitSelectionModal'>{tg('Submit Selection')}</OffCanvasTitle>
			</ModalHeader>
			<ModalBody>
				<div className='row g-4'>
					{/* Angle */}
					<CustomTooltip title={t('Viewing angle tooltip')}>
						<div className='col-12'>
							<FormGroup id='angle' label={t('Angle')}>
								<Input
									id='angle-input'
									type='number'
									placeholder={t('Angle')}
									name='angle'
									min={0}
									max={360}
									onChange={formik.handleChange}
									value={formik.values.angle}
									isValid={formik.isValid}
									isTouched={formik.touched.angle}
									invalidFeedback={formik.errors.angle}
								/>
							</FormGroup>
						</div>
					</CustomTooltip>
					{/* Direction */}
					<CustomTooltip title={t('Origin of gaze tooltip')}>
						<div className='col-12'>
							<FormGroup id='direction-radio-group' label={t('Origin of the gaze')}>
								<ChecksGroup id='direction-radio-group' isInline>
									<Checks
										type='radio'
										label={t('Right')}
										value='R'
										name='direction'
										onChange={formik.handleChange}
										checked={formik.values.direction}
									/>
									<Checks
										type='radio'
										label={t('Left')}
										value='L'
										name='direction'
										onChange={formik.handleChange}
										checked={formik.values.direction}
									/>
									<Checks
										type='radio'
										label={t('Top')}
										value='T'
										name='direction'
										onChange={formik.handleChange}
										checked={formik.values.direction}
									/>
									<Checks
										type='radio'
										label={t('Bottom')}
										value='B'
										name='direction'
										onChange={formik.handleChange}
										checked={formik.values.direction}
									/>
								</ChecksGroup>
							</FormGroup>
						</div>
					</CustomTooltip>
					{/* 24 or 8 pie */}
					<CustomTooltip title={t('8 or 24 Pie tooltip')}>
						<div className='col-12'>
							<FormGroup
								id='pie-selector-radio-group'
								label={
									t('8 Sectors or 24 Directions') +
									tg(' (Upgrade your plan to access all features)')
								}>
								<ChecksGroup id='pie-selector-radio-group' isInline>
									<Checks
										type='radio'
										label={t('8 Sectors')}
										value='0'
										name='is24Pie'
										onChange={formik.handleChange}
										checked={formik.values.is24Pie}
									/>
									<Checks
										type='radio'
										label={t('24 Directions')}
										value='1'
										name='is24Pie'
										onChange={formik.handleChange}
										checked={formik.values.is24Pie}
										disabled
									/>
								</ChecksGroup>
							</FormGroup>
						</div>
					</CustomTooltip>
					{/* Pie Chart Kind */}
					<CustomTooltip title={t('Select the Pie Chart Type')}>
						<div className='col-12'>
							<FormGroup
								id='pieChartKind-radio-group'
								label={
									t('Pie Chart Type') +
									tg(' (Upgrade your plan to access all features)')
								}>
								<ChecksGroup id='pieChartKind-radio-group' isInline>
									<Checks
										type='radio'
										label={t('Circle')}
										value='C'
										name='pieChartKind'
										onChange={formik.handleChange}
										checked={formik.values.pieChartKind}
									/>
									<Checks
										type='radio'
										label={t('Square')}
										value='S'
										name='pieChartKind'
										disabled
									/>
									<Checks
										type='radio'
										label={t('Rectangle')}
										value='R'
										name='pieChartKind'
										disabled
									/>
									<Checks
										type='radio'
										label={t('Ellipsis')}
										value='E'
										name='pieChartKind'
										disabled
									/>
								</ChecksGroup>
							</FormGroup>
						</div>
					</CustomTooltip>
					{/* celestial directions */}
					<CustomTooltip title={t('Show Celestial Directions tooltip')}>
						<div className='col-12'>
							<FormGroup
								id='celestial-directions-radio-group'
								label={t('Show Celestial Directions')}>
								<ChecksGroup id='celestial-directions-radio-group' isInline>
									<Checks
										type='radio'
										label={tg('Yes')}
										value='1'
										name='useCelestialDirections'
										onChange={formik.handleChange}
										checked={formik.values.useCelestialDirections}
									/>
									<Checks
										type='radio'
										label={tg('No')}
										value='0'
										name='useCelestialDirections'
										onChange={formik.handleChange}
										checked={formik.values.useCelestialDirections}
									/>
								</ChecksGroup>
							</FormGroup>
						</div>
					</CustomTooltip>
				</div>
			</ModalBody>
			<ModalFooter className='bg-transparent'>
				<div className='row g-4'>
					<div className='col-6'>
						<Button color='primary' onClick={formik.handleSubmit}>
							{tg('Confirm')}
						</Button>
					</div>
					<div className='col-6'>
						<Button color='info' isOutline onClick={() => setSubmitModalStatus(false)}>
							{tg('Cancel')}
						</Button>
					</div>
				</div>
			</ModalFooter>
		</Modal>
	);
};

SettingsModal.propTypes = {
	setActiveListTab: PropTypes.func.isRequired,
	listTabName: PropTypes.string.isRequired,
};

export default SettingsModal;
