import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Page from '../../../layout/Page/Page';
import Button from '../../../components/bootstrap/Button';
import Icon from '../../../components/icon/Icon';

const ErrorDisplay = ({ resetStatus }) => {
	const { t } = useTranslation('checkout');

	return (
		<Page>
			<div className='row d-flex align-items-center h-100'>
				<div
					className='col-12 d-flex justify-content-center'
					style={{ fontSize: 'calc(1rem + 3vw)' }}>
					<p>{t('Order canceled')}</p>
				</div>
				<div className='col-12 d-flex align-items-baseline justify-content-center'>
					<Icon icon='CartX' forceFamily='bootstrap' size='10x' color='danger' />
				</div>
				<div className='col-12 d-flex justify-content-center'>
					<h4 className='fw-bold'>
						<span>
							{t('Your checkout session has been closed, no payment has been done.')}
						</span>
						<br />
						<span>
							{t(
								'Please start a new checkout session by going back to the product overview and selecting your desired plan.',
							)}
						</span>
					</h4>
				</div>
				<div className='col-12 mb-3'>
					<Button
						onClick={resetStatus}
						color='primary'
						isLight
						className='w-100 py-3 text-uppercase'
						size='lg'>
						{t('Back to Product Overview')}
					</Button>
				</div>
			</div>
		</Page>
	);
};

ErrorDisplay.propTypes = {
	resetStatus: PropTypes.func.isRequired,
};

export default ErrorDisplay;
